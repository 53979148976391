import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { BiCheck, BiX } from 'react-icons/bi'
import { useQsFilters } from '../../hooks/filters'
import { useAccountsUsers } from '../../hooks/users'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import createStringLink from '../../utils'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import { useCanGestioneUtenti } from '../../permissions'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'full_name',
  paese_intervento: params.get('paese_intervento') ?? '',
  progetto: params.get('progetto') ?? '',
})

export default function UsersList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useAccountsUsers(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const userCanGestioneUtenti = useCanGestioneUtenti()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('users'),
      },
    ]
  }, [t])

  const [showFilters, setShowFilters] = useState(true)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
              {userCanGestioneUtenti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/users/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            mb={2}
            zIndex={99}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(4, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('country')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.paese_intervento}
                      isMulti={false}
                      url={'/api/paesi-intervento/options/'}
                      keyQuery={'paesi-intervento-options'}
                      onChange={(values: any) => {
                        setFilters({
                          page: 1,
                          paese_intervento: values?.value ?? '',
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('project')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.progetto}
                      isMulti={false}
                      url={'/api/progetti/options/'}
                      keyQuery={'progetti-options'}
                      onChange={(values: any) => {
                        setFilters({
                          page: 1,
                          progetto: values?.value ?? '',
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            // downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'full_name', label: t('name') },
              { value: 'email', label: t('email') },
              { value: 'is_staff', label: t('administrator') },
              { value: 'is_active', label: t('active') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                mt={3}
                width={'100%'}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('email')}</Th>
                      <Th>{t('name')}</Th>
                      <Th>{t('administrator')}</Th>
                      <Th>{t('active')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((user) => (
                      <Tr padding={2} key={user.id}>
                        <Td>
                          <LangLinkWithStyle to={`/users/${user.id}`}>
                            {user.email}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{user.full_name}</Td>
                        <Td align="left">
                          {user.is_staff ? (
                            <BiCheck size={20} color="green" />
                          ) : (
                            <BiX size={20} color="red" />
                          )}
                        </Td>
                        <Td align="left">
                          {user.is_active ? (
                            <BiCheck size={20} color="green" />
                          ) : (
                            <BiX size={20} color="red" />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
