import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'

export function useCausali(user?: any) {
  return useQuery<any>(
    ['causali'],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/causali/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      suspense: false,
      enabled: !!user
    }
  )
}
