import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useUpdateValoreCambio, useValoreCambio } from '../../hooks/valori-cambio'
import createStringLink from '../../utils'
import ValoreCambioForm from './ValoreCambioForm'

export default function ValoriCambioEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useValoreCambio(+id!)
  const editValoreCambio = useUpdateValoreCambio()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <ValoreCambioForm
        valore={data}
        save={async (valore) => {
          const savedValore = await editValoreCambio.mutateAsync(valore)
          navigate(
            createStringLink({
              path: `/exchange-rate-values/${savedValore.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
