import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useProgettoSimple } from '../../../hooks/progetti'
import {
  useBudgetsSimple,
  useEditMultipleBudgets,
} from '../../../hooks/budgets'
import { useMemo, useState } from 'react'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import BreadCrumb from '../../../components/BreadCrumb'
import { Formik } from 'formik'
import { groupBy } from 'lodash'
import { InputField } from '../../../components/form'

export default function BudgetMultiEdit() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(+id!)
  const { data: budgets, refetch } = useBudgetsSimple({
    progetto: +id!,
  })

  const itemsBreadCrumbEditMultiple = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('budgets'),
        link: `/projects/${id}/budgets`,
      },
      {
        label: t('edit_multiple'),
      },
    ]
  }, [t, progetto, id])

  const budgetsByCodiceWithEmptyYears = useMemo(() => {
    const budgetsByCodice = groupBy(budgets, 'codice')
    // order by codice
    const orderedBudgetsByCodice = Object.keys(budgetsByCodice).sort().reduce(
      (obj: any, key) => {
        obj[key] = budgetsByCodice[key]
        return obj
      },
      {}
    )
    const mappedBudgetsWithAnnualita = Object.keys(orderedBudgetsByCodice).map(
      (key) => {
        return [...Array(progetto?.num_annualita)].map((_, index) => {
          const budget = budgetsByCodice[key].find(
            (budget) => budget.annualita === index + 1
          )
          if (budget) {
            return {
              importo: Number(budget.importo),
              id: Number(budget.id),
              progetto: Number(progetto?.id),
              codice: budget.codice,
              annualita: index + 1,
              descrizione: budget.descrizione,
            }
          } else {
            return {
              codice: key,
              annualita: index + 1,
              importo: 0,
              progetto: Number(progetto?.id),
              descrizione: '',
            }
          }
        })
      }
    )
    return mappedBudgetsWithAnnualita
  }, [budgets, progetto])

  const editMultiBudgets = useEditMultipleBudgets()

  const [loadingSave, setLoadingSave] = useState(false)

  const toast = useToast()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Formik
        // validationSchema={BudgetSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={async (budgets, { setErrors }) => {
          let budgetsForSubmit = [] as any
          if (progetto) {
            budgets.budgets.map((budget) =>
              budget.map((budget) =>
                budgetsForSubmit.push({
                  ...budget,
                  progetto: progetto?.id,
                })
              )
            )
            setLoadingSave(true)
            await editMultiBudgets.mutateAsync({
              budgets: budgetsForSubmit,
            }).then(() => {
              toast({
                title: t('edit_multiple'),
                description: t('you_have_modify') + ' ' + budgetsForSubmit.length + ' ' + t('budgets'),
                status: 'success',
                duration: 10000,
                isClosable: true,
              })
              refetch()
              setLoadingSave(false)
            })
          }
        }}
        validateOnMount
        initialValues={{
          budgets: budgetsByCodiceWithEmptyYears,
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          handleReset,
          dirty,
          setFieldValue,
          values,
        }) => {
          return (
            <form className="form-without-scrollbar" onSubmit={handleSubmit}>
              <Flex direction={'column'}>
                <HeaderActionsPage
                  sticky
                  breadCrumbs={
                    <BreadCrumb items={itemsBreadCrumbEditMultiple} />
                  }
                  blockRight={
                    <Box display={'flex'} alignItems={'center'}>
                      <Button
                        size={'sm'}
                        color={'white'}
                        cursor={
                          isSubmitting || !isValid || loadingSave
                            ? 'not-allowed'
                            : 'pointer'
                        }
                        _disabled={{
                          opacity: 0.5,
                        }}
                        isDisabled={isSubmitting || loadingSave}
                        type={'submit'}
                        _hover={{ bg: 'brand' }}
                        bg={'brandSecondary'}
                      >
                        {loadingSave && (
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="white"
                            size="sm"
                            me={2}
                          />
                        )}
                        {
                          loadingSave ? t('saving') : t('save')
                        }
                      </Button>
                    </Box>
                  }
                />
                <Box overflowY={'auto'} height={'calc(100vh - 150px)'}>
                  <Table variant={'pinc'}>
                    <Thead position={'sticky'} top={0}>
                      <Tr>
                        <Th>{t('budget_line')}</Th>
                        <Th>{t('description')}</Th>
                        {progetto?.num_annualita &&
                          [...Array(progetto?.num_annualita)].map(
                            (_, index) => (
                              <Th key={index}>
                                {t('year')} {index + 1}
                              </Th>
                            )
                          )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {budgetsByCodiceWithEmptyYears?.map((budget, index) => (
                        <Tr key={index}>
                          <Td>{budget[0].codice}</Td>
                          <Td>
                            {
                              // search for the first description not empty
                              budget.find((b) => b.descrizione)?.descrizione
                            }
                          </Td>
                          {budget.map((budget, i) => (
                            <Td key={i}>
                              <InputField
                                name={`budgets[${index}][${i}].importo`}
                                type={'number'}
                                isDisabled={loadingSave}
                                //   value={budget.importo}
                                onChange={(e) => {
                                  setFieldValue(
                                    `budgets[${index}][${i}].importo`,
                                    Number(e.target.value)
                                  )
                                }}
                              />
                            </Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}
