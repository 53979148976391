import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import createStringLink from '../../../utils'
import { useFinanziamento, useUpdateFinanziamento } from '../../../hooks/finanziamenti'
import FinanziamentoForm from './FinanziamentiForm'

export default function FinanziamentoEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id, finanziamento } = useParams()
  const { data } = useFinanziamento(+finanziamento!)
  const editFinanziamento = useUpdateFinanziamento()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <FinanziamentoForm
        finanziamento={data}
        save={async (finanziamento) => {
          const savedFinanziamento = await editFinanziamento.mutateAsync(finanziamento)
          navigate(
            createStringLink({
              path: `/projects/${id}/financing/${savedFinanziamento.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
