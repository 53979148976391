import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Table: {
      variants: {
        pinc: {
          thead: {
            zIndex: 2,
          },
          th: {
            bg: 'tertiary',
            px: 4,
            py: 2,
            color: 'white',
            fontSize: 12,
            fontWeight: 600,
            textTransform: 'capitalize',
            fontFamily: 'Inter',
            border: '1px',
            borderColor: 'white',
            textAlign: 'left',
            _first: {
              borderTopLeftRadius: 'md',
            },
            _last: {
              borderTopRightRadius: 'md',
            },
            zIndex: 10,
          },
          td: {
            fontSize: 13,
            fontweight: 400,
            fontFamily: 'Inter',
            px: 4,
          },
          tr: {
            _even: {
              background: 'card',
            },
            _odd: {
              background: 'white',
            },
          },
        },
      },
    },
  },
  colors: {
    brand: '#FF671D',
    brandSecondary: '#FF9800',
    brandLight: '#FEEBCB',
    secondary: '#607D8B',
    tertiary: '#A6BAC5',
    card: '#ECF7FE',
    white: '#FFFFFF',
    darkblue: '#405C67',
    brandBlue: '#607D8B',
    success: '#8BC34A',
    error: '#E53E3E',
    dark: '#001A20',
    background: '#F8F8F8'
  },
  fonts: {
    heading: '"Inter", sans-serif',
    body: '"Inter", sans-serif',
  },
})
