import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateValoreCambio } from '../../hooks/valori-cambio'
import createStringLink from '../../utils'
import ValoreCambioForm from './ValoreCambioForm'

export default function ValoriCambioCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createValoreCambio = useCreateValoreCambio()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <ValoreCambioForm
        save={async (valore) => {
          const savedValore = await createValoreCambio.mutateAsync(valore)
          navigate(
            createStringLink({
              path: `/exchange-rate-values/${savedValore.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
