import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import AutoCompleteField from './form/fields'
import { Fornitore } from '../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto?: number | undefined | null
  excludeProjects?: number[]
  fornitore: Fornitore | undefined
}

export default function ModalAssociateProject({
  isOpen,
  onClose,
  onConfirm,
  progetto,
  excludeProjects = [],
  fornitore,
}: Props) {
  const { t } = useTranslation()


  const initialValues = {
    progetto: null,
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      initialValues={progetto ? { progetto: progetto } : initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {progetto
                  ? t('dissociate_or_change_project')
                  : t('associate_project')}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'progetto'}
                    label={t('project') ?? ''}
                    keyQuery={'progetti-options'}
                    url={'/api/progetti/options-for-fornitore/'}
                    isRequired
                    params={{
                      exclude_ids: excludeProjects,
                      paese_intervento: fornitore?.paese_intervento,
                    }}
                    value={values.progetto}
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  //   isDisabled={!values.progetto}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('associate')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
