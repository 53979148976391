import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdSearch } from 'react-icons/md'

interface SearchInputProps {
  value: string
  setFiltersDebounced: (filters: any) => void
}

export default function SearchInput({
  value,
  setFiltersDebounced,
}: SearchInputProps) {
  const { t } = useTranslation()
  return (
    <InputGroup size={'sm'}>
      <InputLeftElement
        pointerEvents="none"
        children={<MdSearch color="paragraph" />}
      />
      <Input
        placeholder={t('search') ?? ''}
        size={'sm'}
        boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
        width={300}
        borderRadius={8}
        border={'none'}
        borderColor={'none'}
        focusBorderColor={'secondary'}
        color={'secondary'}
        background={'white'}
        value={value}
        onChange={(e) =>
          setFiltersDebounced({ search: e.target.value, page: 1 })
        }
      />
      
      <InputRightElement>
        <MdClose cursor={
          'pointer'
        } width={20} color="paragraph" onClick={() => {
          setFiltersDebounced({ search: '', page: 1 })
        }} />

      </InputRightElement>
    </InputGroup>
  )
}
