import { BaseValoreCambio, ValoreCambio } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  DateField,
  InputField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'
import { useQsFilters } from '../../hooks/filters'

interface ValoreCambioFormProps {
  valore?: ValoreCambio
  save: (valore: ValoreCambio) => Promise<void>
}

const initFilters = (params: URLSearchParams) => ({
  valuta_da: params.get('valuta_da') ?? undefined,
  valuta_a: params.get('valuta_a') ?? undefined,
  tipo_cambio: params.get('tipo_cambio') ?? undefined,
  data_da: params.get('data_da') ?? undefined,
})

export default function ValoreCambioForm({
  valore,
  save,
}: ValoreCambioFormProps) {
  const { t } = useTranslation()
  const { filters } = useQsFilters(initFilters)

  const initialValues: BaseValoreCambio = {
    tipo_cambio: Number(filters.tipo_cambio) ?? undefined,
    da_data: filters.data_da ?? undefined,
    a_data: undefined,
    valore: null,
    valuta_a: Number(filters.valuta_a) ?? undefined,
    valuta_da: Number(filters.valuta_da) ?? undefined,
  }

  const ValoreCambioSchema = Yup.object().shape({
    tipo_cambio: Yup.string().required().label(t('type_of_exchange')),
    da_data: Yup.string().required().label(t('from_date')),
    valore: Yup.string().required().label(t('value')),
    valuta_a: Yup.string().required().label(t('currency_to')),
    valuta_da: Yup.string().required().label(t('currency_from')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('exchange_rate_values'),
        link: '/exchange-rate-values',
      },
      {
        label: t('new_exchange_rate_value'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('exchange_rate_values'),
        link: '/exchange-rate-values',
      },
      {
        label:
          valore?.tipo_cambio_data?.codice +
          ' - ' +
          valore?.tipo_cambio_data?.frequenza,
        link: `/exchange-rate-values/${valore?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, valore])

  return (
    <Formik
      validationSchema={ValoreCambioSchema}
      onSubmit={(valore, { setErrors, setSubmitting }) =>
        save({
          ...valore,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(valore ?? initialValues) as ValoreCambio}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        handleReset,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={valore ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem>
                    <AutoCompleteField
                      name="tipo_cambio"
                      label={t('type_of_exchange') ?? ''}
                      url="/api/tipi-cambio/options/"
                      keyQuery="tipi-cambio-options"
                      value={values.tipo_cambio}
                      isRequired
                    />
                  </GridItem>
                  <GridItem>
                    <DateField
                      isRequired
                      name="da_data"
                      label={t('from_date') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <DateField name="a_data" label={t('to_date') ?? ''} />
                  </GridItem>
                </Grid>
                <Grid
                  minHeight="100%"
                  mt={4}
                  templateColumns="repeat(3, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <AutoCompleteField
                      name="valuta_da"
                      label={t('currency_from') ?? ''}
                      url="/api/valute/options/"
                      keyQuery="valute-options"
                      value={values.valuta_da}
                      isRequired
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      name="valuta_a"
                      label={t('currency_to') ?? ''}
                      url="/api/valute/options/"
                      keyQuery="valute-options"
                      value={values.valuta_a}
                      isRequired
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      isRequired
                      name="valore"
                      label={t('value') ?? ''}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
