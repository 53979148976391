import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import CollaborazioneCreate from './CollaborazioneCreate'
import CollaborazioneDetail from './CollaborazioneDetail'
import CollaborazioneEdit from './CollaborazioneEdit'
import CollaborazioniList from './CollaborazioniList'

export default function Collaborazioni() {
  return (
    <Routes>
      <Route index element={<CollaborazioniList />} />
      <Route path="/create" element={<CollaborazioneCreate />} />
      <Route path=":collaboration" element={<CollaborazioneDetail />} />
      <Route path=":collaboration/edit" element={<CollaborazioneEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
