import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import { useBeni, useBeniDownload, useImportBeni } from '../../hooks/beni'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import { useCanInsertBeni } from '../../permissions'
import { BiCheck, BiX } from 'react-icons/bi'
import ModalImportBeni from './ModalImportBeni'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'progressivo',
})

export default function BeniList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useBeni(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const userCanGestioneBeni = useCanInsertBeni()

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('goods'),
      },
    ]
  }, [t])

  const downloadWithParams = useBeniDownload(filters)

  const importBeni = useImportBeni()

  const {
    isOpen: isOpenImportBeni,
    onOpen: onOpenImportBeni,
    onClose: onCloseImportBeni,
    onToggle: onToggleImportBeni,
  } = useDisclosure()

  const [isLoadingImport, setIsLoadingImport] = useState(false)

  const [errors, setErrors] = useState(null)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={breadCrumbsItems} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneBeni && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'darkblue'}
                  border={'1px solid'}
                  fontWeight={500}
                  _hover={{ bg: 'brand', color: 'white' }}
                  onClick={() => {
                    onOpenImportBeni()
                  }}
                >
                  {t('import_goods')}
                </Button>
              )}
              {userCanGestioneBeni && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/goods/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'progressivo', label: t('progressive') },
              { value: 'descrizione', label: t('description') },
              { value: 'progetto__titolo', label: t('project') },
              { value: 'luogo_utilizzo', label: t('place_of_use') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('progressive')}</Th>
                      <Th>{t('description')}</Th>
                      <Th>{t('project')}</Th>
                      <Th>{t('place_of_use')}</Th>
                      <Th>{t('quantity')}</Th>
                      <Th>{t('not_in_use')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((bene) => (
                      <Tr padding={2} key={bene.id}>
                        <Td>{bene.progressivo}</Td>
                        <Td>
                          <LangLinkWithStyle to={`/goods/${bene.id}`}>
                            {bene.descrizione}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>
                          <LangLinkWithStyle to={`/projects/${bene.progetto}`}>
                            {bene.progetto_data && bene.progetto_data.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{bene.luogo_utilizzo}</Td>
                        <Td>{bene.quantita}</Td>
                        <Td align={'center'}>
                          {bene.non_in_uso ? (
                            <BiCheck size={20} color="green" />
                          ) : (
                            <BiX size={20} color="red" />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      <ModalImportBeni
        isOpen={isOpenImportBeni}
        onClose={onCloseImportBeni}
        onToggle={onToggleImportBeni}
        onOpen={onOpenImportBeni}
        errors={errors}
        isLoading={isLoadingImport}
        onConfirm={async (values) => {
          setIsLoadingImport(true)
          await importBeni
            .mutateAsync({
              importFile: values.import_file,
            })
            .then(() => {
              setIsLoadingImport(false)
              onCloseImportBeni()
            })
            .catch((err) => {
              setErrors(err.response.data)
            })
        }}
      />
    </Box>
  )
}
