import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCollaborazione, useUpdateCollaborazione } from '../../../hooks/collaborazioni'
import createStringLink from '../../../utils'
import CollaborazioneForm from './CollaborazioneForm'

export default function CollaborazioneEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id, collaboration } = useParams()
  const { data } = useCollaborazione(+collaboration!)
  const editCollaborazione = useUpdateCollaborazione()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <CollaborazioneForm
        collaborazione={data}
        save={async (collaborazione) => {
          const savedCollaborazione = await editCollaborazione.mutateAsync(collaborazione)
          navigate(
            createStringLink({
              path: `/projects/${id}/collaborations/${savedCollaborazione.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
