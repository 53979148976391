import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import ProtectedRoute from '../../../components/ProtectedRoute'
import { useCanCreateEditBudget } from '../../../permissions'
import BudgetCreate from './BudgetCreate'
import BudgetDetail from './BudgetDetail'
import BudgetEdit from './BudgetEdit'
import BudgetsList from './BudgetsList'
import BudgetMultiEdit from './BudgetsMultiEdit'

export default function Budgets() {
  const userCanGestioneBudget = useCanCreateEditBudget()
  return (
    <Routes>
      <Route index element={<BudgetsList />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneBudget}>
            <BudgetCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-multiple"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneBudget}>
            <BudgetMultiEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path=":budget/edit"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneBudget}>
            <BudgetEdit />
          </ProtectedRoute>
        }
      />
      <Route path=":budget" element={<BudgetDetail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
