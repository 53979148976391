import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseValoreCambio,
  PaginatedDJResponse,
  ValoreCambio,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

export function useValoriCambio(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<ValoreCambio>>(
    ['valori-cambio', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/valori-cambio/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteValoreCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/valori-cambio/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['valori-cambio'])
        client.invalidateQueries(['valore-cambio'])
      },
    }
  )
}

export function useCreateValoreCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (cambio: BaseValoreCambio) =>
        axios
          .post(`/api/valori-cambio/`, cambio, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ValoreCambio)
    ),
    {
      onSuccess(savedCambio) {
        client.invalidateQueries(['valori-cambio'])
        client.setQueryData(['valore-cambio', savedCambio.id], savedCambio)
      },
    }
  )
}

export function useUpdateValoreCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (cambio: ValoreCambio) =>
        axios
          .put(`/api/valori-cambio/${cambio.id}/`, cambio, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ValoreCambio)
    ),
    {
      onSuccess(savedCambio) {
        client.invalidateQueries(['valori-cambio'])
        client.setQueryData(['valore-cambio', savedCambio.id], savedCambio)
      },
    }
  )
}
export function useValoreCambio(id: number) {
  return useQuery<ValoreCambio>(
    ['valore-cambio', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/valori-cambio/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useCreateMultipleValoreCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (cambi: {
        cambi: BaseValoreCambio[]
      }) =>
        axios
          .post(`/api/valori-cambio/create-multiple/`, cambi.cambi, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ValoreCambio[])
    ),
    {
      onSuccess() {
        client.invalidateQueries(['valori-cambio'])
      },
    }
  )
}


export function useValoriCambioDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/valori-cambio/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

const makeImportCambiFormData = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useImportCambi() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ importFile }) => {
          const formData = makeImportCambiFormData({ import_file: importFile })
          return axios
            .post(`/api/valori-cambio/import-cambi/`, formData, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
        }
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-budgets'])
      },
    }
  )
}


