import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useBene, useUpdateBene } from '../../hooks/beni'
import createStringLink from '../../utils'
import BeneForm from './BeneForm'

export default function BeniEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useBene(+id!)
  const editBene = useUpdateBene()
  

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <BeneForm
        bene={data}
        save={async (bene) => {
          const savedBene = await editBene.mutateAsync(bene)
          navigate(
            createStringLink({
              path: `/goods/${savedBene.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
