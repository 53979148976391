import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import FornitoriProgettoList from './FornitoriProgettoList'

export default function FornitoriProgetto() {
  return (
    <Routes>
      <Route index element={<FornitoriProgettoList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
