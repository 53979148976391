import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import ModalitaPagamentoCreate from './ModalitaPagamentoCreate'
import ModalitaPagamentoDetail from './ModalitaPagamentoDetail'
import ModalitaPagamentoEdit from './ModalitaPagamentoEdit'
import ModalitaPagamentoList from './ModalitaPagamentoList'

export default function ModalitaPagamento() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<ModalitaPagamentoList />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <ModalitaPagamentoCreate />
          </ProtectedRoute>
        }
      />
      <Route path="/:id" element={<ModalitaPagamentoDetail />} />
      <Route
        path="/:id/edit"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <ModalitaPagamentoEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
