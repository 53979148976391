import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCreateCollaborazione } from '../../../hooks/collaborazioni'
import createStringLink from '../../../utils'
import CollaborazioneForm from './CollaborazioneForm'

export default function CollaborazioneCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const createCollaborazione = useCreateCollaborazione()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <CollaborazioneForm
        save={async (collaborazione) => {
          const savedCollaborazione = await createCollaborazione.mutateAsync(collaborazione)
          navigate(
            createStringLink({
              path: `/projects/${id}/collaborations/${savedCollaborazione.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
