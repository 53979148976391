import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Budget, PaginatedDJResponse } from '../types'
import { useDownloadFile } from './download'

export function useBudgets(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Budget>>(
    ['budgets', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/budgets/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      enabled: params.enabled ?? true,
    }
  )
}

export function useBudgetsSimple(params: Record<string, any> = {}) {
  return useQuery<Budget[]>(
    ['budgets', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/budgets/budgets-multi/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteBudget() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/budgets/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['collaborazioni'])
        client.invalidateQueries(['collaborazioni'])
      },
    }
  )
}

export function useCreateBudget() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (budget: Budget) =>
        axios
          .post(`/api/budgets/`, budget, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Budget)
    ),
    {
      onSuccess(savedBudget) {
        client.invalidateQueries(['budgets'])
        client.setQueryData(['budget', savedBudget.id], savedBudget)
      },
    }
  )
}

export function useUpdateBudget() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (budget: Budget) =>
        axios
          .put(`/api/budgets/${budget.id}/`, budget, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Budget)
    ),
    {
      onSuccess(savedBudget) {
        client.invalidateQueries(['budgets'])
        client.setQueryData(['budget', savedBudget.id], savedBudget)
      },
    }
  )
}

export function useEditMultipleBudgets(){
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (budgets: any) =>
        axios
          .post(`/api/budgets/update-multi-budgets/`, budgets, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Budget)
    ),
    {
      onSuccess(savedMovimento) {
        client.invalidateQueries(['budgets'])
      },
    }
  )
}

export function useBudget(id: number) {
  return useQuery<Budget>(
    ['budget', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/budgets/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Budget)
    ),
    {
      enabled: !!id,
    }
  )
}

export function useDeleteBudgets() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (ids: any) =>
        axios.post(
          `/api/budgets/delete-budgets/`,
          ids,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
    ),
    {
      onSuccess() {
        client.invalidateQueries(['budgets'])
        client.invalidateQueries(['budgets'])
      },
    }
  )
}

export function useBudgetsDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/budgets/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
