import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseDonatore,
  Donatore,
  PaginatedDJResponse,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

const makeDonatoreFormData = makeFormDataEncoder({
  fileFields: ['allegato'],
  excludeFields: [],
})

export function useDonatori(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Donatore>>(
    ['donatori', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/donatori/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteDonatore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/donatori/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['donatori'])
        client.invalidateQueries(['donatore'])
      },
    }
  )
}

export function useCreateDonatore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (donatore: BaseDonatore) =>
        axios
          .post(`/api/donatori/`, makeDonatoreFormData(donatore), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Donatore)
    ),
    {
      onSuccess(savedDonatore) {
        client.invalidateQueries(['donatori'])
        client.setQueryData(['donatore', savedDonatore.id], savedDonatore)
      },
    }
  )
}

export function useUpdateDonatore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (donatore: Donatore) =>
        axios
          .put(
            `/api/donatori/${donatore.id}/`,
            makeDonatoreFormData(donatore),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Donatore)
    ),
    {
      onSuccess(savedDonatore) {
        client.invalidateQueries(['donatori'])
        client.setQueryData(['donatore', savedDonatore.id], savedDonatore)
      },
    }
  )
}

export function useDonatore(id: number) {
  return useQuery<Donatore>(
    ['donatore', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/donatori/${id}/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Donatore)
    ),
    {
      enabled: id !== undefined,
    }
  )
}

export function useDonatoriDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/donatori/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
