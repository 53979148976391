import { Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { useLangPathPrefix } from '../hooks/lang'

export default function LangLinkWithStyle(props: any) {
  return (
    <Link
      color={'darkblue'}
      textDecoration={'underline'}
      _hover={{
        opacity: 0.8,
      }}
      fontWeight={600}
      as={RouterLink}
      {...props}
      to={`${useLangPathPrefix()}${props.to}`}
    />
  )
}
