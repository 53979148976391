import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { CollaboratoreGridForm } from './CollaboratoreForm'
import { useState } from 'react'
import { BaseCollaboratore } from '../../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: BaseCollaboratore) => void
}

export default function ModalFormCollaboratore({
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const CollaboratoreSchema = Yup.object().shape({
    nome: Yup.string().required().label(t('name')),
    codice: Yup.string().required().label(t('employee_code')),
    duty_station_relazione: Yup.number().required().label(t('duty_station')),
  })

  const initialValues: BaseCollaboratore = {
    nome: '',
    sesso: '',
    email: '',
    telefono: '',
    note: '',
    residenza: '',
    data_nascita: '',
    luogo_nascita: '',
    area_intervento: null,
    paese_intervento: null,
    ente: null,
    allegato: null,
    allegato_2: null,
    allegato_3: null,
    allegato_4: null,
    allegato_5: null,
    duty_station_relazione: undefined,
    codice: '',
  }

  const [showAllegati, setShowAllegati] = useState(0)

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      validationSchema={CollaboratoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'3xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('new_collaborator')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody style={{ overflow: 'auto', maxHeight: '72vh' }}>
                <CollaboratoreGridForm
                  setShowAllegati={setShowAllegati}
                  showAllegati={showAllegati}
                  isModal
                />
              </ModalBody>
              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Box>
                  <Button
                    type="submit"
                    bg={'brand'}
                    _hover={{
                      bg: 'brandSecondary',
                    }}
                    color={'white'}
                  >
                    {t('create')}
                  </Button>
                </Box>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
