import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

// only in production

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9c751f0b3b8a7fbced8194950e82a74b@o1047151.ingest.sentry.io/4506665024094208',
    integrations: [],
  })
}



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
