import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import {
  usePaeseIntervento,
  useUpdatePaeseIntervento,
} from '../../hooks/paesi-intervento'
import createStringLink from '../../utils'
import PaeseInterventoForm from './PaeseInterventoForm'

export default function PaesiInterventoEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = usePaeseIntervento(+id!)
  const editPaeseIntervento = useUpdatePaeseIntervento()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <PaeseInterventoForm
        paese={data}
        save={async (paese) => {
          const savedPaese = await editPaeseIntervento.mutateAsync(paese)
          navigate(
            createStringLink({
              path: `/countries-of-intervention/${savedPaese.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
