import {
  BaseModalitaPagamento,
  ModalitaPagamento,
} from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { InputField, transformErrorForForm } from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

const initialValues: BaseModalitaPagamento = {
  descrizione: '',
}

interface ModalitaPagamentoFormProps {
  modalita?: ModalitaPagamento
  save: (modalita: ModalitaPagamento) => Promise<void>
}

export default function ModalitaPagamentoForm({
  modalita,
  save,
}: ModalitaPagamentoFormProps) {
  const { t } = useTranslation()

  const ModalitaPagamentoSchema = Yup.object().shape({
    descrizione: Yup.string().required().label(t('description')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('methods_of_payment'),
        link: '/methods-of-payment',
      },
      {
        label: t('new_method_of_payment'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('methods_of_payment'),
        link: '/methods-of-payment',
      },
      {
        label: modalita?.descrizione ?? '',
        link: `/methods-of-payment/${modalita?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, modalita])

  return (
    <Formik
      validationSchema={ModalitaPagamentoSchema}
      onSubmit={(tipo, { setErrors, setSubmitting }) =>
        save({
          ...tipo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(modalita ?? initialValues) as ModalitaPagamento}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={modalita ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem>
                    <InputField
                      name="descrizione"
                      isRequired
                      label={t('description') ?? ''}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
