import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { PaginatedDJResponse, VoceSpesa } from '../types'
import { useDownloadFile } from './download'

export function useVociSpesa(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<VoceSpesa>>(
    ['voci-spesa', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/voci-spesa/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteVoceSpesa() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/voci-spesa/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['voci-spesa'])
        client.invalidateQueries(['voce-spesa'])
      },
    }
  )
}

export function useCreateVoceSpesa() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (voce: VoceSpesa) =>
        axios
          .post(`/api/voci-spesa/`, voce, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as VoceSpesa)
    ),
    {
      onSuccess(savedVoceSpesa) {
        client.invalidateQueries(['voci-spesa'])
        client.setQueryData(['voce-spesa', savedVoceSpesa.id], savedVoceSpesa)
      },
    }
  )
}

export function useUpdateVoceSpesa() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (voce: VoceSpesa) =>
        axios
          .put(`/api/voci-spesa/${voce.id}/`, voce, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as VoceSpesa)
    ),
    {
      onSuccess(savedVoce) {
        client.invalidateQueries(['voci-spesa'])
        client.setQueryData(['voce-spesa', savedVoce.id], savedVoce)
      },
    }
  )
}

export function useVoceSpesa(id: number) {
  return useQuery<VoceSpesa>(
    ['voce-spesa', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/voci-spesa/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useVociSpesaDownload(filters:any){
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/voci-spesa/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
