import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import {
  useContoBancario,
  useUpdateContoBancario,
} from '../../hooks/conti-bancari'
import createStringLink from '../../utils'
import ContoForm from './ContoForm'

export default function ContoEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useContoBancario(+id!)
  const editConto = useUpdateContoBancario()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <ContoForm
        conto={data}
        save={async (conto) => {
          const savedConto = await editConto.mutateAsync(conto)
          navigate(
            createStringLink({
              path: `/accounts/${savedConto.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
