import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import AutoCompleteField from '../../../components/form/fields'
import { Progetto } from '../../../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto: Progetto | undefined
}

export default function ModalCopiaCollaborazione({
  progetto,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    progetto: null,
  }

  const AssociaFornitoreSchema = Yup.object().shape({
    progetto: Yup.string().required(t('project_is_required') ?? ''),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={AssociaFornitoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('copy_collaboration')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'progetto'}
                    label={t('project') ?? ''}
                    keyQuery={'progetti-options'}
                    url={'/api/progetti/options/'}
                    isRequired
                    value={values.progetto}
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.progetto}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('copy')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
