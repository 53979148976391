import { BaseBene, Bene } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  CheckboxField,
  InputField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

export function BeneGridForm() {
  const { t } = useTranslation()
  return (
    <>
      <Grid minHeight="100%" templateColumns="repeat(1, 1fr)" gap={4}>
        <GridItem>
          <TextareaField
            isRequired
            name="descrizione"
            label={t('description') ?? ''}
          />
        </GridItem>
      </Grid>
      <Grid mt={4} minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <InputField name="quantita" label={t('quantity') ?? ''} />
        </GridItem>

        <GridItem>
          <InputField name="seriale" label={t('serial') ?? ''} />
        </GridItem>
      </Grid>
      <Grid mt={4} templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <InputField name="luogo_utilizzo" label={t('place_of_use') ?? ''} />
        </GridItem>
        <GridItem>
          <CheckboxField
            name="non_di_proprieta"
            label={t('not_of_property') ?? ''}
          />
        </GridItem>
        <GridItem>
          <CheckboxField
            name="non_in_uso"
            label={t('not_in_use') ?? ''}
          />
        </GridItem>
        
      </Grid>
      <Grid mt={4} templateColumns="repeat(1, 1fr)" gap={4}>
        <GridItem>
          <TextareaField
            name="causa_dimissione"
            label={t('cause_discharge') ?? ''}
          />
        </GridItem>
      </Grid>
      <Grid mt={4} templateColumns="repeat(1, 1fr)" gap={4}>
        <GridItem>
          <TextareaField name="note" label={t('notes') ?? ''} />
        </GridItem>
      </Grid>
    </>
  )
}

const initialValues: BaseBene = {
  descrizione: '',
  quantita: 1,
  seriale: '',
  luogo_utilizzo: '',
  non_di_proprieta: false,
  causa_dimissione: '',
  note: '',
}

interface BeneFormProps {
  bene?: Bene
  save: (bene: Bene) => Promise<void>
}

export default function BeneForm({ bene, save }: BeneFormProps) {
  const { t } = useTranslation()

  const BeneSchema = Yup.object().shape({
    descrizione: Yup.string().required().label(t('description')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('goods'),
        link: '/goods',
      },
      {
        label: t('new_good'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('goods'),
        link: '/goods',
      },
      {
        label: bene?.descrizione ?? '',
        link: `/goods/${bene?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, bene])

  return (
    <Formik
      validationSchema={BeneSchema}
      onSubmit={(bene, { setErrors, setSubmitting }) =>
        save({
          ...bene,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(bene ?? initialValues) as Bene}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={bene ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2} overflow={'auto'} className={'card-scroll-height'}>
              <CardBody>
                <BeneGridForm />
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
