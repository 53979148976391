import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import FinanziamentiList from './FinanziamentiList'
import FinanziamentoCreate from './FinanziamentiCreate'
import Finanziamento from './Finanziamento'
import FinanziamentoEdit from './FinanziamentoEdit'

export default function Finanziamenti() {
  return (
    <Routes>
      <Route index element={<FinanziamentiList />} />
      <Route path='/create' element={<FinanziamentoCreate />} />
      <Route path={':finanziamento'} element={<Finanziamento />} />
      <Route path={':finanziamento/edit'} element={<FinanziamentoEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
