import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import PaeseInterventoDetail from './PaeseInterventoDetail'
import PaesiInterventoCreate from './PaeseInterventoCreate'
import PaesiInterventoEdit from './PaeseInterventoEdit'
import PaesiInterventoList from './PaesiInterventoList'
import { useCanGestioneAnagrafiche } from '../../permissions'
import ProtectedRoute from '../../components/ProtectedRoute'
import PaesiInterventoOverview from './PaesiInterventoOverview'

export default function PaesiIntervento() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<PaesiInterventoList />} />
      <Route path="/overview" element={<PaesiInterventoOverview />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <PaesiInterventoCreate />
          </ProtectedRoute>
        }
      />
      <Route path=":id" element={<PaeseInterventoDetail />} />
      <Route
        path=":id/edit"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <PaesiInterventoEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
