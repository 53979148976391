import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import { useCanGestioneAnagrafiche } from '../../permissions'
import AreaInterventoDetail from './AreaInterventoDetail'
import AreeInterventoCreate from './AreeInterventoCreate'
import AreeInterventoEdit from './AreeInterventoEdit'
import AreeInterventoList from './AreeInterventoList'

export default function AreeIntervento() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<AreeInterventoList />} />
      {userCanGestioneAnagrafiche && (
        <Route path={'/create'} element={<AreeInterventoCreate />} />
      )}
      <Route path={'/:id'} element={<AreaInterventoDetail />} />
      {userCanGestioneAnagrafiche && (
        <Route path={'/:id/edit'} element={<AreeInterventoEdit />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
