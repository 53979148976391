import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import {
  useAreeIntervento,
  useAreeInterventoDownload,
} from '../../hooks/aree-intervento'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import { useNavigate } from 'react-router'
import { useCanGestioneAnagrafiche } from '../../permissions'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'nome',
})

export default function AreeInteventoList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useAreeIntervento(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const downloadWithParams = useAreeInterventoDownload(filters)
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('intervention_areas'),
      },
    ]
  }, [t])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneAnagrafiche && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/intervention-areas/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFilters={setFilters}
            setFiltersDebounced={setFiltersDebounced}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[{ value: 'nome', label: t('name') }]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Table variant={'pinc'} mt={3}>
                <Thead>
                  <Tr>
                    <Th>{t('name')}</Th>
                    <Th>{t('countries_of_intervention')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data!.results.map((area) => (
                    <Tr padding={2} key={area.id}>
                      <Td>
                        <LangLinkWithStyle
                          to={`/intervention-areas/${area.id}`}
                        >
                          {area.nome}
                        </LangLinkWithStyle>
                      </Td>
                      <Td>
                        {area?.paesi_intervento_data
                          .map((paese) => paese.nome)
                          .join(', ')}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
