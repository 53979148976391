import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCheck, BiX } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useAccountUser, useDeleteAccountUser, useReSendUserActivationLink } from '../../hooks/users'
import { useCanGestioneUtenti } from '../../permissions'
import createStringLink from '../../utils'
import ModalDelete from '../../components/ModalDelete'

export default function UserDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const { data } = useAccountUser(Number(id!))
  const userCanGestioneUtenti = useCanGestioneUtenti()

  const deleteUser = useDeleteAccountUser()

  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()

  const reinviaMail = useReSendUserActivationLink()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('users'),
        link: '/users',
      },
      {
        label: data?.full_name ?? '',
      },
    ]
  }, [t, data])

  const toast = useToast()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneUtenti && (
                <Button
                  size={'sm'}
                  me={4}
                  color={'white'}
                  onClick={() => {
                    reinviaMail.mutateAsync(+id!).then(() => {
                      toast({
                        title: 'Email inviata',
                        description: 'Email inviata con successo',
                        icon: 'success',
                        status: 'success',
                        duration: 10000,
                        isClosable: true,
                      })
                    }
                    )
                  }}
                  type={'submit'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                >
                  {t('resend_activation_email')}
                </Button>
              )}
              {userCanGestioneUtenti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: `/users/${id}/edit`,
                        lang: i18n.language,
                      })
                    )
                  }}
                  type={'submit'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                >
                  {t('edit')}
                </Button>
              )}
              {userCanGestioneUtenti && (
                <Button
                  size={'sm'}
                  ms={4}
                  _hover={{
                    bg: 'error',
                    color: 'white',
                    opacity: 0.8,
                  }}
                  onClick={() => {
                    onOpen()
                  }}
                  bg={'error'}
                  color={'white'}
                >
                  {t('delete')}
                </Button>
              )}
            </Box>
          }
        />
        <Card px={2}>
          <CardBody>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('email')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.email}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('name')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.full_name}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('duty_station')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.permessi?.duty_station_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('staff_status')}
                </Text>
              </GridItem>
              <GridItem>
                {data?.is_staff ? (
                  <BiCheck size={20} color="green" />
                ) : (
                  <BiX size={20} color="red" />
                )}
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('active')}
                </Text>
              </GridItem>
              <GridItem>
                {data?.is_active ? (
                  <BiCheck size={20} color="green" />
                ) : (
                  <BiX size={20} color="red" />
                )}
              </GridItem>
            </Grid>
            {!data?.is_staff && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('countries_of_intervention')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {data?.permessi?.paesi_intervento_data?.map((paese) => (
                      <Text key={paese.id}>{paese.nome}</Text>
                    ))}
                  </Text>
                </GridItem>
              </Grid>
            )}

            {!data?.is_staff && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('projects')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {data?.permessi?.progetti_data?.map((progetto) => (
                      <Text key={progetto.id}>{progetto.codice}</Text>
                    ))}
                  </Text>
                </GridItem>
              </Grid>
            )}

            {!data?.is_superuser && !data?.is_staff && (
              <>
                <Box mt={5}>
                  <Text fontSize={17} fontWeight={500}>
                    {t('permissions')}
                  </Text>
                </Box>
                <Table mt={4} variant={'striped'}>
                  <Tbody>
                    <Tr>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('create_user')}
                      </Td>
                      <Td>
                        {data?.permessi?.gestione_utenti ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('supplier_management')}
                      </Td>
                      <Td>
                        {data?.permessi?.gestione_fornitori ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('data_management')}
                      </Td>
                      <Td>
                        {data?.permessi?.gestione_anagrafiche ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('goods_management')}
                      </Td>
                      <Td>
                        {data?.permessi?.gestione_beni ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('create_projects')}
                      </Td>
                      <Td>
                        {data?.permessi?.creazione_progetti ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('create_exchange_values')}
                      </Td>
                      <Td>
                        {data?.permessi?.inserimento_modifica_cambi ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('create_budgets')}
                      </Td>
                      <Td>
                        {data?.permessi?.inserimento_modifica_budget ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('management_accounts')}
                      </Td>
                      <Td>
                        {data?.permessi?.gestione_conti ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('insert_movements')}
                      </Td>
                      <Td>
                        {data?.permessi?.inserimento_movimenti ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('modify_movements')}
                      </Td>
                      <Td>
                        {data?.permessi?.modifica_movimenti ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                      <Td fontWeight={'500'} fontSize={14}>
                        {t('restriction_goods_suppliers')}
                      </Td>
                      <Td>
                        {data?.permessi?.limitazione_beni_fornitori ? (
                          <BiCheck size={20} color="green" />
                        ) : (
                          <BiX size={20} color="red" />
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteUser.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/users`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.full_name}
        title={t('delete_user')}
      />
    </Box>
  )
}
