import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  Textarea,
  TextareaProps,
  Select as ChakraSelect,
  SelectProps,
  Link,
  Button,
} from '@chakra-ui/react'
import { getIn, useField, useFormikContext } from 'formik'
import { ChakraFieldProps, OptionSelect } from '../../types'
import { useChakraFieldProps } from '../../hooks/chakra-field-props'
import { OnChangeValue, Select } from 'chakra-react-select'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useOptions } from '../../hooks/options'
import { BsPlusCircleFill } from 'react-icons/bs'
import { enGB, it, pt, es, fr } from 'date-fns/locale'
import { MdClose, MdRemove } from 'react-icons/md'

registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('fr', fr)
registerLocale('es', es)
registerLocale('pt', pt)

export interface InputFormikProps extends ChakraFieldProps<InputProps> {
  onChange?: (value: any) => void
}

/** `InputFormik` connects Chakra's `Input` component as a Formik field. */
export const InputField = (props: InputFormikProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  return (
    <FormControl
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      isInvalid={error && isTouched}
    >
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Input
        size={'sm'}
        {...field}
        borderRadius={6}
        _focusVisible={{
          borderColor: 'brandSecondary',
        }}
        onChange={(event: any) => {
          if (props.onChange) {
            props.onChange(event.target.value)
          } else {
            field.onChange(event)
          }
        }}
        formNoValidate
        step={0}
        borderColor={'brandLight'}
        boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.05)'}
        {...props}
        // Block browser auto validation
        isRequired={false}
      />
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface DateFormikProps extends ChakraFieldProps<InputProps> {
  required?: boolean
  width?: string | number
  isClearable?: boolean
  showIcon?: boolean
  portalId?: string
}

export const DateField = (props: DateFormikProps) => {
  const { name } = useChakraFieldProps(props)
  const { errors, touched, setFieldValue } = useFormikContext()
  const [field] = useField(name)
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  const { i18n } = useTranslation()

  const datePickerRef = useRef<
    ReactDatePicker & HTMLInputElement & { input: HTMLInputElement }
  >(null)

  // if tab is pressed close the datepicker 
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        datePickerRef.current?.setOpen(false)
      }
    },
    [datePickerRef]
  )

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.input.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (datePickerRef.current) {
        datePickerRef.current.input.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [datePickerRef, handleKeyDown])

  return (
    <FormControl isRequired={props.isRequired} isInvalid={error && isTouched} >
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <ReactDatePicker
        showIcon={props.showIcon ?? true}
        isClearable={props.isClearable ?? true}
        locale={i18n.language}
        ref={
          datePickerRef
        }
        autoComplete={'off'}
        // avoid z-index issue 
        portalId={
          props.portalId
        }
        clearButtonTitle={'Clear value'}
        name={name}
        onChange={(date) => {
          if (dayjs(date).isValid()) {
            setFieldValue(name, dayjs(date).format('YYYY-MM-DD'))
          } else {
            setFieldValue(name, '')
          }
        }}
        className={
          isTouched && error
            ? 'react-datepicker-wrapper is-invalid'
            : 'react-datepicker-wrapper'
        }
        clearButtonClassName={'clear-button'}
        dateFormat={'dd/MM/yyyy'}
        selected={(field.value && dayjs(field.value).toDate()) || null}
      />
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface TextareaFormikProps extends ChakraFieldProps<TextareaProps> {
  required?: boolean
}

export const TextareaField = (props: TextareaFormikProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  return (
    <FormControl isRequired={props.isRequired} isInvalid={error && isTouched}>
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Textarea
        size={'sm'}
        {...field}
        borderRadius={6}
        _focusVisible={{
          borderColor: 'brandSecondary',
        }}
        borderColor={'brandLight'}
        boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.05)'}
        {...props}
        isRequired={false}
      />
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface CheckboxFormikProps extends ChakraFieldProps<CheckboxProps> {
  required?: boolean
}

export const CheckboxField = (props: CheckboxFormikProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  return (
    <FormControl isInvalid={error && isTouched}>
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Box>
        <Checkbox
          {...field}
          borderRadius={6}
          _focusVisible={{
            borderColor: 'brandSecondary',
          }}
          colorScheme={'orange'}
          isChecked={
            field.value === true || field.value === 'true' || field.value === 1
          }
          borderColor={'brandLight'}
          boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.05)'}
          {...props}
        />
      </Box>
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface ReactSelectProps extends ChakraFieldProps<InputProps> {
  required?: boolean
  options: any[]
  onInputChange?: (value: string) => void
  value?: any
}

interface RSOption {
  label: string
  value: string
}

export const ReactSelectMultiField = (props: ReactSelectProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched, setFieldValue } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)

  const selectValue = useMemo(
    () =>
      field.value
        ? props.options?.filter((d) => field.value.includes(d.value))
        : [],
    [field.value, props.options]
  )

  const handleChange = useCallback(
    (value: OnChangeValue<RSOption, true>) => {
      setFieldValue(
        field.name,
        value.map((v) => Number(v.value))
      )
    },
    [field.name, setFieldValue]
  )

  return (
    <FormControl
      size={'sm'}
      isRequired={props.isRequired}
      isInvalid={error && isTouched}
    >
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Select
        isMulti
        size={'sm'}
        required={props.required}
        // onInputChange={props.onInputChange}
        options={props.options}
        isSearchable
        selectedOptionColorScheme={'orange'}
        focusBorderColor="brandSecondary"
        className="react-select-container"
        placeholder={''}
        {...field}
        onChange={handleChange}
        value={selectValue}
      />
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface AutoCompleteFieldProps extends ChakraFieldProps<InputProps> {
  isRequired?: boolean
  url?: string
  isMulti?: boolean
  isDisabled?: boolean
  keyQuery?: string
  params?: any
  value?: any
  name: string
  addOption?: boolean
  onAddOptionOpen?: () => void
  filterName?: string
  onChange?: (value: any) => void
}

export default function AutoCompleteField({
  value,
  url = '/api/progetti/options/',
  isMulti = false,
  isDisabled = false,
  isReadOnly = false,
  keyQuery = 'id',
  params = {},
  isRequired,
  name,
  addOption = false,
  onAddOptionOpen,
  filterName = 'id',
  onChange,
  ...props
}: AutoCompleteFieldProps) {
  const [search, setSearch] = useState<string>('')
  const [focus, setFocus] = useState(false)
  const { t } = useTranslation()

  const valueToSend = useMemo(() => {
    if (!isMulti) {
      return value
    }
    if (!value || value.length === 0) {
      return []
    }
    const finalValue = value.join(',')
    return finalValue
  }, [isMulti, value])

  const { data: items, isFetching: isFetchingPrimary } = useOptions(
    {
      ...params,
      search,
      exclude_ids: params.exclude_ids
        ? params.exclude_ids
        : isMulti
          ? valueToSend
          : [],
    },
    !!search || focus,
    keyQuery,
    url
  )

  const paramsToSend = useMemo(() => {
    if (valueToSend) {
      if (!isMulti) {
        if (filterName === 'id') {
          return {
            id: valueToSend,
          }
        } else {
          return {
            [filterName]: valueToSend,
          }
        }
      } else {
        return {
          ids: valueToSend,
        }
      }
    }
  }, [isMulti, filterName, valueToSend])

  const { data: values, isFetching } = useOptions(
    paramsToSend,
    !!valueToSend || focus,
    keyQuery,
    url
  )

  const valueForSelect = useMemo(() => {
    if (!value || value.length === 0 || isFetching) {
      return []
    }
    return values?.map((v) => ({
      value: String(v.value),
      label: v.label,
    }))
  }, [values, value, isFetching])

  const [field] = useField(name)
  const { errors, touched, setFieldValue } = useFormikContext()

  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      isInvalid={error && isTouched}
    >
      <FormLabel
        display={'flex'}
        fontSize={14}
        fontWeight={500}
        alignItems={'center'}
      >
        <Box me={addOption ? 2 : 0}>{props.label}</Box>{' '}
        {addOption && (
          <BsPlusCircleFill
            cursor={'pointer'}
            fill="orange"
            onClick={onAddOptionOpen}
          />
        )}
      </FormLabel>
      <Select
        isReadOnly={isReadOnly}
        isMulti={isMulti}
        isSearchable
        isClearable={!isReadOnly}
        selectedOptionColorScheme={'orange'}
        focusBorderColor="brandSecondary"
        placeholder={''}
        options={items ?? []}
        {...field}
        onFocus={() => {
          setFocus(true)
        }}
        openMenuOnClick={true}
        hideSelectedOptions={true}
        isDisabled={isDisabled}
        noOptionsMessage={() => t('digit_for_search')}
        onChange={(value: any) => {
          if (onChange) onChange(value)
          if (!isMulti) {
            setFieldValue(field.name, value?.value ?? null)
          } else {
            setFieldValue(
              field.name,
              value?.map((v: any) => Number(v.value))
            )
          }
        }}
        isLoading={isFetchingPrimary}
        size={'sm'}
        onInputChange={(value) => {
          setSearch(value)
        }}
        value={valueForSelect}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface ReactSelectProps extends ChakraFieldProps<InputProps> {
  required?: boolean
  options: any[]
  onInputChange?: (value: string) => void
  onChange?: (value: any) => void
  value?: any
  isAutoFocus?: boolean
}

export const ReactSelectField = (props: ReactSelectProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched, setFieldValue } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)

  const refSelect = useRef<any>(null)

  useEffect(() => {
    if (props.isAutoFocus) {
      refSelect.current?.focus()
    }
  }, [props.isAutoFocus])


  return (
    <FormControl
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      isInvalid={error && isTouched}
    >
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Select
        size={'sm'}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        options={props.options}
        isSearchable
        ref={refSelect}
        // autofocus 
        isClearable
        selectedOptionColorScheme={'orange'}
        focusBorderColor="brandSecondary"
        placeholder={''}
        {...field}
        onChange={(option: OptionSelect) => {
          if (props.onChange) {
            props.onChange(option)
          }
          if (option) {
            setFieldValue(field.name, option.value)
          } else {
            setFieldValue(field.name, null)
          }
        }}
        value={props.value}
      />
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface InputFileProps extends ChakraFieldProps<InputProps> { }

export const InputFileField = (props: InputFileProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched, setFieldValue } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  const { t } = useTranslation()
  const inputFileRef = useRef<HTMLInputElement>(null)
  return (
    <FormControl
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      isInvalid={error && isTouched}
    >
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Input
        borderRadius={6}
        _focusVisible={{
          borderColor: 'brandSecondary',
        }}
        onChange={(event: any) => {
          setFieldValue(field.name, event.target.files[0])
        }}
        borderColor={'brandLight'}
        boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.05)'}
        {...props}
        display={'none'}
        ref={inputFileRef}
        type="file"
      />
      <Box display={'flex'} alignItems={'center'}>
        <Box>
          <Button
            display={'flex'}
            alignItems={'center'}
            onClick={() => {
              inputFileRef.current?.click()
            }}
            variant={'outline'}
            size={'xs'}
            colorScheme={'orange'}
          >
            <BsPlusCircleFill cursor={'pointer'} fill="orange" />{' '}
            <Box ms={2}>{t('choose_file')}</Box>
          </Button>
        </Box>
        {field.value && (
          <Box ms={2} color={'brand'}>
            <Link
              href={field.value}
              fontWeight={500}
              fontSize={13}
              isExternal
              target="_blank"
              rel="noreferrer"
            >
              {t('uploaded_file')}
            </Link>
          </Box>
        )}
        {/* remove file */}
        {field.value && (
        <Box ms={2}>
          <Button
            onClick={() => {
              setFieldValue(field.name, null)
            }}
            variant={'outline'}
            colorScheme={'orange'}
            size={'xs'}
          >
            <MdClose />
          </Button>
        </Box>
      )}
      </Box>
      
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface SelectChakraProps extends ChakraFieldProps<SelectProps> { }

export const SelectChakraField = (props: SelectChakraProps) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  return (
    <FormControl
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
      isInvalid={error && isTouched}
    >
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <ChakraSelect
        {...field}
        size={'sm'}
        borderRadius={6}
        _focusVisible={{
          borderColor: 'brandSecondary',
        }}
        formNoValidate
        borderColor={'brandLight'}
        boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.05)'}
        {...props}
        isRequired={false}
      >
        {props.children}
      </ChakraSelect>
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export interface InputAddSubtractOneChakraProps
  extends ChakraFieldProps<InputProps> { }

export const InputAddSubtractOneChakraField = (
  props: InputAddSubtractOneChakraProps
) => {
  const { name } = useChakraFieldProps(props)
  const [field] = useField(name)
  const { errors, touched, setFieldValue } = useFormikContext()
  const error = getIn(errors, name)
  const isTouched = getIn(touched, name)
  return (
    <FormControl isInvalid={error && isTouched}>
      <FormLabel display={'flex'} fontSize={14} fontWeight={500}>
        {props.label}
      </FormLabel>
      <Box display={'flex'}>
        <Button
          onClick={() => {
            if (field.value > 0) {
              setFieldValue(field.name, field.value - 1)
            }
          }}
        >
          <MinusIcon color={'brand'} />
        </Button>
        <Input
          ms={2}
          me={2}
          {...field}
          borderRadius={6}
          _focusVisible={{
            borderColor: 'brandSecondary',
          }}
          width={100}
          textAlign={'center'}
          borderColor={'brandLight'}
          boxShadow={'0px 1px 2px rgba(0, 0, 0, 0.05)'}
          {...props}
        />
        <Button
          onClick={() => {
            setFieldValue(field.name, field.value + 1)
          }}
        >
          <AddIcon color={'brand'} />
        </Button>
      </Box>
      {isTouched && error && (
        <FormErrorMessage color={'error'} fontSize={14} fontWeight={400}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
