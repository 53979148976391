import { createContext, useContext } from 'react'
import { useCausali } from '../hooks/causali'
import { useAuthUser } from 'use-eazy-auth'

interface ContextCausali {
  causali: any
}

const CausaleStateContext = createContext<ContextCausali>({
  causali: [],
})

export function CausaliContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { user } = useAuthUser()
  const { data: causali } = useCausali(user)

  return (
    <CausaleStateContext.Provider
      value={{
        causali: causali ?? [],
      }}
    >
      {children}
    </CausaleStateContext.Provider>
  )
}

export function useCausaleContextState() {
  const filters = useContext(CausaleStateContext)
  return filters
}
