import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  DutyStation,
  BaseDutyStation,
  PaginatedDJResponse,
} from '../types'
import { useDownloadFile } from './download'

export function useDutyStations(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<DutyStation>>(
    ['duty-stations', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/duty-stations/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteDutyStation() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/duty-stations/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['duty-stations'])
        client.invalidateQueries(['duty-station'])
      },
    }
  )
}

export function useCreateDutyStation() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (duty: BaseDutyStation) =>
        axios
          .post(`/api/duty-stations/`, duty, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as DutyStation)
    ),
    {
      onSuccess(savedDutyStation) {
        client.invalidateQueries(['duty-stations'])
        client.setQueryData(
          ['duty-stations', savedDutyStation.id],
          savedDutyStation
        )
      },
    }
  )
}

export function useUpdateDutyStation() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (duty: DutyStation) =>
        axios
          .put(`/api/duty-stations/${duty.id}/`, duty, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as DutyStation)
    ),
    {
      onSuccess(savedDuty) {
        client.invalidateQueries(['duty-stations'])
        client.setQueryData(['duty-station', savedDuty.id], savedDuty)
      },
    }
  )
}

export function useDutyStation(id: number) {
  return useQuery<DutyStation>(
    ['duty-station', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/duty-stations/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDutyStationsDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/duty-stations/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
