import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateAccountUser } from '../../hooks/users'
import createStringLink from '../../utils'
import UserForm from './UserForm'

export default function UserCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createAccountUser = useCreateAccountUser()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <UserForm
        save={async (user) => {
          const savedUser = await createAccountUser.mutateAsync(user)
          navigate(
            createStringLink({
              path: `/users/${savedUser.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
