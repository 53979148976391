import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Finanziamento, PaginatedDJResponse } from '../types'

export function useFinanziamenti(params: Record<string, any> = {}) {
    return useQuery<PaginatedDJResponse<Finanziamento>>(
      ['finanziamenti', params],
      useAuthCallPromise(
        (token: string) =>
          ({ signal }) =>
            axios
              .get(`/api/finanziamenti/?${new URLSearchParams(params).toString()}`, {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((r) => r.data)
      ),
      {
        keepPreviousData: true,
      }
    )
  }

export function useDeleteFinanziamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/finanziamenti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['finanziamenti'])
        client.invalidateQueries(['finanziamento'])
      },
    }
  )
}

export function useCreateFinanziamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (finanziamento: Finanziamento) =>
        axios
          .post(`/api/finanziamenti/`, finanziamento, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Finanziamento)
    ),
    {
      onSuccess(savedFinanziamento) {
        client.invalidateQueries(['finanziamenti'])
        client.setQueryData(['finanziamento', savedFinanziamento.id], savedFinanziamento)
      },
    }
  )
}

export function useUpdateFinanziamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (finanziamento: Finanziamento) =>
        axios
          .put(`/api/finanziamenti/${finanziamento.id}/`, finanziamento, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Finanziamento)
    ),
    {
      onSuccess(savedFinanziamento) {
        client.invalidateQueries(['finanziamenti'])
        client.setQueryData(['finanziamento', savedFinanziamento.id], savedFinanziamento)
      },
    }
  )
}

export function useFinanziamento(id: number) {
  return useQuery<Finanziamento>(
    ['finanziamento', id],
    useAuthCallPromise(
      (token: string) => () =>
        axios
          .get(`/api/finanziamenti/${id}/`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Finanziamento)
    )
  )
}

