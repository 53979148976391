import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import {
  useContiBancari,
  useContiBancariDownload,
  useImportContiBancari,
} from '../../hooks/conti-bancari'
import { useCanGestioneConti } from '../../permissions'
import ContiTable from './ContiTable'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'
import { Select } from 'chakra-react-select'
import ModalImportContiBancari from './ModalImportContiBancari'
import { useAuthUser } from 'use-eazy-auth'
import { PaeseIntervento } from '../../types'
import { useDownloadMovimentiConti } from '../../hooks/movimenti'
import { useDownloadLastSaldoCertificatiConti } from '../../hooks/saldi-certificati'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'nome_banca',
  paese_intervento: params.get('paese_intervento') ?? '',
  stato: params.get('stato') ?? '',
})

export default function ContiList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)

  const { user } = useAuthUser()

  console.log('user', user)

  const filtersWithPaese = useMemo(() => {
    if (user.is_superuser) {
      return filters
    }
    return {
      ...filters,
      _paesi_intervento: user.permessi.paesi_intervento_data
        .map((p: PaeseIntervento) => p.id)
        .join(),
    }
  }, [filters, user])

  const { data, isLoading, isRefetching } = useContiBancari(filtersWithPaese)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('accounts'),
      },
    ]
  }, [t])

  const downloadWithParams = useContiBancariDownload(filters)
  const userCanGestioneConti = useCanGestioneConti()

  const [showFilters, setShowFilters] = useState(true)

  const booleanFilter = useMemo(() => {
    return [
      {
        value: 'true',
        label: t('open'),
      },
      {
        value: 'false',
        label: t('closed'),
      },
    ]
  }, [t])

  const importContiBancari = useImportContiBancari()

  const {
    isOpen: isOpenImportContiBancari,
    onOpen: onOpenImportContiBancari,
    onClose: onCloseImportContiBancari,
    onToggle: onToggleImportContiBancari,
  } = useDisclosure()

  const [isLoadingImport, setIsLoadingImport] = useState(false)

  const [errors, setErrors] = useState(null)

  const [contiSelected, setSelectedConti] = useState<number[]>([])

  const downloadMovimentiConti = useDownloadMovimentiConti(contiSelected)

  const downloadSaldiCertificatiConti = useDownloadLastSaldoCertificatiConti(
    contiSelected
  )

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={breadCrumbsItems} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {contiSelected.length > 0 && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    downloadSaldiCertificatiConti()
                  }}
                >
                  {t('download_last_certified_balances')}
                </Button>
              )}
              {contiSelected.length > 0 && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    downloadMovimentiConti()
                  }}
                >
                  {t('download_movements_accounts')}
                </Button>
              )}
              {userCanGestioneConti && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'darkblue'}
                  border={'1px solid'}
                  fontWeight={500}
                  _hover={{ bg: 'darkblue', color: 'white' }}
                  onClick={() => {
                    onOpenImportContiBancari()
                  }}
                >
                  {t('import')}
                </Button>
              )}
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
              {userCanGestioneConti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/accounts/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            mb={2}
            zIndex={99}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(4, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('country')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.paese_intervento}
                      isMulti={false}
                      url={'/api/paesi-intervento/options/'}
                      keyQuery={'paesi-intervento-options'}
                      onChange={(values: any) => {
                        setFilters({
                          page: 1,
                          paese_intervento: values?.value ?? '',
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('state')}</FormLabel>
                    <Select
                      options={booleanFilter ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        if (!values) {
                          setFilters({
                            ...filters,
                            stato: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            stato: String(values?.value) ?? '',
                            page: 1,
                          })
                        }
                      }}
                      value={
                        booleanFilter?.find(
                          (v) => String(v.value) === filters.stato
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'nome_banca', label: t('name_bank') },
              { value: 'numero_conto', label: t('account_number') },
              { value: 'tipo_conto', label: t('account_type') },
              { value: 'valuta__codice', label: t('currency') },
              { value: 'saldo_estratto_conto', label: t('balance') },
              { value: 'paese_intervento__nome', label: t('country') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                mt={3}
                overflowY={'auto'}
                width={'100%'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <ContiTable
                  data={data}
                  setSelectedConti={setSelectedConti}
                  contiSelected={contiSelected}
                  isRefetching={isRefetching}
                />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {isOpenImportContiBancari && (
        <ModalImportContiBancari
          isOpen={isOpenImportContiBancari}
          onClose={() => {
            onCloseImportContiBancari()
            setErrors(null)
          }}
          onOpen={onOpenImportContiBancari}
          errors={errors}
          onToggle={() => {
            onToggleImportContiBancari()
            setErrors(null)
          }}
          isLoading={isLoadingImport}
          onConfirm={async (values) => {
            setIsLoadingImport(true)
            await importContiBancari
              .mutateAsync({
                importFile: values.import_file,
              })
              .then(() => {
                setIsLoadingImport(false)
                onCloseImportContiBancari()
              })
              .catch((err) => {
                setErrors(err.response.data)
                setIsLoadingImport(false)
              })
          }}
        />
      )}
    </Box>
  )
}
