import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useMemo } from 'react'
import { BaseCollaborazione, Collaboratore, Collaborazione } from '../../../types'
import { useParams } from 'react-router'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import {
  DateField,
  InputField,
  InputFileField,
  TextareaField,
  transformErrorForForm,
} from '../../../components/form'
import BreadCrumb from '../../../components/BreadCrumb'
import { useProgettoSimple } from '../../../hooks/progetti'
import AutoCompleteField from '../../../components/form/fields'

export const CollaborazioneGridForm = ({
  collaborazione,
  progettoAsField = false,
  collaboratore,
  paesiIntervento,
}: {
  collaborazione?: Collaborazione
  progettoAsField?: boolean
  collaboratore?: Collaboratore | undefined
  paesiIntervento?: number[] | undefined | null
}) => {
  const { t } = useTranslation()

  const { values } = useFormikContext<BaseCollaborazione>()

  // check if paesiIntervento is not null or undefined and has at least one element isn't null or undefined
  const paesiInterventoHasValue = paesiIntervento && paesiIntervento.length > 0 && paesiIntervento[0] !== null && paesiIntervento[0] !== undefined


  return (
    <>
      <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
        {progettoAsField && (
          <GridItem>
            <AutoCompleteField
              isRequired
              name="progetto"
              label={t('project') ?? ''}
              params={{
                paesi_intervento: collaboratore?.paese_intervento ? [collaboratore?.paese_intervento] :
                  []
              }}
              url={'/api/progetti/options/'}
              keyQuery={'progetti-options'}
              value={values.progetto}
            />
          </GridItem>
        )}
        <GridItem>
          <AutoCompleteField
            isRequired
            name="collaboratore"
            label={t('collaborator') ?? ''}
            params={
              paesiInterventoHasValue ? { paesi_intervento: paesiIntervento } : {}
            }
            url={'/api/collaboratori/options/'}
            keyQuery={'collaboratori-options'}
            value={values.collaboratore}
          />
        </GridItem>
        {collaborazione && (
          <GridItem>
            <InputField
              isReadOnly
              name="codice_collaborazione"
              label={t('code_collaboration') ?? ''}
            />
          </GridItem>
        )}
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <InputField
            name="qualifica_contrattuale"
            label={t('contractual_qualification') ?? ''}
          />
        </GridItem>
        <GridItem>
          <InputField
            name="localita_progetto"
            label={t('project_locality') ?? ''}
          />
        </GridItem>
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <DateField
            isRequired
            name="data_inizio"
            label={t('date_start') ?? ''}
          />
        </GridItem>
        <GridItem>
          <DateField isRequired name="data_fine" label={t('date_end') ?? ''} />
        </GridItem>
        <GridItem>
          <DateField
            name="data_interruzione"
            label={t('interruption_date') ?? ''}
          />
        </GridItem>
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <AutoCompleteField
            name="valuta"
            label={t('currency') ?? ''}
            url={'/api/valute/options/'}
            keyQuery={'valute-options'}
            value={values.valuta}
            isRequired
          />
        </GridItem>
        <GridItem>
          <InputField
            name="importo_retribuzione"
            label={t('import_retribution') ?? ''}
            type={'number'}
            isRequired
          />
        </GridItem>
        <GridItem>
          <AutoCompleteField
            name="tipo_retribuzione"
            label={t('type_retribution') ?? ''}
            url={'/api/tipi-retribuzione/options/'}
            keyQuery={'tipi-retribuzione-options'}
            value={values.tipo_retribuzione}
            isRequired
          />
        </GridItem>
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <InputField name="tipo_lavoro" label={t('type_work') ?? ''} />
        </GridItem>
        <GridItem colSpan={2}>
          <TextareaField name="note" label={t('notes') ?? ''} />
        </GridItem>
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <InputFileField name="allegato" label={t('attachment') ?? ''} />
        </GridItem>
      </Grid>
    </>
  )
}

const initialValues: BaseCollaborazione = {
  collaboratore: undefined,
  allegato: null,
  data_inizio: '',
  data_fine: '',
  descrizione_collaborazione: '',
  localita_progetto: '',
  importo_retribuzione: null,
  tipo_lavoro: '',
  tipo_retribuzione: undefined,
  qualifica_contrattuale: '',
  valuta: null,
  allegato_2: null,
  allegato_3: null,
  allegato_4: null,
  allegato_5: null,
  data_interruzione: '',
  note: '',
}

interface CollaborazioneFormProps {
  collaborazione?: Collaborazione
  save: (collaborazione: Collaborazione) => Promise<void>
}

export default function CollaborazioneForm({
  collaborazione,
  save,
}: CollaborazioneFormProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const CollaborazioneSchema = Yup.object().shape({
    collaboratore: Yup.string().required().label(t('collaborator')),
    data_inizio: Yup.string().required().label(t('date_start')),
    data_fine: Yup.string().required().label(t('date_end')),
    valuta: Yup.string().required().label(t('currency')),
    importo_retribuzione: Yup.number()
      .required()
      .typeError(t('must_be_a_number') as string)
      .label(t('import_retribution')),
    tipo_retribuzione: Yup.string().required().label(t('type_retribution')),
  })

  const { data: progetto } = useProgettoSimple(Number(id!))

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('collaborations'),
        link: `/projects/${id}/collaborations`,
      },
      {
        label: t('new_collaboration'),
      },
    ]
  }, [t, progetto, id])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('collaborations'),
        link: `/projects/${id}/collaborations`,
      },
      {
        label: collaborazione?.collaboratore_data?.nome ?? '',
        link: `/projects/${id}/collaborations/${collaborazione?.id}`,
      },
      {
        label: t('edit_collaboration'),
      },
    ]
  }, [
    t,
    progetto?.codice,
    id,
    collaborazione?.collaboratore_data?.nome,
    collaborazione?.id,
  ])

  return (
    <Formik
      validationSchema={CollaborazioneSchema}
      onSubmit={(tipo, { setErrors, setSubmitting }) =>
        save({
          ...tipo,
          progetto: Number(id!),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(collaborazione ?? initialValues) as Collaborazione}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    collaborazione ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2} className={'card-scroll-height'} overflow={'auto'}>
              <CardBody>
                <CollaborazioneGridForm collaborazione={collaborazione} paesiIntervento={progetto?.paesi_intervento_data?.map(
                  (paese) => paese.id
                ) ?? []} />
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
