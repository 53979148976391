import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import AutoCompleteField, { ReactSelectField } from '../../../components/form/fields'
import { Progetto } from '../../../types'
import { useMemo, useState } from 'react'

interface Props {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto: Progetto | undefined

}

export default function ModalAssociaFornitore({
  progetto,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    fornitore: null,
  }

  const AssociaFornitoreSchema = Yup.object().shape({
    fornitore: Yup.string().required(t('supplier_is_required') ?? ''),
  })

  const [paeseSelected, setPaeseSelected] = useState<number | null>(null)

  const moreThenOnePaese = useMemo(() => {
    if (!progetto) {
      return false
    }
    if (!progetto.paesi_intervento_data) {
      return false
    }
    return progetto.paesi_intervento_data.length > 1
  }, [progetto])

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      validationSchema={AssociaFornitoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, resetForm }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={() => {
          onClose()
          resetForm()
        }}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('associate_supplier')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {moreThenOnePaese && (
                  <Box>
                    <ReactSelectField
                      name={'paese'}
                      label={t('country') ?? ''}
                      options={progetto?.paesi_intervento_data?.map((p) => ({
                        label: p.nome,
                        value: p.id,
                      })) ?? []}
                      
                      onChange={(value) => {
                        setPaeseSelected(value.value)
                      }}
                    />
                  </Box>
                )}

                <Box mt={2}>
                  <AutoCompleteField
                    isDisabled={!paeseSelected && moreThenOnePaese}
                    name={'fornitore'}
                    label={t('supplier') ?? ''}
                    url={`/api/progetti/${progetto?.id}/options-fornitori-esterni/`}
                    value={
                      values.fornitore
                    }
                    params={{
                      paesi_intervento: paeseSelected ? [paeseSelected] : progetto?.paesi_intervento_data?.map((p) => p.id),
                    }}
                    keyQuery={'fornitori-options'}
                    isRequired

                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.fornitore}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('associate')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
