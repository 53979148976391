import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseCollaborazione,
  BaseDonatore,
  BaseFornitore,
  BaseProgetto,
  Collaborazione,
  ConsuntivoBudget,
  ContoBancario,
  Donatore,
  Fornitore,
  PaginatedDJResponse,
  PermessoUtenteProgetto,
  Progetto,
  ProgettoPerLista,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder, serializeQueryParams } from './utils'

const makeProgettoFormData = makeFormDataEncoder({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

const makeImportBudgetFormData = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useProgetti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<ProgettoPerLista>>(
    ['progetti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/progetti/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
              params: serializeQueryParams(params),
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      // enabled: params.enabled ?? true,
    }
  )
}

export function useProgetto(id: number) {
  return useQuery<Progetto>(
    ['progetto', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/progetti/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useProgettoSimple(id: number) {
  return useQuery<Progetto>(
    ['progetto-simple', id],
    useAuthCallPromise(
      (token: string) =>

        ({ signal }) =>
          axios
            .get(`/api/progetti/${id}/simple`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Progetto)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/progetti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetti'])
        client.invalidateQueries(['progetto'])
      },
    }
  )
}

export function useCreateProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (progetto: BaseProgetto) =>
        axios
          .post(`/api/progetti/`, makeProgettoFormData(progetto), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Progetto)
    ),
    {
      onSuccess(savedProgetto) {
        client.invalidateQueries(['progetti'])
        client.setQueryData(['progetto', savedProgetto.id], savedProgetto)
      },
    }
  )
}

export function useUpdateProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (progetto: Progetto) =>
        axios
          .put(
            `/api/progetti/${progetto.id}/`,
            makeProgettoFormData(progetto),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Progetto)
    ),
    {
      onSuccess(savedProgetto) {
        client.invalidateQueries(['progetti'])
        client.setQueryData(['progetto', savedProgetto.id], savedProgetto)
      },
    }
  )
}

export function useUpdateInserimentoBloccato(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (inserimento_bloccato: boolean) =>
        axios
          .put(
            `/api/progetti/${id}/update-inserimento-bloccato/`,
            { inserimento_bloccato },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Progetto)
    ),
    {
      onSuccess(savedProgetto) {
        client.invalidateQueries(['progetti'])
        client.setQueryData(['progetto', savedProgetto.id], savedProgetto)
      },
    }
  )
}

export function useUpdateBloccoFinale(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (data_blocco_finale: string) =>
        axios
          .put(
            `/api/progetti/${id}/update-blocco-finale/`,
            { data_blocco_finale },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Progetto)
    ),
    {
      onSuccess(savedProgetto) {
        client.invalidateQueries(['progetti'])
        client.setQueryData(['progetto', savedProgetto.id], savedProgetto)
      },
    }
  )
}

export function useUpdateBloccoData(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          data_blocco_compentenza,
          blocco_compentenza_gestori,
        }: {
          data_blocco_compentenza: string
          blocco_compentenza_gestori: boolean
        }) =>
          axios
            .put(
              `/api/progetti/${id}/update-blocco-data/`,
              { data_blocco_compentenza, blocco_compentenza_gestori },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Progetto)
    ),
    {
      onSuccess(savedProgetto) {
        client.invalidateQueries(['progetti'])
        client.setQueryData(['progetto', savedProgetto.id], savedProgetto)
      },
    }
  )
}

export function useFornitoriProgetto(
  id: number,
  params: Record<string, any> = {}
) {
  return useQuery<PaginatedDJResponse<Fornitore>>(
    ['progetto-fornitori', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/progetti/${id}/fornitori/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDonatoriProgetto(
  id: number,
  params: Record<string, any> = {}
) {
  return useQuery<PaginatedDJResponse<Donatore>>(
    ['progetto-donatori', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/progetti/${id}/donatori/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useContiProgetto(id: number, params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<ContoBancario>>(
    ['progetto-conti', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/progetti/${id}/conti/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAddFornitoreProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, fornitore: fornitoreId }) =>
          axios
            .post(
              `/api/progetti/${progettoId}/add-fornitore/`,
              { fornitore: fornitoreId },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-fornitori'])
      },
    }
  )
}

export function useCreateFornitoreProgetto(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (fornitore: BaseFornitore) =>
        axios
          .post(`/api/progetti/${id}/create-fornitore/`, fornitore, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Fornitore)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-fornitori'])
        client.invalidateQueries(['progetto'])
        client.invalidateQueries(['fornitori'])
      },
    }
  )
}

export function useCreateDonatoreProgetto(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (donatore: BaseDonatore) =>
        axios
          .post(`/api/progetti/${id}/create-donatore/`, donatore, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Donatore)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-donatori'])
        client.invalidateQueries(['progetto'])
        client.invalidateQueries(['donatori'])
      },
    }
  )
}

export function useCreateCollaborazioneProgetto(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (collaborazione: BaseCollaborazione) =>
        axios
          .post(`/api/progetti/${id}/create-collaborazione/`, collaborazione, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Collaborazione)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-collaborazioni'])
        client.invalidateQueries(['progetto'])
        client.invalidateQueries(['collaborazioni'])
      },
    }
  )
}

export function useAssociaBeneProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto, bene: beneId, data_associazione, importo_residuo }) =>
          axios
            .post(
              `/api/beni/${beneId}/associa-progetto/`,
              { progetto,
                data_associazione,
                importo_residuo },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['beni'])
        client.invalidateQueries(['bene'])
        client.invalidateQueries(['associazioniBene'])
      },
    }
  )
}

export function useAddDonatoreProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, donatore: donatoreId }) =>
          axios
            .post(
              `/api/progetti/${progettoId}/add-donatore/`,
              { donatore: donatoreId },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-donatori'])
      },
    }
  )
}

export function useAddContoProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, conto: contoId }) =>
          axios
            .post(
              `/api/progetti/${progettoId}/add-conto/`,
              { conto: contoId },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-conti'])
      },
    }
  )
}

export function useRemoveFornitoreProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, fornitore: fornitoreId }) =>
          axios
            .post(
              `/api/progetti/${progettoId}/delete-fornitore/`,
              { fornitore: fornitoreId },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-fornitori'])
      },
    }
  )
}

export function useRemoveDonatoreProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, donatore: donatoreId }) =>
          axios
            .post(
              `/api/progetti/${progettoId}/delete-donatore/`,
              { donatore: donatoreId },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-donatori'])
      },
    }
  )
}

export function useRemoveContoProgetto() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, conto: contoId }) =>
          axios
            .post(
              `/api/progetti/${progettoId}/delete-conto/`,
              { conto: contoId },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-conti'])
      },
    }
  )
}

export function useImportBudgets() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ progetto: progettoId, importFile }) => {
          const formData = makeImportBudgetFormData({ import_file: importFile })
          return axios
            .post(`/api/progetti/${progettoId}/import-budgets/`, formData, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
        }
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetto-budgets'])
      },
    }
  )
}

export function useConsuntivoBudget(
  id: number,
  params: Record<string, any> = {}
) {
  return useQuery<ConsuntivoBudget>(
    ['consuntivo-budget', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/progetti/${id}/consuntivo/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDownloadConsuntivoBudget(id: number, filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader(`/api/progetti/${id}/download-consuntivo/`, filters)
  }, [downloader, filters, id])
  return downloadWithParams
}

export function useDownloadProgetti(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/progetti/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

export interface ProgettoOptions {
  label: string
  value: string
}

export function useProgettiOptions(
  params: Record<string, any> = {},
  enabled: boolean
) {
  return useQuery<ProgettoOptions[]>(
    ['progetti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/progetti/options/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
              params: serializeQueryParams(params),
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: false,
      enabled: enabled,
      suspense: false,
    }
  )
}

export function usePermessiUtenteProgetto(id: number) {
  return useQuery<PermessoUtenteProgetto[]>(
    ['permessi-utente-progetto', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/progetti/${id}/permessi-utenti/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: false,
    }
  )
}
