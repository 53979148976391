import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import ReferenteGenericoDetail from './ReferenteGenericoDetail'
import ReferentiGenericiCreate from './ReferentiGenericiCreate'
import ReferentiGenericiEdit from './ReferentiGenericiEdit'
import ReferentiGenericiList from './ReferentiGenericiList'

export default function ReferentiGenerici() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<ReferentiGenericiList />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <ReferentiGenericiCreate />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<ReferenteGenericoDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <ReferentiGenericiEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
