import { Box, Button, Flex, Spinner } from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useDonatori, useDonatoriDownload } from '../../hooks/donatori'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import { useCanGestioneAnagrafiche } from '../../permissions'
import DonatoriTable from './DonatoriTable'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'generalita',
})

export default function CollaboratoriList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useDonatori(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const downloadWithParams = useDonatoriDownload(filters)
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('donors'),
      },
    ]
  }, [t])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneAnagrafiche && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: '/donors/create',
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'generalita', label: t('generality') },
              {
                value: 'progressivo',
                label: t('progressive'),
              },
              {
                value: 'sede',
                label: t('site'),
              },
              {
                value: 'mail',
                label: t('email'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <DonatoriTable data={data} />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
