import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { BaseBene, Progetto } from '../../types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { BeneGridForm } from './BeneForm'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: BaseBene) => void
  progetto?: Progetto | undefined
}

export default function ModalFormBene({
  progetto,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const BeneSchema = Yup.object().shape({
    descrizione: Yup.string().required().label(t('description')),
  })

  const initialValues: BaseBene = {
    descrizione: '',
    quantita: 1,
    seriale: '',
    luogo_utilizzo: '',
    non_di_proprieta: false,
    causa_dimissione: '',
    note: '',
    progetto: progetto?.id,
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      validationSchema={BeneSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('new_good')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <BeneGridForm />
              </ModalBody>
              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.descrizione}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('create')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
