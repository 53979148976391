import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import ModalDelete from '../../../components/ModalDelete'
import {
  useCollaborazione,
  useCopiaCollaborazione,
  useCreateRipartizioneBudgetCollaborazione,
  useDeleteCollaborazione,
  useRipartizioniBudgetCollaborazione,
} from '../../../hooks/collaborazioni'
import { useProgettoSimple } from '../../../hooks/progetti'
import { useCanGestioneAnagrafiche } from '../../../permissions'
import { RipartizioneCollaborazione } from '../../../types'
import createStringLink, { numberFormatter } from '../../../utils'
import ModalCopiaCollaborazione from './ModalCopiaCollaborazione'
import ModalFormRipartizione from './ModalFormRipartizione'
import RipartizioniTable from './RipartizioniTable'

export default function CollaborazioneDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id, collaboration } = useParams()
  const { data: collaborazione } = useCollaborazione(Number(collaboration!))
  const { data: progetto } = useProgettoSimple(Number(id!))
  const deleteCollaborazione = useDeleteCollaborazione()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const {
    isOpen: isOpenCopia,
    onClose: onCloseCopia,
    onOpen: onOpenCopia,
    onToggle: onToggleCopia,
  } = useDisclosure()
  const {
    isOpen: isOpenRipartizioneCreate,
    onClose: onCloseRipartizioneCreate,
    onOpen: onOpenRipartizioneCreate,
    onToggle: onToggleRipartizioneCreate,
  } = useDisclosure()

  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  const copiaCollaborazione = useCopiaCollaborazione()
  const createRipartizione = useCreateRipartizioneBudgetCollaborazione()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('collaborations'),
        link: `/projects/${id}/collaborations`,
      },
      {
        label: collaborazione?.collaboratore_data?.nome ?? '',
      },
    ]
  }, [t, collaborazione, progetto, id])

  const { data: ripartizioni } = useRipartizioniBudgetCollaborazione({
    collaborazione: Number(collaboration!),
    suspense: true,
  })

  const sumImportiRipartizione = useMemo(() => {
    if (!ripartizioni) return 0
    const sommaImporti = ripartizioni.reduce((partialSum: number, a: any) => {
      return Number(partialSum) + Number(a.importo)
    }, 0)
    return sommaImporti
  }, [ripartizioni])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneAnagrafiche && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    border={'1px solid'}
                    borderColor={'brandBlue'}
                    color={'brandBlue'}
                    bg={'card'}
                    _hover={{
                      bg: 'brandBlue',
                      color: 'white',
                    }}
                    onClick={() => {
                      onOpenCopia()
                    }}
                  >
                    {t('copy_collaboration')}
                  </Button>
                  <Button
                    size={'sm'}
                    me={4}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/projects/${id}/collaborations/${collaborazione?.id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('project')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.progetto_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('code_collaboration')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.codice_collaborazione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('collaborator')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.collaboratore_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('progressive')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.progressivo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('contractual_qualification')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.qualifica_contrattuale}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('date_start')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(collaborazione?.data_inizio).format('DD/MM/YYYY')}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('date_end')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(collaborazione?.data_fine).format('DD/MM/YYYY')}
                </Text>
              </GridItem>
            </Grid>
            {collaborazione?.data_interruzione && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('interruption_date')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {dayjs(collaborazione?.data_interruzione).format(
                      'DD/MM/YYYY'
                    )}
                  </Text>
                </GridItem>
              </Grid>
            )}
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('currency')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.valuta_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('import_retribution')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(
                    collaborazione?.importo_retribuzione || 0
                  )}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('type_retribution')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {collaborazione?.tipo_retribuzione_data?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            {collaborazione?.localita_progetto && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('project_locality')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {collaborazione?.localita_progetto}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {collaborazione?.descrizione_collaborazione && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('description_collaboration')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {collaborazione?.descrizione_collaborazione}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {collaborazione?.note && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('notes')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {collaborazione?.note}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {collaborazione?.allegato && (
              <Grid
                width={'100%'}
                templateColumns={'repeat(5, 1fr)'}
                mt={3}
                gap={6}
              >
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link href={collaborazione?.allegato} isExternal>
                      {t('download')}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(collaborazione?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(collaborazione?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box>{t('repartitions')}</Box>
            <Box>
              <Button
                bg={'brandSecondary'}
                color={'white'}
                _hover={{ bg: 'brand' }}
                size={'sm'}
                onClick={() => onOpenRipartizioneCreate()}
              >
                {t('add_repartition')}
              </Button>
            </Box>
          </CardHeader>
          <CardBody>
            {ripartizioni &&
              ripartizioni?.length > 0 &&
              sumImportiRipartizione !==
                collaborazione?.importo_retribuzione && (
                <Alert status="warning" mb={3}>
                  <AlertIcon />
                  <AlertTitle mr={2}>
                    {t(
                      'the_sum_of_the_amounts_of_the_partitions_is_different_from_the_amount_of_the_collaboration'
                    )}
                  </AlertTitle>
                </Alert>
              )}
            <RipartizioniTable
              valuta={collaborazione?.valuta_data?.codice}
              data={ripartizioni}
              collaborazione={collaborazione}
            />
            <Box
              width={'100%'}
              mt={3}
              display={'flex'}
              justifyContent={'flex-end'}
            ></Box>
          </CardBody>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteCollaborazione.mutateAsync(+collaboration!)
          navigate(
            createStringLink({
              path: `/projects/${id}/collaborations`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={collaborazione?.collaboratore_data?.nome}
        title={t('delete_collaboration')}
      />
      <ModalCopiaCollaborazione
        isOpen={isOpenCopia}
        onClose={onCloseCopia}
        onConfirm={async (progetto) => {
          await copiaCollaborazione.mutateAsync({
            id: +collaboration!,
            progetto_destinazione: progetto.progetto,
          })
          navigate(
            createStringLink({
              path: `/projects/${progetto.progetto}/collaborations`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpenCopia}
        onToggle={onToggleCopia}
        progetto={progetto}
      />
      <ModalFormRipartizione
        isOpen={isOpenRipartizioneCreate}
        onClose={onCloseRipartizioneCreate}
        onConfirm={async (data: RipartizioneCollaborazione) => {
          await createRipartizione
            .mutateAsync({
              ...data,
              collaborazione: +collaboration!,
            })
            .then(() => {
              onCloseRipartizioneCreate()
            })
        }}
        onOpen={onOpenRipartizioneCreate}
        onToggle={onToggleRipartizioneCreate}
        collaborazione={collaborazione}
      />
    </Box>
  )
}
