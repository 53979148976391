import { Box, Spinner } from '@chakra-ui/react'
import { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Outlet } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Sidebar from './Sidebar'

export default function Layout() {
  const { user } = useAuthUser()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.selected_language)
    }
  }, [user, i18n])

  return (
    <Sidebar>
      <Suspense
        fallback={
          <Box
            minHeight={'calc(100vh - 32px)'}
            pt={67}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand"
              size="xl"
            />
          </Box>
        }
      >
        <Box minHeight={'calc(100vh - 32px)'}>
          <Outlet />
        </Box>
      </Suspense>
    </Sidebar>
  )
}
