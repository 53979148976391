import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import SettoreInterventoDetail from './SettoreInterventoDetail'
import SettoriInterventoCreate from './SettoriInterventoCreate'
import SettoriInterventoEdit from './SettoriInterventoEdit'
import SettoriInterventoList from './SettoriInterventoList'

export default function SettoriIntervento() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<SettoriInterventoList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <SettoriInterventoCreate />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<SettoreInterventoDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <SettoriInterventoEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
