import { Box, Button, Flex, Spinner, useDisclosure } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { useBeni, useBeniDownload } from '../../../hooks/beni'
import { useQsFilters } from '../../../hooks/filters'
import { useAssociaBeneProgetto, useProgettoSimple } from '../../../hooks/progetti'
import BeniTable from '../../Beni/BeniTable'
import ModalAssociaBene from './ModalAssociaBene'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'progressivo',
})

export default function BeniProgettoList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const filtersBeni = useMemo(() => {
    return {
      ...filters,
      progetto: id,
    }
  }, [filters, id])
  const { data, isLoading, refetch } = useBeni(filtersBeni)
  const { t } = useTranslation()
  const downloadWithParams = useBeniDownload(filtersBeni)
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const associaBene = useAssociaBeneProgetto()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('goods'),
      },
    ]
  }, [t, progetto, id])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                color={'white'}
                _hover={{ bg: 'brand' }}
                bg={'brandSecondary'}
                onClick={onOpen}
              >
                {t('associate_good')}
              </Button>
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'progressivo', label: t('progressive') },
              { value: 'descrizione', label: t('description') },
              { value: 'progetto__titolo', label: t('project') },
              { value: 'luogo_utilizzo', label: t('place_of_use') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <BeniTable data={data} />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <ModalAssociaBene
          isOpen={isOpen}
          onClose={onClose}
          onToggle={onToggle}
          progetto={progetto}
          onConfirm={async (bene) => {
            await associaBene
              .mutateAsync({
                bene: bene.bene,
                progetto: progetto?.id ?? 0,
              })
              .then(() => {
                onClose()
                refetch()
              })
          }}
          onOpen={onOpen}
          title={t('associate_supplier')}
        />
      )}
    </Box>
  )
}
