import { Box, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import {
  useCreateMultipleMovimenti,
  useMovimentiMulti,
} from '../../../hooks/movimenti'
import createStringLink from '../../../utils'
import MovimentoMultipleForm from './MovimentiMultipleForm'
import { useQsFilters } from '../../../hooks/filters'

const initFilters = (params: URLSearchParams) => ({
  movimenti: params.getAll('movimenti') ?? undefined,
})

export default function MovimentoEditMultiple() {
  const { filters: movimentiQueryString } = useQsFilters(initFilters)
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const createMovimentiMultipli = useCreateMultipleMovimenti()

  const movimentiData = useMovimentiMulti({
    ids: movimentiQueryString.movimenti.map((m) => Number(m)).join(','),
  })

  const { t } = useTranslation()
  const toast = useToast()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <MovimentoMultipleForm
        movimenti={movimentiData?.data ?? []}
        save={async (movimenti) => {
          console.log(movimenti)
          await createMovimentiMultipli.mutateAsync(movimenti)
          toast({
            title: t('edit_multiple'),
            description:
              t('you_have_modify') +
              ' ' +
              movimenti.movimenti.length +
              ' ' +
              t('movements'),
            status: 'success',
            duration: 10000,
            isClosable: true,
          })
          navigate(
            createStringLink({
              path: `/projects/${id}/movements/?movimentiEdited=${movimenti.length}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
