import { Box, Button } from '@chakra-ui/react'
import { useMemo } from 'react'
import { MdOutlineArrowBack, MdOutlineArrowForward } from 'react-icons/md'
import { PAGE_SIZE } from '../consts'

function paginationRange(
  current: number,
  total: number,
  delta = 1,
  separator = '...'
) {
  if (total <= 1) return [1]

  const center = [current]
  for (let i = 1; i <= delta; i++) {
    center.push(current + i)
    center.unshift(current - i)
  }
  if (delta === 1 && current === 1) {
    center.push(3)
  }
  if (delta === 1 && current === total) {
    center.unshift(total - 2)
  }

  const filteredCenter: (number | string)[] = center.filter(
      (p) => p > 1 && p < total
    ),
    includeLeft = current === delta + 2 + 1,
    includeRight = current === total - delta - 2,
    includeLeftDots = current > delta + 2 + 1,
    includeRightDots = current < total - delta - 2

  if (includeLeft) filteredCenter.unshift(2)
  if (includeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift(separator)
  if (includeRightDots) filteredCenter.push(separator)

  return [1, ...filteredCenter, total]
}

interface Props {
  count: number
  currentPage: number
  goToPage(page: number): void
  className?: string
}

const Paginator = ({ count, currentPage, goToPage }: Props) => {
  const numPages = Math.ceil(count / PAGE_SIZE)
  const longPages = useMemo(
    () => paginationRange(currentPage, numPages, 3),
    [currentPage, numPages]
  )

  return (
    <Box aria-label="Paginator">
      <Button
        size={'xs'}
        boxShadow={
          '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }
        onClick={() => goToPage(currentPage - 1)}
        isDisabled={currentPage === null || currentPage === 1}
      >
        <MdOutlineArrowBack />
      </Button>
      {longPages.map((page, i) =>
        typeof page === 'string' ? (
          <Button key={i} size={'xs'}
          boxShadow={
            '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }>{page}</Button>
        ) : (
          <Button
            ms={1}
            size={'xs'}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
            _hover={{ bg: 'secondary', color: 'white' }}
            bg={page === currentPage ? 'secondary' : 'white'}
            color={page === currentPage ? 'white' : 'secondary'}
            onClick={() => goToPage(page)}
            key={i}
          >
            {page}
          </Button>
        )
      )}
      <Button
        ms={1}
        size={'xs'}
        boxShadow={
          '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }
        onClick={() => goToPage(currentPage + 1)}
        isDisabled={currentPage === null || !(currentPage < numPages)}
      >
        <MdOutlineArrowForward />
      </Button>
    </Box>
  )
}

export default Paginator
