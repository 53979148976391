import { AreaIntervento, BaseAreaIntervento } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { InputField, transformErrorForForm } from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

const initialValues: BaseAreaIntervento = {
  nome: '',
  paesi_intervento: [],
}

interface AreaInterventoFormProps {
  area?: AreaIntervento
  save: (area: AreaIntervento) => Promise<void>
}

export default function AreaInterventoForm({
  area,
  save,
}: AreaInterventoFormProps) {
  const { t } = useTranslation()

  const AreaInterventoSchema = Yup.object().shape({
    nome: Yup.string().required().label(t('name')),
    paesi_intervento: Yup.array().label(t('countries')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('intervention_areas'),
        link: '/intervention-areas',
      },
      {
        label: t('new_intervention_area'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('intervention_areas'),
        link: '/intervention-areas',
      },
      {
        label: area?.nome ?? '',
        link: `/intervention-areas/${area?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, area])

  return (
    <Formik
      validationSchema={AreaInterventoSchema}
      onSubmit={(area, { setErrors, setSubmitting }) =>
        save({
          ...area,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(area ?? initialValues) as AreaIntervento}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={area ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <InputField
                      name="nome"
                      isRequired
                      label={t('name') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      name="paesi_intervento"
                      label={t('countries') ?? ''}
                      url={'/api/paesi-intervento/options/'}
                      value={values.paesi_intervento}
                      isMulti
                      keyQuery={'paesi-intervento-options'}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
