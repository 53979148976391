import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateCollaboratore } from '../../hooks/collaboratori'
import createStringLink from '../../utils'
import CollaboratoreForm from './CollaboratoreForm'

export default function CollaboratoriCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createCollaboratore = useCreateCollaboratore()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <CollaboratoreForm
        save={async (collaboratore) => {
          const savedCollaboratore = await createCollaboratore.mutateAsync(
            collaboratore
          )
          navigate(
            createStringLink({
              path: `/employees/${savedCollaboratore.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
