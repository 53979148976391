import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { Fragment, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import AutoCompleteField, {
  DateField,
  InputField,
  ReactSelectField,
  SelectChakraField,
} from '../../../components/form/fields'
import { useRipartizioniBudgetCollaborazione } from '../../../hooks/collaborazioni'
import { Progetto } from '../../../types'

function GridSpesaPersonaleForm({
  progetto,
}: {
  progetto: Progetto | undefined
}) {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const { data: ripartizioni } = useRipartizioniBudgetCollaborazione({
    collaborazione: values.collaborazione,
  })

  const optionsValute = useMemo(() => {
    const valuteLocali = progetto?.valute_locali_data || []
    const valute = valuteLocali.map((v) => ({
      value: v.id,
      label: v.codice,
    }))
    return valute
  }, [progetto])

  const numeroAnnualita = useMemo(() => {
    if (!progetto) return 1
    return progetto.num_annualita ?? 1
  }, [progetto])

  const sumImportiRipartizione = useMemo(() => {
    if (!ripartizioni) return 0
    const sommaImporti = ripartizioni.reduce((partialSum: number, a: any) => {
      return Number(partialSum) + Number(a.importo)
    }, 0)
    return sommaImporti
  }, [ripartizioni])

  const sumValuesRipartizione = useMemo(() => {
    if (!values.importi) return 0
    const tot = values.importi.reduce((partialSum: number, a: number) => {
      return Number(partialSum) + Number(a)
    }, 0)
    return tot
  }, [values])

  useEffect(() => {
    if (ripartizioni && ripartizioni.length > 0) {
      const importi = ripartizioni.map((r: any) => r.importo)
      setFieldValue('importi', importi)
    }
  }, [ripartizioni, setFieldValue])

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={6}>
      <GridItem colSpan={3}>
        <AutoCompleteField
          name="collaborazione"
          value={values.collaborazione}
          params={{
            progetto: progetto?.id,
          }}
          url={`/api/progetti/${progetto?.id}/options-collaborazioni/`}
          keyQuery="collaborazioni-options"
          label={t('collaboration') ?? ''}
          isRequired
        />
      </GridItem>
      <GridItem>
        <DateField name="data" label={t('date') ?? ''} isRequired />
      </GridItem>
      <GridItem colSpan={1}>
        <SelectChakraField name="report" label={t('report') ?? ''} isRequired>
          <option value=""></option>
          {[...Array(numeroAnnualita)].map((_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </SelectChakraField>
      </GridItem>
      <GridItem colSpan={1}>
        <AutoCompleteField
          name="modalita_pagamento"
          value={values.modalita_pagamento}
          url={`/api/modalita-pagamento/options/`}
          keyQuery="modalita-pagamento-options"
          label={t('payment_method') ?? ''}
          isRequired
        />
      </GridItem>
      <GridItem colSpan={1}>
        <AutoCompleteField
          name="conto_bancario"
          value={values.conto_bancario}
          url={`/api/progetti/${progetto?.id}/options-conti-bancari/`}
          keyQuery="conti-bancari-options"
          label={t('account') ?? ''}
          isRequired={values.modalita_pagamento}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <ReactSelectField
          name="valuta"
          label={t('currency') ?? ''}
          options={optionsValute ?? []}
          isRequired
          value={
            optionsValute.find((v) => v.value === values.valuta)
            
          }
        />
      </GridItem>
      {ripartizioni?.map((ripartizione, i) => (
        <Fragment key={ripartizione.id}>
          <GridItem>
            <InputField
              name={`ripartizione-${ripartizione.id}`}
              value={ripartizione.codice ?? ''}
              label={t('budget_line_ripartition') ?? ''}
              isReadOnly
            />
          </GridItem>
          <GridItem>
            <InputField
              name={`ripartizione-${ripartizione.id}`}
              value={ripartizione.importo ?? ''}
              label={t('amount_ripartition') ?? ''}
              isReadOnly
            />
          </GridItem>
          <GridItem colSpan={2}>
            <InputField
              name={`importi[${i}]`}
              label={t('amount') ?? ''}
              isRequired
              type={'number'}
            />
          </GridItem>
        </Fragment>
      ))}
      {sumImportiRipartizione !== sumValuesRipartizione && (
        <GridItem colSpan={4}>
          <Alert bg={'brandLight'}>
            <AlertIcon color={'brand'} />
            <AlertTitle fontSize={14}>{t('error_amounts_not_match')}</AlertTitle>
            <AlertDescription ms={3} fontSize={13}>
              <Box display={'flex'}>
                {t('total_amount_ripartition')}:{' '}
                <Text ms={3} fontWeight={500}>{sumImportiRipartizione}</Text>
              </Box>
              <Box display={'flex'}>
                {t('total_amount_importi')} 
                <Text ms={3} fontWeight={500}>{sumValuesRipartizione}</Text>
              </Box>
              <Box display={'flex'}>
                {t('difference_amounts')}{' '}
                <Text ms={3} fontWeight={500}>{
                    sumImportiRipartizione - sumValuesRipartizione
                }</Text>
              </Box>
            </AlertDescription>
          </Alert>
        </GridItem>
      )}
    </Grid>
  )
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  progetto: Progetto | undefined
  onConfirm: (values: any) => void
}

export default function ModalSpesaPersonale({
  isOpen,
  onClose,
  progetto,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    collaborazione: '',
    data: '',
    modalita_pagamento: '',
    conto_bancario: '',
    valuta: undefined,
    importi: null,
    report: '',
  }

  const SpesaPersonaleSchema = Yup.object().shape({
    collaborazione: Yup.string().required().label(t('collaboration')),
    data: Yup.date().required().label(t('date')),
    modalita_pagamento: Yup.string().required().label(t('payment_method')),
    conto_bancario: Yup.string()
      .label(t('account'))
      .when('modalita_pagamento', {
        is: (val: string) => val !== undefined && val !== null && val !== '',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    valuta: Yup.string().required().label(t('currency')),
    report: Yup.number().required().label(t('report')),
    importi: Yup.array() // array of numbers
      .of(Yup.number().required().label(t('amount')))
      .required()

      .label(t('amount')),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={SpesaPersonaleSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, resetForm }) => (
        <Modal
          isCentered
          size={'4xl'}
          isOpen={isOpen}
          onClose={() => {
            resetForm()
            onClose()
          }}
        >
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('create_spesa_personale') ?? ''}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <GridSpesaPersonaleForm progetto={progetto} />
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('create')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
