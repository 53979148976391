import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import MovimentiList from './MovimentiList'

export default function Movimenti() {
  return (
    <Routes>
      <Route index element={<MovimentiList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
