import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Progetto } from '../../types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import AutoCompleteField from '../../components/form/fields'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: { fornitore: number | undefined }) => void
  progetto?: Progetto | undefined
  onOpenFornitore: () => void
}

const initialValues = {
  fornitore: undefined,
}

export default function ModalSearchFornitore({
  progetto,
  isOpen,
  onClose,
  onConfirm,
  onOpenFornitore,
}: Props) {
  const { t } = useTranslation()

  const FornitoreSchema = Yup.object().shape({
    fornitore: Yup.string().required().label(t('supplier')),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      validationSchema={FornitoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('search_supplier_to_add')} ({progetto?.codice})
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'fornitore'}
                    label={t('supplier') ?? ''}
                    url="/api/fornitori/options/"
                    keyQuery="fornitori-options-search"
                    value={values.fornitore}
                    isRequired
                  />
                </Box>
                <Box mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                  <Box color={'red'} mb={2}>{t('if_you_dont_find_supplier_in_list')} </Box>
                  <Button
                    color={'brand'}
                    _hover={{
                      bg: 'brand',
                      color: 'white',
                    }}
                    bg={'brandLight'}
                    mr={3}
                    onClick={() => {
                      onOpenFornitore()
                      onClose()
                    }}
                  >
                    {t('add_supplier')}
                  </Button>
                </Box>
              </ModalBody>
              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.fornitore}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('confirm')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
