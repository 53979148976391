import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  InputGroup,
  Stack,
  Heading,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { recoverPassword } from '../../hooks/profile'
import { InputField, transformErrorForForm } from '../../components/form'
import { useState } from 'react'

const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required().label('Email'),
})

const initialValues = {
  email: '',
}

export default function RecuperaPassword() {
  const [resetSended, setResetSended] = useState(false)
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="orange" />
        <Heading color="orange">Recupera password</Heading>
        <Box minW={{ base: '90%', md: '468px' }}>
          <Formik
            onSubmit={(data, { setErrors }) =>
              recoverPassword(data.email).then(
                () => {
                  setResetSended(true)
                },
                (error) => {
                  setErrors(transformErrorForForm(error))
                }
              )
            }
            validationSchema={RecoverPasswordSchema}
            initialValues={initialValues}
          >
            {({ handleSubmit, errors, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Stack
                  spacing={4}
                  p="1rem"
                  backgroundColor="whiteAlpha.900"
                  boxShadow="md"
                >
                  <FormControl>
                    <InputGroup>
                      <InputField name="email" placeholder="Email" />
                    </InputGroup>
                  </FormControl>

                  {resetSended && (
                    <Box
                      bg="green.500"
                      p={3}
                      color="white"
                      width={'100%'}
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="sm"
                      textAlign="center"
                    >
                      Ti abbiamo inviato una mail con le istruzioni per <br />
                      recuperare la password
                    </Box>
                  )}
                  <Button
                    borderRadius={0}
                    type="submit"
                    variant="solid"
                    isDisabled={isSubmitting || !isValid}
                    colorScheme="orange"
                    width="full"
                  >
                    Recupera password
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  )
}
