import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useUpdateValuta, useValuta } from '../../hooks/valute'
import createStringLink from '../../utils'
import ValutaForm from './ValutaForm'

export default function ValuteEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useValuta(+id!)
  const editValuta = useUpdateValuta()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <ValutaForm
        valuta={data}
        save={async (valuta) => {
          const savedValuta = await editValuta.mutateAsync(valuta)
          navigate(
            createStringLink({
              path: `/currencies/${savedValuta.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
