import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Ente, PaginatedDJResponse } from '../types'
import { useDownloadFile } from './download'

export function useEnti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Ente>>(
    ['enti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/enti/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteEnte() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/enti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['enti'])
        client.invalidateQueries(['ente'])
      },
    }
  )
}

export function useCreateEnte() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (ente: Ente) =>
        axios
          .post(`/api/enti/`, ente, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Ente)
    ),
    {
      onSuccess(savedEnte) {
        client.invalidateQueries(['enti'])
        client.setQueryData(['ente', savedEnte.id], savedEnte)
      },
    }
  )
}

export function useUpdateEnte() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (ente: Ente) =>
        axios
          .put(`/api/enti/${ente.id}/`, ente, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Ente)
    ),
    {
      onSuccess(savedEnte) {
        client.invalidateQueries(['enti'])
        client.setQueryData(['ente', savedEnte.id], savedEnte)
      },
    }
  )
}

export function useEnte(id: number) {
  return useQuery<Ente>(
    ['ente', id],
    useAuthCallPromise(
      (token: string) => () =>
        axios
          .get(`/api/enti/${id}/`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Ente)
    )
  )
}

export function useEntiDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/enti/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

