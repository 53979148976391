import { useAuthUser } from 'use-eazy-auth'
import { AccountUser } from './types'

export function useCanGestioneAnagrafiche() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.gestione_anagrafiche) {
    return true
  }

  return false
}

export function useCanGestioneUtenti() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.gestione_utenti) {
    return true
  }

  return false
}

export function useCanGestioneFornitori() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.gestione_fornitori) {
    return true
  }

  return false
}

export function useCanGestioneBeni() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.gestione_beni) {
    return true
  }

  return false
}

export function useCanGestioneCambi() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.inserimento_modifica_cambi) {
    return true
  }

  return false
}

export function useCanCreateProgetti() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.creazione_progetti) {
    return true
  }

  return false
}

export function useCanCreateEditBudget() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.inserimento_modifica_budget) {
    return true
  }

  return false
}

export function useCanGestioneConti() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.gestione_conti) {
    return true
  }

  return false
}

export function useCanInsertMovimenti(progetto: number) {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (
    user.is_staff ||
    user.permessi?.inserimento_movimenti ||
    (user.permessi?.progetti && user.permessi?.progetti.includes(progetto))
  ) {
    return true
  }

  return false
}

export function useCanInsertMultipleMovimenti() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.inserimento_multiplo_movimenti) {
    return true
  }

  return false
}

export function useCanEditMultipleMovimenti() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.modifica_multipla_movimenti) {
    return true
  }

  return false
}

export function useCanEditMovimenti(progetto: number) {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (
    user.is_staff ||
    user.permessi?.modifica_movimenti ||
    (user.permessi?.progetti && user.permessi?.progetti.includes(progetto)) ||
    (user.permessi?.paesi_intervento)
  ) {
    return true
  }

  return false
}

export function useCanCausaleSpesePersonale() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || user.permessi?.causale_spese_personale) {
    return true
  }

  return false
}

export function useCanInsertFornitori() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || (user.permessi?.inserimento_fornitori)) {
    return true
  }

  return false
}

export function useCanInsertBeni() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || (user.permessi?.inserimento_beni)) {
    return true
  }

  return false
}

export function useCanInsertCollaboratori() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || (user.permessi?.inserimento_collaboratori)) {
    return true
  }

  return false
}

export function useCanInsertConti() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || (user.permessi?.inserimento_conti)) {
    return true
  }

  return false
}

export function useCanImportSpeseLoco() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || (user.permessi?.importazione_spese_loco)) {
    return true
  }

  return false
}

export function useCanGestioneCollaborazioni() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return false
  }

  if (user.is_staff || (user.permessi?.gestione_collaborazioni)) {
    return true
  }

  return false
}

export function useHomeLinks() {
  const { user } = useAuthUser<AccountUser>()

  if (!user) {
    return []
  }

  if (user.is_staff) {
    return [
      { path: '/projects', label: 'projects' },
      { path: '/employees', label: 'employees' },
    ]
  }

  return [
    { path: '/projects', label: 'projects' },
    { path: '/movements', label: 'movements' },
  ]
}
