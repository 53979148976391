import { BaseFornitore, Fornitore } from '../../types'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  InputField,
  InputFileField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

export function FornitoreGridForm({
  isModalForm = false,
  showAllegati,
  setShowAllegati,
}: {
  showAllegati?: number
  setShowAllegati?: (value: number) => void
  isModalForm?: boolean
}) {
  const { t } = useTranslation()

  const { values } = useFormikContext<any>()

  return (
    <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem>
        <InputField
          isRequired
          name="ragione_sociale"
          label={t('business_name') ?? ''}
        />
      </GridItem>
      <GridItem>
        <InputField name="partita_iva" isRequired label={t('vat_number') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField
          name="codice_fiscale"
          isRequired
          label={t('tax_id_code') ?? ''}
        />
      </GridItem>
      <GridItem>
        <InputField name="mail" type={'email'} label={t('email') ?? ''} />
      </GridItem>
      <GridItem>
        <AutoCompleteField
          name="paese_intervento"
          label={t('country') ?? ''}
          url={'/api/paesi-intervento/options/'}
          value={values.paese_intervento}
          keyQuery={'paesi-intervento-options'}
          isRequired
        />
      </GridItem>
      <GridItem>
        <InputField name="telefono" label={t('phone') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField isRequired name="indirizzo" label={t('address') ?? ''} />
      </GridItem>
      <GridItem colSpan={2}>
        <TextareaField name="note" label={t('notes') ?? ''} rows={6} />
      </GridItem>
      {!isModalForm && setShowAllegati && showAllegati !== undefined && (
        <GridItem>
          <Button
            onClick={() => {
              setShowAllegati(showAllegati + 1)
            }}
            isDisabled={showAllegati === 5}
          >
            {t('add_file_attachment')}
          </Button>
          {Array.from(Array(showAllegati)).map((item, index) => (
            <GridItem mt={2} colSpan={4} key={index}>
              <InputFileField
                name={index === 0 ? 'allegato' : `allegato_${index + 1}`}
                label={t('attachment') + ' ' + (index + 1) ?? ''}
              />
            </GridItem>
          ))}
        </GridItem>
      )}
    </Grid>
  )
}

const initialValues: BaseFornitore = {
  ragione_sociale: '',
  partita_iva: '',
  codice_fiscale: '',
  indirizzo: '',
  mail: '',
  note: '',
  telefono: '',
  allegato: null,
  allegato_2: null,
  allegato_3: null,
  allegato_4: null,
  allegato_5: null,
  paese_intervento: undefined,
}

interface FornitoreFormProps {
  fornitore?: Fornitore
  save: (fornitore: Fornitore) => Promise<void>
}

export default function FornitoreForm({ fornitore, save }: FornitoreFormProps) {
  const { t } = useTranslation()

  const FornitoreSchema = Yup.object().shape({
    ragione_sociale: Yup.string().required().label(t('business_name')),
    codice_fiscale: Yup.string().required().label(t('tax_id_code')),
    paese_intervento: Yup.number().required().label(t('country')),
    indirizzo: Yup.string().required().label(t('address')),
    partita_iva: Yup.string().required().label(t('vat_number')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('suppliers'),
        link: '/suppliers',
      },
      {
        label: t('new_supplier'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('suppliers'),
        link: '/suppliers',
      },
      {
        label: fornitore?.ragione_sociale ?? '',
        link: `/suppliers/${fornitore?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, fornitore])

  const [showAllegati, setShowAllegati] = useState<number>(0)

  useEffect(() => {
    if (fornitore) {
      setShowAllegati(fornitore.numero_allegati ?? 0)
    }
  }, [fornitore])

  return (
    <Formik
      validationSchema={FornitoreSchema}
      onSubmit={(fornitore, { setErrors, setSubmitting }) =>
        save({
          ...fornitore,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(fornitore ?? initialValues) as Fornitore}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    fornitore ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <FornitoreGridForm
                  setShowAllegati={setShowAllegati}
                  showAllegati={showAllegati}
                  isModalForm={false}
                />
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
