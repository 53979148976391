import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useMemo } from 'react'
import {
  BaseFinanziamento,
  Finanziamento,
} from '../../../types'
import { useParams } from 'react-router'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import {
  InputField,
  TextareaField,
  transformErrorForForm,
} from '../../../components/form'
import BreadCrumb from '../../../components/BreadCrumb'
import { useProgettoSimple } from '../../../hooks/progetti'
import AutoCompleteField from '../../../components/form/fields'

export const FinanziamentoGridForm = ({
  finanziamento,
}: {
  finanziamento?: Finanziamento
}) => {
  const { t } = useTranslation()

  const { values } = useFormikContext<BaseFinanziamento>()

  return (
    <>
      <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem colSpan={2}>
          <AutoCompleteField
            isRequired
            name="ente"
            label={t('entity') ?? ''}
            url={'/api/enti/options/'}
            keyQuery={'enti-options'}
            value={values.ente}
          />
        </GridItem>
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem>
          <InputField name="importo" label={t('amount') ?? ''} />
        </GridItem>
        <GridItem>
          <InputField name="annualita" label={t('annuity') ?? ''} />
        </GridItem>
      </Grid>
      <Grid mt={3} minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem colSpan={2}>
          <TextareaField name="descrizione" label={t('description') ?? ''} />
        </GridItem>
      </Grid>
    </>
  )
}

interface FinanziamentoFormProps {
  finanziamento?: Finanziamento
  save: (finanziamento: Finanziamento) => Promise<void>
}

export default function FinanziamentoForm({
  finanziamento,
  save,
}: FinanziamentoFormProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const initialValues: BaseFinanziamento = {
    ente: undefined,
    descrizione: '',
    importo: 0,
    annualita: 1,
    progetto: Number(id!),
  }

  const { data: progetto } = useProgettoSimple(Number(id!))

  const FinanziamentoSchema = Yup.object().shape({
    ente: Yup.number().required().label(t('entity')),
    importo: Yup.number().required().label(t('amount')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('financing'),
        link: `/projects/${id}/financing`,
      },
      {
        label: t('new_financing'),
      },
    ]
  }, [t, progetto, id])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('financing'),
        link: `/projects/${id}/financing`,
      },
      {
        label: finanziamento?.ente_data?.nome ?? '',
        link: `/projects/${id}/collaborations/${finanziamento?.id}`,
      },
      {
        label: t('edit_financing'),
      },
    ]
  }, [
    t,
    progetto?.codice,
    id,
    finanziamento?.ente_data?.nome,
    finanziamento?.id,
  ])

  return (
    <Formik
      validationSchema={FinanziamentoSchema}
      onSubmit={(finanziamento, { setErrors, setSubmitting }) =>
        save({
          ...finanziamento,
          progetto: Number(id!),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(finanziamento ?? initialValues) as Finanziamento}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    finanziamento ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2} className={'card-scroll-height'} overflow={'auto'}>
              <CardBody>
                <FinanziamentoGridForm finanziamento={finanziamento} />
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
