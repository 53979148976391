import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Link,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { InputFileField } from '../../../components/form'
import { Progetto } from '../../../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto: Progetto | undefined
  errors?: string | null
}

export default function ImportBudgetsModal({
  progetto,
  isOpen,
  onClose,
  onConfirm,
  errors,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    import_file: null,
  }

  const AssociaFornitoreSchema = Yup.object().shape({
    import_file: Yup.string().required(),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={AssociaFornitoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, isSubmitting }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('import_budgets')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box width={'100%'} mt={2} mb={4}>
                  <Button
                    bg={'brandBlue'}
                    _hover={{
                      opacity: 0.8,
                    }}
                    size={'sm'}
                    color={'white'}
                    as={Link}
                    href={'/budgets-template.xlsx'}
                  >
                    {t('download_template')}{' '}
                  </Button>
                </Box>
                <Box>
                  <InputFileField
                    name={'import_file'}
                    label={t('import_file') ?? ''}
                    accept={'.xlsx'}
                    isRequired
                  />
                </Box>
                {errors && (
                  <Box
                    p={3}
                    color={'white'}
                    overflow={'auto'}
                    height={'300'}
                    mt={4}
                    bg={'red.500'}
                  >
                    {errors}
                  </Box>
                )}
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.import_file || isSubmitting}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('importa')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
