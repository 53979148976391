import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import TipiRetribuzioneCreate from './TipiRetribuzioneCreate'
import TipiRetribuzioneEdit from './TipiRetribuzioneEdit'
import TipiRetribuzioneList from './TipiRetribuzioneList'
import TipoRetribuzioneDetail from './TipoRetribuzioneDetail'

export default function TipiRetribuzione() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<TipiRetribuzioneList />} />
      <Route path=":id" element={<TipoRetribuzioneDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <TipiRetribuzioneEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <TipiRetribuzioneCreate />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
