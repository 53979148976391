import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import { useCanGestioneAnagrafiche } from '../../permissions'
import DutyStationDetail from './DutyStationDetail'
import DutyStationCreate from './DutyStationCreate'
import DutyStationEdit from './DutyStationEdit'
import DutyStationsList from './DutyStationsList'

export default function DutyStations() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<DutyStationsList />} />
      {userCanGestioneAnagrafiche && (
        <Route path={'/create'} element={<DutyStationCreate />} />
      )}
      <Route path={'/:id'} element={<DutyStationDetail />} />
      {userCanGestioneAnagrafiche && (
        <Route path={'/:id/edit'} element={<DutyStationEdit />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
