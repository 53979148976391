import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { AccountUser, BaseAccountUser, PaginatedDJResponse } from '../types'
import { QueryOnlyOptions } from './utils'

export function useAccountsUsers(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<AccountUser>>(
    ['users', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/users/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAccountUser(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<AccountUser>(
    ['user', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/users/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteAccountUser() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/users/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['users'])
        client.invalidateQueries(['user'])
      },
    }
  )
}

export function useAssociaProgettoAUtente() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ utente, progetto }) =>
          axios.post(
            `/api/users/${utente}/associa-progetto/`,
            {
              progetto,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
    ),
    {
      onSuccess() {
        client.invalidateQueries(['permessi-utente-progetto'])
      },
    }
  )
}

export function useCreateAccountUser() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (user: BaseAccountUser) =>
        axios
          .post(`/api/users/`, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as BaseAccountUser)
    ),
    {
      onSuccess(savedUser) {
        client.invalidateQueries(['users'])
        client.setQueryData(['user', savedUser.id], savedUser)
      },
    }
  )
}

export function useUpdateAccountUser() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (user: BaseAccountUser) =>
        axios
          .put(`/api/users/${user.id}/`, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as BaseAccountUser)
    ),
    {
      onSuccess(savedUser) {
        client.invalidateQueries(['users'])
        client.setQueryData(['user', savedUser.id], savedUser)
      },
    }
  )
}

export function useReSendUserActivationLink() {
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios
          .post(
            `/api/users/${id}/resend_activation_email/`,
            {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as void)
    )
  )
}
