import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanInsertFornitori } from '../../permissions'
import FornitoreDetail from './FornitoreDetail'
import FornitoriCreate from './FornitoriCreate'
import FornitoriEdit from './FornitoriEdit'
import FornitoriList from './FornitoriList'

export default function Fornitori() {
  const userCanInsertFornitori = useCanInsertFornitori()
  return (
    <Routes>
      <Route index element={<FornitoriList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanInsertFornitori}>
            <FornitoriCreate />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<FornitoreDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanInsertFornitori}>
            <FornitoriEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
