import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
  } from '@chakra-ui/react'
  import { Formik } from 'formik'
  import { useTranslation } from 'react-i18next'
  import * as Yup from 'yup'
  import { InputFileField } from '../../components/form'
  
  interface Props {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    onToggle: () => void
    onConfirm: (value: any) => void
    errors?: string[] | null
    isLoading?: boolean
  }
  
  export default function ModalImportContiBancari({
    isOpen,
    onClose,
    onConfirm,
    errors,
    isLoading,
  }: Props) {
    const { t } = useTranslation()
  
    const initialValues = {
      import_file: null,
    }
  
    const ImportContoBancarioSchema = Yup.object().shape({
      import_file: Yup.string().required(),
    })
  
    return (
      <Formik
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
          setSubmitting(true)
          onConfirm(values)
          setSubmitting(false)
        }}
        validationSchema={ImportContoBancarioSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{t('import')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    <InputFileField
                      name={'import_file'}
                      label={t('import_file') ?? ''}
                      accept={'.xlsx'}
                      isRequired
                    />
                  </Box>
                  {errors && (
                    <Box
                      p={3}
                      color={'white'}
                      overflow={'auto'}
                      height={'400px'}
                      overflowY={'scroll'}
                      mt={4}
                      bg={'red.400'}
                    >
                      {errors.map((e) => (
                        <Box>{e}</Box>
                      ))}
                    </Box>
                  )}
                </ModalBody>
  
                <ModalFooter display={'flex'} justifyContent={'space-between'}>
                  <Button
                    color={'brand'}
                    _hover={{
                      bg: 'brand',
                      color: 'white',
                    }}
                    bg={'brandLight'}
                    mr={3}
                    onClick={onClose}
                  >
                    {t('close')}
                  </Button>
                  <Button
                    type="submit"
                    bg={'brand'}
                    isDisabled={!values.import_file || isSubmitting || isLoading}
                    _hover={{
                      bg: 'brandSecondary',
                    }}
                    color={'white'}
                  >
                    {isLoading && (
                      <Spinner
                        thickness="4px"
                        emptyColor="gray.200"
                        color="brand"
                        size="sm"
                        mr={2}
                      />
                    )}
                    {t('import')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }
  