import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useMemo, useState } from 'react'
import { BaseBudget, Budget } from '../../../types'
import { useParams } from 'react-router'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import { InputField, transformErrorForForm } from '../../../components/form'
import BreadCrumb from '../../../components/BreadCrumb'
import { useProgettoSimple } from '../../../hooks/progetti'
import AutoCompleteField from '../../../components/form/fields'

const initialValues: BaseBudget = {
  codice: '',
  descrizione: '',
  annualita: undefined,
  importo: undefined,
  codice_paese: '',
  codice_piano_conti: '',
  codice_order: '',
  voce_spesa: null,
  importo_aggiornato: undefined,
}

interface BudgetFormProps {
  budget?: Budget
  save: (budget: Budget) => Promise<void>
}

export default function BudgetForm({ budget, save }: BudgetFormProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const [errorsServer, setErrorsServer] = useState<string | null>(null)
  const BudgetSchema = Yup.object().shape({
    codice: Yup.string().required().label(t('budget_line')),
    descrizione: Yup.string().required().label(t('description')),
    annualita: Yup.number().required().label(t('annuity')),
    importo: Yup.number().required().label(t('amount')),
  })

  const { data: progetto } = useProgettoSimple(Number(id!))

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('budgets'),
        link: `/projects/${id}/budgets`,
      },
      {
        label: t('new_budget'),
      },
    ]
  }, [t, progetto, id])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('budgets'),
        link: `/projects/${id}/budgets`,
      },
      {
        label: budget?.codice ?? '',
        link: `/projects/${id}/budgets/${budget?.id}`,
      },
      {
        label: t('edit_budget'),
      },
    ]
  }, [budget?.codice, budget?.id, id, progetto?.codice, t])

  const valutaCode = useMemo(() => {
    return progetto?.valuta_rendicontazione_data?.codice
  }, [progetto])

  return (
    <Formik
      validationSchema={BudgetSchema}
      onSubmit={(budget, { setErrors, setSubmitting }) =>
        save({
          ...budget,
          progetto: Number(id!),
        }).catch((err) => {
          setErrorsServer(err.response.data.error)
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(budget ?? initialValues) as Budget}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <>
          {errorsServer && (
            <Box
              p={3}
              color={'white'}
              overflow={'auto'}
              height={'300'}
              mt={4}
              bg={'red.500'}
            >
              {errorsServer}
            </Box>
          )}
          <form onSubmit={handleSubmit}>
            <Flex direction={'column'}>
              <HeaderActionsPage
                sticky
                breadCrumbs={
                  <BreadCrumb
                    items={budget ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                  />
                }
                blockRight={
                  <Box display={'flex'} alignItems={'center'}>
                    <Button
                      size={'sm'}
                      color={'white'}
                      disabled={isSubmitting || !isValid}
                      type={'submit'}
                      _hover={{ bg: 'brand' }}
                      bg={'brandSecondary'}
                    >
                      {t('save')}
                    </Button>
                  </Box>
                }
              />
              <Card mt={2} minHeight={500}>
                <CardBody>
                  <Grid
                    minHeight="100%"
                    templateColumns="repeat(3, 1fr)"
                    gap={4}
                  >
                    <GridItem>
                      <InputField
                        name="codice"
                        label={t('budget_line') ?? ''}
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <InputField
                        name="descrizione"
                        label={t('description') ?? ''}
                        isRequired
                      />
                    </GridItem>
                  </Grid>
                  <Grid
                    mt={3}
                    minHeight="100%"
                    templateColumns="repeat(3, 1fr)"
                    gap={4}
                  >
                    <GridItem>
                      <InputField
                        name="importo"
                        label={t('import') + ' [' + valutaCode + ']' ?? ''}
                        isRequired
                      />
                    </GridItem>
                    <GridItem>
                      <InputField
                        name="annualita"
                        label={t('annuity') ?? ''}
                        isRequired
                      />
                    </GridItem>
                    <GridItem>
                      <AutoCompleteField
                        name="voce_spesa"
                        label={t('expense_item') ?? ''}
                        value={values.voce_spesa}
                        url={'/api/voci-spesa/options/'}
                        keyQuery={'voci-spesa-options'}
                      />
                    </GridItem>
                  </Grid>
                  <Grid
                    mt={3}
                    minHeight="100%"
                    templateColumns="repeat(3, 1fr)"
                    gap={4}
                  >
                    <GridItem>
                      <InputField
                        name="codice_paese"
                        label={t('country_code') ?? ''}
                      />
                    </GridItem>
                    <GridItem>
                      <InputField
                        name="codice_piano_conti"
                        label={t('account_plan_code') ?? ''}
                      />
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </Flex>
          </form>
        </>
      )}
    </Formik>
  )
}
