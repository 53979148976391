import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateEnte } from '../../hooks/enti'
import createStringLink from '../../utils'
import EnteForm from './EnteForm'

export default function EntiCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createEnte = useCreateEnte()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <EnteForm
        save={async (ente) => {
          const savedEnte = await createEnte.mutateAsync(ente)
          navigate(
            createStringLink({
              path: `/entities/${savedEnte.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
