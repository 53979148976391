import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { BaseReferente, Referente } from '../../../types'
import { useParams } from 'react-router'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import {
  DateField,
  transformErrorForForm,
} from '../../../components/form'
import BreadCrumb from '../../../components/BreadCrumb'
import { useProgettoSimple } from '../../../hooks/progetti'
import AutoCompleteField from '../../../components/form/fields'

const initialValues: BaseReferente = {
  data_fine: '',
  data_inizio: '',
  progetto: null,
  referente_generico: null,
}

interface ReferenteFormProps {
  referente?: Referente
  save: (referente: Referente) => Promise<void>
}

export default function ReferenteForm({ referente, save }: ReferenteFormProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const ReferenteSchema = Yup.object().shape({
    data_fine: Yup.string().required().label(t('date_end')),
    data_inizio: Yup.string().required().label(t('date_start')),
    referente_generico: Yup.number().required().label(t('generic_contact')),
  })

  const { data: progetto } = useProgettoSimple(Number(id!))

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('contacts'),
        link: `/projects/${id}/contacts`,
      },
      {
        label: t('new_contact'),
      },
    ]
  }, [t, progetto, id])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('contacts'),
        link: `/projects/${id}/contacts`,
      },
      {
        label: referente?.referente_generico_data.nome ?? '',
        link: `/projects/${id}/contacts/${referente?.id}`,
      },
      {
        label: t('edit_collaboration'),
      },
    ]
  }, [t, progetto, id, referente])

  return (
    <Formik
      validationSchema={ReferenteSchema}
      onSubmit={(referente, { setErrors, setSubmitting }) =>
        save({
          ...referente,
          progetto: Number(id!),
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(referente ?? initialValues) as Referente}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    referente ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2} minHeight={500}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem>
                    <AutoCompleteField
                      isRequired
                      name={'referente_generico'}
                      label={t('generic_contact') ?? ''}
                      url={'/api/referenti-generici/options/'}
                      value={values.referente_generico}
                      keyQuery={'referenti-generici-options'}
                    />
                  </GridItem>
                  <GridItem>
                    <DateField
                      isRequired
                      name={'data_inizio'}
                      label={t('date_start') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <DateField
                      isRequired
                      name={'data_fine'}
                      label={t('date_end') ?? ''}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
