import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useReferente, useUpdateReferente } from '../../../hooks/referenti'
import createStringLink from '../../../utils'
import ReferenteForm from './ReferenteForm'

export default function ReferenteEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id, referente } = useParams()
  const { data } = useReferente(+referente!)
  const editReferente = useUpdateReferente()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <ReferenteForm
        referente={data}
        save={async (referente) => {
          const savedReferente = await editReferente.mutateAsync(referente)
          navigate(
            createStringLink({
              path: `/projects/${id}/contacts/${savedReferente.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
