import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import BreadCrumb from '../../components/BreadCrumb'
import ModalChangePassword from '../../components/ModalChangePassword'
import { Formik } from 'formik'
import { SelectChakraField } from '../../components/form'
import { useAuthUser } from 'use-eazy-auth'
import { useChangeLanguage } from '../../hooks/profile'
import { useNavigate } from 'react-router'

export default function Profilo() {
  const { t, i18n } = useTranslation()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const { user } = useAuthUser()
  const selectedLanguage = useMemo(() => {
    return user?.selected_language ?? 'en'
  }, [user])
  const breadCrumbItems = useMemo(() => {
    return [
      {
        label: t('profile'),
      },
    ]
  }, [t])

  const changeLanguage = useChangeLanguage()
  const navigate = useNavigate()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Box>
        <Flex direction={'column'}>
          <HeaderActionsPage
            sticky
            breadCrumbs={<BreadCrumb items={breadCrumbItems} />}
          />
          <Card>
            <CardBody>
              <Grid templateColumns={'repeat(4, 1fr)'} gap={6}>
                <GridItem>
                  <Box>{t('change_password')}</Box>
                  <Button
                    mt={4}
                    size={'sm'}
                    color={'white'}
                    onClick={onOpen}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('change_password')}
                  </Button>
                </GridItem>
                <GridItem>
                  <Box>{t('change_language')}</Box>
                  <Formik
                    initialValues={{
                      selected_language: selectedLanguage,
                    }}
                    onSubmit={(values) => {
                      changeLanguage({
                        selected_language: values.selected_language,
                      }).then(() => {
                        i18n.changeLanguage(values.selected_language)
                        navigate(
                          `/${values.selected_language}/profile`,
                        )
                      })
                    }}
                  >
                    {({ handleSubmit, values, setFieldValue }) => (
                      <form onSubmit={handleSubmit}>
                        <SelectChakraField
                          name={'selected_language'}
                          value={values.selected_language}
                          onChange={(e) => {
                            setFieldValue('selected_language', e.target.value)
                            handleSubmit()
                          }}
                        >
                          <option value={'en'}>{t('english')}</option>
                          <option value={'it'}>{t('italian')}</option>
                          <option value={'fr'}>{t('french')}</option>
                          <option value={'es'}>{t('spanish')}</option>
                          <option value={'pt'}>{t('portoguese')}</option>
                        </SelectChakraField>
                      </form>
                    )}
                  </Formik>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        </Flex>
      </Box>
      <ModalChangePassword
        isOpen={isOpen}
        onConfirm={onClose}
        title={t('change_password')}
        onClose={onClose}
        onOpen={onOpen}
        onToggle={onToggle}
      />
    </Box>
  )
}
