import { Box, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCreateMultipleMovimenti } from '../../../hooks/movimenti'
import createStringLink from '../../../utils'
import ConnectedUsers from '../../../components/ConnectedUsers'
import MovimentoMultipleForm from './MovimentiMultipleForm'

export default function MovimentoCreateMultiple() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const createMovimentiMultipli = useCreateMultipleMovimenti()

  const toast = useToast()

  const { t } = useTranslation()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <MovimentoMultipleForm
        save={async (movimenti) => {
          await createMovimentiMultipli.mutateAsync(
            movimenti
          )
          toast({
            title: t('create_multiple'),
            description:
              t('you_have_created') +
              ' ' +
              movimenti.movimenti.length +
              ' ' +
              t('movements'),
            status: 'success',
            duration: 10000,
            isClosable: true,
          })
          navigate(
            createStringLink({
              path: `/projects/${id}/movements/`,
              lang: i18n.language,
            })
          )
        }}
      />
      {/* <ConnectedUsers topic={'movimenti-edit-' + id} /> */}
    </Box>
  )
}
