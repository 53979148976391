import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneCambi } from '../../permissions'
import TipiCambioCreate from './TipiCambioCreate'
import TipiCambioEdit from './TipiCambioEdit'
import TipiCambioList from './TipiCambioList'
import TipoCambioDetail from './TipoCambioDetail'

export default function TipiRetribuzione() {
  const userCanGestioneCambi = useCanGestioneCambi()
  return (
    <Routes>
      <Route index element={<TipiCambioList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneCambi}>
            <TipiCambioCreate />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<TipoCambioDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneCambi}>
            <TipiCambioEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
