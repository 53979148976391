import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface ModalDeleteProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  nameItem: string | undefined
  onConfirm: () => void
}

export default function ModalDelete({
  title,
  isOpen,
  onClose,
  nameItem,
  onConfirm,
}: ModalDeleteProps) {
  const { t } = useTranslation()
  return (
    <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            {t('are_you_sure_you_want_to_delete')} {nameItem}?
          </Text>
        </ModalBody>

        <ModalFooter display={'flex'} justifyContent={'space-between'}>
          <Button
            size={'sm'}
            color={'brand'}
            _hover={{
              bg: 'brand',
              color: 'white',
            }}
            bg={'brandLight'}
            mr={3}
            onClick={onClose}
          >
            {t('close')}
          </Button>
          <Button
            size={'sm'}
            _hover={{
              bg: 'error',
              color: 'white',
              opacity: 0.8,
            }}
            onClick={onConfirm}
            bg={'error'}
            color={'white'}
          >
            {t('delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
