import { Box, Card, CardBody, Flex } from '@chakra-ui/react'

interface HeaderActionsPageProps {
  title?: string | null
  blockRight?: React.ReactNode
  sticky?: boolean
  breadCrumbs?: React.ReactNode
}

export default function HeaderActionsPage({
  title = null,
  blockRight,
  sticky,
  breadCrumbs,
}: HeaderActionsPageProps) {
  return (
    <Card
      height={'48px'}
      mb={2}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      position={sticky ? 'sticky' : 'relative'}
    >
      <CardBody
        px={8}
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        py={2}
        boxShadow={
          '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }
      >
        <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>{title ? title : breadCrumbs}</Box>
          {blockRight}
        </Flex>
      </CardBody>
    </Card>
  )
}
