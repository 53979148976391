import { BaseVoceSpesa, VoceSpesa } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { InputField, transformErrorForForm } from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

const initialValues: BaseVoceSpesa = {
  codice: '',
  nome: '',
}

interface VoceSpesaFormProps {
  voce?: VoceSpesa
  save: (voce: VoceSpesa) => Promise<void>
}

export default function VoceSpesaForm({ voce, save }: VoceSpesaFormProps) {
  const { t } = useTranslation()

  const VoceSpesachema = Yup.object().shape({
    codice: Yup.string().required().label(t('code')),
    nome: Yup.string().required().label(t('name')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('expense_items'),
        link: '/expense-items',
      },
      {
        label: t('new_expense_item'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('expense_items'),
        link: '/expense-items',
      },
      {
        label: voce?.nome ?? '',
        link: `/expense-items/${voce?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, voce])

  return (
    <Formik
      validationSchema={VoceSpesachema}
      onSubmit={(voce, { setErrors, setSubmitting }) =>
        save({
          ...voce,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(voce ?? initialValues) as VoceSpesa}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={voce ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <InputField isRequired name="codice" label={t('code') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField isRequired name="nome" label={t('name') ?? ''} />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
