import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import createStringLink from '../../utils'
import DutyStationForm from './DutyStationForm'
import { useDutyStation, useUpdateDutyStation } from '../../hooks/duty-stations'

export default function DutyStationEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useDutyStation(+id!)
  const editDutyStation = useUpdateDutyStation()
  

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <DutyStationForm
        duty={data}
        save={async (duty) => {
          const savedDuty = await editDutyStation.mutateAsync(duty)
          navigate(
            createStringLink({
              path: `/duty-stations/${savedDuty.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
