import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCheck, BiX } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import ModalDelete from '../../components/ModalDelete'
import { useAssociazioniBene, useBene, useDeleteBene } from '../../hooks/beni'
import { useAssociaBeneProgetto } from '../../hooks/progetti'
import { useCanInsertBeni } from '../../permissions'
import createStringLink from '../../utils'
import AssociazioniBeneTable from './AssociazioniBeneTable'
import { useMovimenti } from '../../hooks/movimenti'
import MovimentiTable from '../Movimenti/MovimentiTable'
import ModalAssociaProgettoBene from './ModalAssociaProgettoBene'

export default function BeneDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const { data } = useBene(Number(id!))
  const deleteBene = useDeleteBene()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const {
    isOpen: isOpenAssociate,
    onClose: onCloseAssociate,
    onOpen: onOpenAssociate,
    onToggle: onToggleAssociate,
  } = useDisclosure()
  const userCanGestioneBeni = useCanInsertBeni()
  const associaBeneProgetto = useAssociaBeneProgetto()
  const { data: associazioni } = useAssociazioniBene(Number(id!))

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('goods'),
        link: '/goods',
      },
      {
        label: data?.descrizione ?? '',
      },
    ]
  }, [t, data])

  const filtersForMovimentiAcquisto = useMemo(() => {
    return {
      bene: id,
      causale: 'acquisto-cespite',
    }
  }, [id])

  const filtersForMovimentiVendita = useMemo(() => {
    return {
      bene: id,
      causale: 'vendita-cespite',
    }
  }, [id])

  const { data: movimentiAcquisto } = useMovimenti(filtersForMovimentiAcquisto)
  const { data: movimentiVendita } = useMovimenti(filtersForMovimentiVendita)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneBeni && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    border={'1px solid'}
                    borderColor={'brandBlue'}
                    color={'brandBlue'}
                    bg={'card'}
                    _hover={{
                      bg: 'brandBlue',
                      color: 'white',
                    }}
                    onClick={onOpenAssociate}
                  >
                    {data?.progetto
                      ? t('dissociate_or_change_project')
                      : t('associate_project')}
                  </Button>
                  <Button
                    size={'sm'}
                    me={4}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/goods/${id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('description')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('project')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.progetto_data?.titolo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('place_of_use')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.luogo_utilizzo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('progressive')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.progressivo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('serial')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.seriale}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('not_in_use')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.non_in_uso ? (
                    <BiCheck size={20} color="green" />
                  ) : (
                    <BiX size={20} color="red" />
                  )}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('not_of_property')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.non_di_proprieta ? (
                    <BiCheck size={20} color="green" />
                  ) : (
                    <BiX size={20} color="red" />
                  )}
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(data?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(data?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            {t('movements_acquisition')}
          </CardHeader>
          <CardBody>
            <MovimentiTable sticky={false} data={movimentiAcquisto} />
          </CardBody>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            {t('associations')}
          </CardHeader>
          <CardBody>
            <AssociazioniBeneTable associazioni={associazioni} />
          </CardBody>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            {t('movements_sale')}
          </CardHeader>
          <CardBody>
            <MovimentiTable sticky={false} data={movimentiVendita} />
          </CardBody>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteBene.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/goods`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.descrizione}
        title={t('delete_good')}
      />
      {isOpenAssociate && (
        <ModalAssociaProgettoBene
          isOpen={isOpenAssociate}
          onToggle={onToggleAssociate}
          onClose={onCloseAssociate}
          progetto={data?.progetto}
          onConfirm={async (values: any) => {
            await associaBeneProgetto.mutateAsync({
              progetto: values.progetto,
              bene: id,
              data_associazione: values.data_associazione,
              importo_residuo: values.importo_residuo,
            })

            onCloseAssociate()
          }}
          onOpen={onOpenAssociate}
        />
      )}
    </Box>
  )
}
