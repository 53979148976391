import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import ValutaDetail from './ValutaDetail'
import ValuteCreate from './ValuteCreate'
import ValuteEdit from './ValuteEdit'
import ValuteList from './ValuteList'

export default function Valute() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<ValuteList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <ValuteCreate />
          </ProtectedRoute>
        }
      />
      <Route path={':id'} element={<ValutaDetail />} />
      <Route
        path={':id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <ValuteEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
