import { BaseDonatore, Donatore } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  InputField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

export function DonatoreGridForm() {
  const { t } = useTranslation()
  return (
    <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem>
        <InputField
          isRequired
          name="generalita"
          label={t('generality') ?? ''}
        />
      </GridItem>
      <GridItem>
        <InputField name="sede" label={t('site') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField name="mail" type={'email'} label={t('email') ?? ''} />
      </GridItem>
      <GridItem colSpan={2}>
        <TextareaField name="note" label={t('notes') ?? ''} rows={6} />
      </GridItem>
    </Grid>
  )
}

const initialValues: BaseDonatore = {
  generalita: '',
  mail: '',
  note: '',
  sede: '',
  allegato: null,
}

interface DonatoreFormProps {
  donatore?: Donatore
  save: (donatore: Donatore) => Promise<void>
}

export default function DonatoreForm({ donatore, save }: DonatoreFormProps) {
  const { t } = useTranslation()

  const DonatoreSchema = Yup.object().shape({
    generalita: Yup.string().required().label(t('generality')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('donors'),
        link: '/donors',
      },
      {
        label: t('new_donor'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('donors'),
        link: '/donors',
      },
      {
        label: donatore?.generalita ?? '',
        link: `/donors/${donatore?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, donatore])

  return (
    <Formik
      validationSchema={DonatoreSchema}
      onSubmit={(donatore, { setErrors, setSubmitting }) =>
        save({
          ...donatore,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(donatore ?? initialValues) as Donatore}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={donatore ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <DonatoreGridForm />
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
