import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { useQsFilters } from '../../../hooks/filters'
import { useReferenti, useReferentiDownload } from '../../../hooks/referenti'
import createStringLink from '../../../utils'
import { useProgettoSimple } from '../../../hooks/progetti'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'referente_generico__nome',
})

export default function ReferentiList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const filtersWithProgetto = useMemo(() => {
    return {
      ...filters,
      progetto: id,
    }
  }, [filters, id])
  const { data: referenti, isLoading } = useReferenti(filtersWithProgetto)
  const { t, i18n } = useTranslation()
  const downloadWithParams = useReferentiDownload(filters)

  const navigate = useNavigate()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('contacts'),
      },
    ]
  }, [t, progetto, id])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                color={'white'}
                _hover={{ bg: 'brand' }}
                bg={'brandSecondary'}
                onClick={() => {
                  navigate(
                    createStringLink({
                      lang: i18n.language,
                      path: `/projects/${id}/contacts/create`,
                    })
                  )
                }}
              >
                {t('create')}
              </Button>
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={referenti}
            filters={filters}
            options={[
              { value: 'referente_generico__nome', label: t('name') },
              { value: 'data_inizio', label: t('date_start') },
              { value: 'data_fine', label: t('date_end') },
              { value: 'referente_generico__telefono', label: t('phone') },
              { value: 'referente_generico__email', label: t('email') },
              { value: 'referente_generico__ente__nome', label: t('entity') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('name')}</Th>
                      <Th>{t('date_start')}</Th>
                      <Th>{t('date_end')}</Th>
                      <Th>{t('phone')}</Th>
                      <Th>{t('email')}</Th>
                      <Th>{t('entity')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {referenti!.results.map((referente) => (
                      <Tr padding={2} key={referente.id}>
                        <Td>
                          <LangLinkWithStyle
                            to={`/projects/${id}/contacts/${referente.id}`}
                          >
                            {referente.referente_generico_data.nome}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{referente.data_inizio}</Td>
                        <Td>{referente.data_fine}</Td>
                        <Td>{referente.referente_generico_data.telefono}</Td>
                        <Td>{referente.referente_generico_data.email}</Td>
                        <Td>
                          {referente.referente_generico_data.ente_data?.nome}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={referenti!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
