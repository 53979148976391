import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { usePaesiInterventoOverview } from '../../hooks/paesi-intervento'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import { useMemo } from 'react'
import createStringLink from '../../utils'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'nome',
})

export default function PaesiInterventoOverview() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = usePaesiInterventoOverview(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('countries_of_intervention_overview'),
      },
    ]
  }, [t])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                onClick={() => {
                  navigate(
                    createStringLink({
                      path: `/countries-of-intervention`,
                      lang: i18n.language,
                    })
                  )
                }}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
              >
                {t('countries_of_intervention')}
              </Button>
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            //   downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'nome', label: t('name') },
              {
                value: 'num_progetti',
                label: t('num_projects'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Table variant={'pinc'} mt={3}>
                <Thead>
                  <Tr>
                    <Th>{t('name')}</Th>
                    <Th>{t('num_projects')}</Th>
                    <Th>{t('open_projects')}</Th>
                    <Th>{t('closed_projects')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data!.results.map((paese) => (
                    <Tr padding={2} key={paese.id}>
                      <Td>
                        <LangLinkWithStyle
                          to={`/countries-of-intervention/${paese.id}`}
                        >
                          {paese.nome}
                        </LangLinkWithStyle>
                      </Td>
                      <Td>{paese.num_progetti}</Td>
                      <Td>{paese.num_progetti_aperti}</Td>
                      <Td>{paese.num_progetti_chiusi}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
