import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import EnteDetail from './EnteDetail'
import EntiCreate from './EntiCreate'
import EntiEdit from './EntiEdit'
import EntiList from './EntiList'

export default function Enti() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<EntiList />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <EntiCreate />
          </ProtectedRoute>
        }
      />
      <Route path="/:id" element={<EnteDetail />} />
      <Route
        path="/:id/edit"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <EntiEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
