import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  PaginatedDJResponse,
  Referente,
} from '../types'
import { useDownloadFile } from './download'


export function useReferenti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Referente>>(
    ['referenti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/referenti/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteReferente() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/referenti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['referenti'])
        client.invalidateQueries(['referente'])
      },
    }
  )
}

export function useCreateReferente() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (referente: Referente) =>
        axios
          .post(
            `/api/referenti/`,
            referente,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Referente)
    ),
    {
      onSuccess(savedReferente) {
        client.invalidateQueries(['referenti'])
        client.setQueryData(
          ['referente', savedReferente.id],
          savedReferente
        )
      },
    }
  )
}

export function useUpdateReferente() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (referente: Referente) =>
        axios
          .put(
            `/api/referenti/${referente.id}/`,
            referente,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Referente)
    ),
    {
      onSuccess(referente) {
        client.invalidateQueries(['referenti'])
        client.setQueryData(['referente', referente.id], referente)
      },
    }
  )
}

export function useReferente(id: number) {
  return useQuery<Referente>(
    ['referente', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/referenti/${id}/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Referente)
    )
  )
}

export function useReferentiDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/referenti/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
