import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  PaginatedDJResponse,
  SettoreIntervento,
} from '../types'
import { useDownloadFile } from './download'

export function useSettoriIntervento(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<SettoreIntervento>>(
    ['settori-intervento', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/settori-intervento/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteSettoreIntervento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/settori-intervento/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['settori-intervento'])
        client.invalidateQueries(['settore-intervento'])
      },
    }
  )
}

export function useCreateSettoreIntervento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (settore: SettoreIntervento) =>
        axios
          .post(`/api/settori-intervento/`, settore, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as SettoreIntervento)
    ),
    {
      onSuccess(savedSettore) {
        client.invalidateQueries(['settori-intervento'])
        client.setQueryData(
          ['settore-intervento', savedSettore.id],
          savedSettore
        )
      },
    }
  )
}

export function useUpdateSettoreIntervento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (settore: SettoreIntervento) =>
        axios
          .put(`/api/settori-intervento/${settore.id}/`, settore, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as SettoreIntervento)
    ),
    {
      onSuccess(savedSettore) {
        client.invalidateQueries(['settori-intervento'])
        client.setQueryData(
          ['settore-intervento', savedSettore.id],
          savedSettore
        )
      },
    }
  )
}

export function useSettoreIntervento(id: number) {
  return useQuery<SettoreIntervento>(
    ['settore-intervento', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/settori-intervento/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}


export function useSettoriInterventoDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/settori-intervento/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
