import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import createStringLink from '../../utils'
import DutyStationForm from './DutyStationForm'
import { useCreateDutyStation } from '../../hooks/duty-stations'

export default function DutyStationCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createDutyStation = useCreateDutyStation()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <DutyStationForm
        save={async (duty) => {
          await createDutyStation.mutateAsync(duty)
          navigate(
            createStringLink({
              path: `/duty-stations/`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
