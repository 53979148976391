import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import AutoCompleteField from '../../components/form/fields'
import { Progetto } from '../../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto?: Progetto | null
  excludeProjects?: number[]
}

export default function ModalAssociaUtenteProgetto({
  isOpen,
  onClose,
  onConfirm,
  progetto,
  excludeProjects = [],
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    user: null,
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('associate_user')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'user'}
                    label={t('user') ?? ''}
                    keyQuery={'users-options'}
                    url={'/api/users/options/'}
                    isRequired
                    params={{
                      is_active: true,
                      is_staff: false,
                      paese_intervento: progetto?.paesi_intervento_data?.filter(
                        paese => paese.nome !== 'Italy'
                      )?.map(paese => paese.id),
                    }}
                    value={values.user}
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  //   isDisabled={!values.progetto}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('associate')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
