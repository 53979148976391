import { Box, Button, Flex, Spinner } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import {
  useCollaborazioni,
  useCollaborazioniDownload,
} from '../../../hooks/collaborazioni'
import { useQsFilters } from '../../../hooks/filters'
import { useProgettoSimple } from '../../../hooks/progetti'
import { useCanGestioneCollaborazioni } from '../../../permissions'
import createStringLink from '../../../utils'
import CollaborazioniTable from './CollaborazioniTable'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'num_collaborazioni',
})

export default function CollaborazioniList() {
  const { id } = useParams()
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)

  const navigate = useNavigate()

  const filtersWithProgetto = useMemo(() => {
    return {
      ...filters,
      progetto: id,
    }
  }, [filters, id])

  const { data, isLoading } = useCollaborazioni(filtersWithProgetto)
  const { t, i18n } = useTranslation()
  const { data: progetto } = useProgettoSimple(Number(id!))

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('collaborations'),
      },
    ]
  }, [t, progetto, id])

  const downloadWithParams = useCollaborazioniDownload(filtersWithProgetto)
  const userCanCreateCollaborazioni = useCanGestioneCollaborazioni()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanCreateCollaborazioni && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: `/projects/${id}/collaborations/create`,
                      })
                    )
                  }}
                >
                  {t('associate_collaborator')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'collaboratore__nome', label: t('name') },
              {
                value: 'progressivo',
                label: t('progressivo'),
              },
              {
                value: 'data_inizio',
                label: t('date_start'),
              },
              {
                value: 'data_fine',
                label: t('date_end'),
              },
              {
                value: 'data_interruzione',
                label: t('interruption_date'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <CollaborazioniTable data={data} />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
