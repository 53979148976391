import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneUtenti } from '../../permissions'
import UserCreate from './UserCreate'
import UserDetail from './UserDetail'
import UserEdit from './UserEdit'
import UsersList from './UsersList'

export default function Users() {
  const userCanGestioneUtenti = useCanGestioneUtenti()
  return (
    <Routes>
      <Route index element={<UsersList />} />
      <Route path={'/:id'} element={<UserDetail />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanGestioneUtenti}>
            <UserCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneUtenti}>
            <UserEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
