import { BaseTipoCambio, TipoCambio } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  InputField,
  SelectChakraField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

const initialValues: BaseTipoCambio = {
  codice: '',
  descrizione: '',
  frequenza: 'mensile',
  limite_modifica_anni: 1,
}

interface TipoCambioFormProps {
  tipo?: TipoCambio
  save: (settore: TipoCambio) => Promise<void>
}

export default function TipoCambioForm({ tipo, save }: TipoCambioFormProps) {
  const { t } = useTranslation()

  const TipoCambioSchema = Yup.object().shape({
    codice: Yup.string().required().label(t('code')),
    descrizione: Yup.string().required().label(t('description')),
    frequenza: Yup.string().required().label(t('frequency')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('types_of_exchange'),
        link: '/types-exchange',
      },
      {
        label: t('new_type_of_exchange'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('types_of_exchange'),
        link: '/types-exchange',
      },
      {
        label: tipo?.codice ?? '',
        link: `/types-exchange/${tipo?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, tipo])

  return (
    <Formik
      validationSchema={TipoCambioSchema}
      onSubmit={(tipo, { setErrors, setSubmitting }) =>
        save({
          ...tipo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(tipo ?? initialValues) as TipoCambio}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={tipo ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(1, 1fr)" gap={4}>
                  <GridItem>
                    <InputField
                      isRequired
                      name="codice"
                      label={t('code') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      isRequired
                      name="descrizione"
                      label={t('description') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <SelectChakraField
                      isRequired
                      name="frequenza"
                      label={t('frequency') ?? ''}
                    >
                      <option value="mensile">{t('monthly')}</option>
                      <option value="giornaliero">{t('daily')}</option>
                      <option value="libero">{t('free')}</option>
                    </SelectChakraField>
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="limite_modifica_anni"
                      label={t('modification_limit_years') ?? ''}
                      type="number"
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
