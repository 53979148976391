import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useUpdateVoceSpesa, useVoceSpesa } from '../../hooks/voci-spesa'
import createStringLink from '../../utils'
import VoceSpesaForm from './VoceSpesaForm'

export default function VociSpesaEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useVoceSpesa(+id!)
  const editVoceSpesa = useUpdateVoceSpesa()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <VoceSpesaForm
        voce={data}
        save={async (voce) => {
          const savedVoce = await editVoceSpesa.mutateAsync(voce)
          navigate(
            createStringLink({
              path: `/expense-items/${savedVoce.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
