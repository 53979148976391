import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Movimento, PaginatedDJResponse } from '../types'
import { useDownloadFile } from './download'
import {
  makeFormDataEncoder,
  makeFormDataEncoderForMultiRows,
  serializeQueryParams,
} from './utils'
import { omit } from 'lodash'

export const makeProgettoFormData = makeFormDataEncoder({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

const makeMovimentoMultiFormData = makeFormDataEncoderForMultiRows({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

export function useMovimenti(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Movimento>>(
    ['movimenti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/movimenti/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
              params: serializeQueryParams(params),
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useMovimentiMulti(params: Record<string, any> = {}) {
  return useQuery<Movimento[]>(
    ['movimenti-multi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/movimenti/movimenti-multi/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
              params: serializeQueryParams(params),
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useMovimento(id?: number) {
  return useQuery<Movimento>(
    ['movimento', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/movimenti/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  )
}

const makeImportMovimenti = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useImportMovimentiSpeseLoco() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => ({ importFile }) => {
      const formData = makeImportMovimenti({ import_file: importFile })
      return axios
        .post(`/api/movimenti/movimenti-import-spese-loco/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti'])
      },
    }
  )
}

export function useCopiaMovimento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ id, numero_copie, bozza }) =>
          axios
            .post(
              `/api/movimenti/${id}/copia/`,
              {
                numero_copie,
                bozza,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as any)
    ),
    {
      onSuccess(savedMovimento) {
        client.invalidateQueries(['movimenti'])
        client.setQueryData(['movimento', savedMovimento.id], savedMovimento)
      },
    }
  )
}

export function useCopiaMovimenti() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ ids, numero_copie, bozza }) =>
          axios
            .post(
              `/api/movimenti/copia-movimenti/`,
              {
                ids,
                numero_copie,
                bozza,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as any)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti'])
      },
    }
  )
}

export function useDeleteMovimento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/movimenti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti'])
        client.invalidateQueries(['movimenti'])
      },
    }
  )
}

export function useDeleteMovimenti() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (ids: any) =>
        axios.post(`/api/movimenti/delete-movimenti/`, ids, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti'])
        client.invalidateQueries(['movimenti'])
      },
    }
  )
}

export function useCreateMovimento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (movimento: Movimento) =>
        axios
          .post(`/api/movimenti/`, makeProgettoFormData(movimento), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Movimento)
    ),
    {
      onSuccess(savedMovimento) {
        client.invalidateQueries(['movimenti'])
        client.setQueryData(['movimento', savedMovimento.id], savedMovimento)
      },
    }
  )
}

function data2formdata(data: any, key: string): FormData {
  const fd = new FormData()
  // ig
  if (data instanceof File) {
    fd.append('', data)
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const itemFD = data2formdata(data[i], key)
      for (const [key, value] of itemFD.entries()) {
        fd.append(`[${i}]${key}`, value)
      }
    }
  } else if (typeof data === 'object' && data !== null) {
    for (const [outerKey, value] of Object.entries(data)) {
      const itemFD = data2formdata(value, outerKey)
      for (const [key, value] of itemFD.entries()) {
        if (key === '') {
          fd.append(`${outerKey}`, value)
        } else if (key[0] === '[') {
          fd.append(`${outerKey}${key}`, value)
        } else {
          fd.append(`${outerKey}.${key}`, value)
        }
      }
    }
  } else {
    fd.append('', data ?? '')
  }
  return fd
}

export function useCreateMultipleMovimenti() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (data: { movimenti: Movimento[] }) =>
        axios
          .post(
            `/api/movimenti/create-multiple/`,
            data2formdata(
              {
                ...data,
                movimenti: data.movimenti.map((m) => {
                  if (typeof m.allegato === 'string') {
                    return omit(m, ['allegato'])
                  }
                  if (typeof m.allegato_2 === 'string') {
                    return omit(m, ['allegato_2'])
                  }
                  if (typeof m.allegato_3 === 'string') {
                    return omit(m, ['allegato_3'])
                  }
                  if (typeof m.allegato_4 === 'string') {
                    return omit(m, ['allegato_4'])
                  }
                  if (typeof m.allegato_5 === 'string') {
                    return omit(m, ['allegato_5'])
                  }
                  return omit(m, 'progetti_copia')
                }),
              },
              'movimenti'
            ),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Movimento)
    ),
    {
      onSuccess(savedMovimento) {
        client.invalidateQueries(['movimenti'])
        client.setQueryData(['movimento', savedMovimento.id], savedMovimento)
      },
    }
  )
}

export function useUpdateMovimento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (movimento: Movimento) =>
        axios
          .put(
            `/api/movimenti/${movimento.id}/`,
            makeProgettoFormData(movimento),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Movimento)
    ),
    {
      onSuccess(savedMovimento) {
        client.invalidateQueries(['movimenti'])
        client.setQueryData(['movimento', savedMovimento.id], savedMovimento)
      },
    }
  )
}

export function useCheckMovimenti() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ movimenti, data_pagamento, riportata_e_c }) =>
          axios
            .put(
              `/api/movimenti/check-movimenti/`,
              {
                movimenti,
                data_pagamento,
                riportata_e_c,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Movimento)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti-conto'])
      },
    }
  )
}

export function useUpdateRiportataEC() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ id, riportata_e_c }) =>
          axios
            .put(
              `/api/movimenti/${id}/update-riportata-e-c/`,
              {
                riportata_e_c,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Movimento)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti-conto'])
      },
    }
  )
}

export function useUpdateDataPagamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ id, data_pagamento }) =>
          axios
            .put(
              `/api/movimenti/${id}/update-data-pagamento/`,
              {
                data_pagamento,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Movimento)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['movimenti-conto'])
      },
    }
  )
}

export function useMovimentiPdfDownload(ids?: number[]) {
  const blank = true
  const downloader = useDownloadFile(blank)
  const downloadWithParams = useCallback(() => {
    return downloader(`/api/movimenti/pdf-multi/`, { ids })
  }, [downloader, ids])
  return downloadWithParams
}

export function useMovimentoPdfDownloadAll(filters: any) {
  const blank = true
  const downloader = useDownloadFile(blank)
  const downloadWithParams = useCallback(() => {
    return downloader(`/api/movimenti/pdf-all/`, filters)
  }, [downloader, filters])
  return downloadWithParams
}

export function useMovimentoPdfDownload(id: number) {
  const blank = true
  const downloader = useDownloadFile(blank)
  const downloadWithParams = useCallback(() => {
    return downloader(`/api/movimenti/${id}/pdf/`, {})
  }, [downloader, id])
  return downloadWithParams
}

export function useMovimentiDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader(`/api/movimenti/download/`, filters)
  }, [downloader, filters])
  return downloadWithParams
}

export function useCreateSpesaPersonale(id: number) {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (movimento: Movimento) =>
        axios
          .post(
            `/api/progetti/${id}/movimenti-personale/`,
            {
              ...movimento,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Movimento)
    ),
    {
      onSuccess(savedMovimento) {
        client.invalidateQueries(['movimenti'])
        client.setQueryData(['movimento', savedMovimento.id], savedMovimento)
      },
    }
  )
}

export function useMovimentiConto(
  id: string | undefined,
  params: Record<string, any> = {}
) {
  return useQuery<PaginatedDJResponse<Movimento>>(
    ['movimenti-conto-bancario', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/conti-bancari/${id}/movimenti-conto/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
              params: serializeQueryParams(params),
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  )
}

export function useDownloadMovimentiConto(
  id: number | undefined,
  filters: any
) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader(
      `/api/conti-bancari/${id}/download-movimenti-conto/`,
      filters
    )
  }, [downloader, filters])
  return downloadWithParams
}

export function useDownloadMovimentiConti(conti: number[]) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader(`/api/conti-bancari/download-movimenti-conti/`, { conti })
  }, [downloader, conti])
  return downloadWithParams
}