import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import VoceSpesaDetail from './VoceSpesaDetail'
import VociSpesaCreate from './VociSpesaCreate'
import VociSpesaEdit from './VociSpesaEdit'
import VociSpesaList from './VociSpesaList'

export default function VociSpesa() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<VociSpesaList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <VociSpesaCreate />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<VoceSpesaDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <VociSpesaEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
