import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import ModalDelete from '../../components/ModalDelete'
import Paginator from '../../components/Paginator'
import {
  useCollaboratore,
  useCollaboratoreSubtotaliProgetto,
  useDeleteCollaboratore,
} from '../../hooks/collaboratori'
import {
  useCollaborazioni,
  useCreateCollaborazione,
} from '../../hooks/collaborazioni'
import { useQsFilters } from '../../hooks/filters'
import { useCanGestioneAnagrafiche } from '../../permissions'
import createStringLink, { getNameOfFileFromUrl } from '../../utils'
import CollaborazioniTable from '../Progetti/Collaborazioni/CollaborazioniTable'
import SubtotaliProgettiCollaboratoreTable from './SubtotaliProgettiCollaboratoreTable'
import ModalFormCollaborazione from '../Progetti/Collaborazioni/ModalFormCollaborazione'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '-data_inizio',
})

export default function CollaboratoreDetail() {
  const { uiFilters, filters, setFilters } = useQsFilters(initFilters)
  const navigate = useNavigate()
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const { data } = useCollaboratore(Number(id!))
  const deleteCollaboratore = useDeleteCollaboratore()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const { data: subtotaliProgetti } = useCollaboratoreSubtotaliProgetto(
    Number(id!)
  )

  const filtersCollaborazioni = useMemo(() => {
    return {
      ...filters,
      collaboratore: Number(id!),
    }
  }, [id, filters])

  const { data: collaborazioni } = useCollaborazioni(filtersCollaborazioni)

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('employees'),
        link: '/employees',
      },
      {
        label: data?.nome ?? '',
      },
    ]
  }, [t, data])

  const {
    isOpen: isOpenCollaborazione,
    onClose: onCloseCollaborazione,
    onOpen: onOpenCollaborazione,
    onToggle: onToggleCollaborazione,
  } = useDisclosure()

  const createCollaborazione = useCreateCollaborazione()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                me={4}
                onClick={() => {
                  navigate(
                    createStringLink({
                      path: `/movements?collaboratore=${id}`,
                      lang: i18n.language,
                    })
                  )
                }}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
              >
                {t('list_movements')}
              </Button>
              {userCanGestioneAnagrafiche && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/employees/${id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card px={2}>
          <CardBody>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('name')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('entity')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.ente_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('email')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.email}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('phone')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.telefono}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('intervention_area')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.area_intervento_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('country_intervention')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.paese_intervento_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('duty_station')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.duty_station_relazione_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            {data?.data_nascita && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('date_of_birth')}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {dayjs(data?.data_nascita).format('DD/MM/YYYY')}
                  </Text>
                </GridItem>
              </Grid>
            )}
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('residence')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.residenza}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('place_of_birth')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.luogo_nascita}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('employee_code')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.codice}
                </Text>
              </GridItem>
            </Grid>
            {data?.allegato && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_2 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_2')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_2}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_2)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_3 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_3')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_3}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_3)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_4 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_4')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_4}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_4)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_5 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_5')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_5}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_5)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(data?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(data?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            {t('subtotals_by_project')}
          </CardHeader>
          <CardBody>
            <SubtotaliProgettiCollaboratoreTable data={subtotaliProgetti} />
          </CardBody>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {t('collaborations_list')}
            <Button
              size={'sm'}
              color={'white'}
              onClick={() => {
                onOpenCollaborazione()
              }}
              type={'submit'}
              _hover={{ bg: 'brand' }}
              bg={'brandSecondary'}
            >
              {t('add_collaboration')}{' '}
            </Button>
          </CardHeader>
          <CardBody>
            <CollaborazioniTable data={collaborazioni} showProject noSticky />
            <Box
              width={'100%'}
              mt={3}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <Paginator
                count={collaborazioni!.count}
                currentPage={uiFilters.page}
                goToPage={(page) => setFilters({ page })}
              />
            </Box>
          </CardBody>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteCollaboratore.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/employees`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.nome}
        title={t('delete_employee')}
      />
      <ModalFormCollaborazione
        isOpen={isOpenCollaborazione}
        onClose={onCloseCollaborazione}
        progettoAsField={true}
        collaboratore={data}
        onToggle={onToggleCollaborazione}
        onConfirm={async (values: any) => {
          await createCollaborazione.mutateAsync({
            ...values,
          })

          onCloseCollaborazione()
        }}
        onOpen={onOpenCollaborazione}
      />
    </Box>
  )
}
