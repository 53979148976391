import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateModalitaPagamento } from '../../hooks/modalita-pagamento'
import createStringLink from '../../utils'
import ModalitaPagamentoForm from './ModalitaPagamentoForm'

export default function ModalitaPagamentoCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createModalitaPagamento = useCreateModalitaPagamento()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <ModalitaPagamentoForm
        save={async (modalita) => {
          const savedModalita = await createModalitaPagamento.mutateAsync(modalita)
          navigate(
            createStringLink({
              path: `/methods-of-payment/${savedModalita.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
