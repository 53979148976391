import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'
import { Collaborazione, PaginatedDJResponse } from '../../../types'
import { numberFormatter } from '../../../utils'
import dayjs from 'dayjs'

interface TableProps {
  data: PaginatedDJResponse<Collaborazione> | undefined
  showProject?: boolean
  noSticky?: boolean
}

export default function CollaborazioniTable({
  data,
  showProject = false,
  noSticky = false,
}: TableProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  return (
    <Table variant={'pinc'}>
      <Thead position={
        noSticky ? 'relative' : 'sticky'
      } top={
        noSticky ? 'unset' : '0'
      } 
      zIndex={
        noSticky ? 'unset' : 2
      }>
        <Tr>
          {showProject && <Th>{t('project')}</Th>}
          <Th>{t('code')}</Th>
          <Th>{t('name')}</Th>
          {!showProject && <Th>{t('progressive')}</Th>}
          {showProject && <Th flexWrap={'nowrap'}>{t('import')}</Th>}
          {showProject && <Th>{t('retribution_type')}</Th>}
          <Th>{t('date_start')}</Th>
          <Th>{t('date_end')}</Th>
          <Th>{t('interruption_date')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data!.results.map((collaborazione) => (
          <Tr padding={2} key={collaborazione.id}>
            {showProject && (
              <Td>
                <LangLinkWithStyle
                  to={`/projects/${collaborazione.progetto_data?.id}`}
                >
                  {collaborazione.progetto_data?.codice}
                </LangLinkWithStyle>
              </Td>
            )}
            <Td>
              <LangLinkWithStyle
                to={`/projects/${collaborazione.progetto}/collaborations/${collaborazione.id}`}
              >
                {collaborazione.codice_collaborazione}
              </LangLinkWithStyle>
            </Td>
            <Td>{collaborazione.collaboratore_data?.nome}</Td>
            {showProject && (
              <Td flexWrap={'nowrap'}>
                {numberFormatter.format(
                  collaborazione.importo_retribuzione ?? 0
                )}{' '}
                {collaborazione.valuta_data.codice}
              </Td>
            )}
            {showProject && (
              <Td>{collaborazione.tipo_retribuzione_data?.descrizione}</Td>
            )}
            {!showProject && <Td>{collaborazione.progressivo}</Td>}
            <Td>{dayjs(collaborazione.data_inizio).format('DD/MM/YYYY')}</Td>
            <Td>{dayjs(collaborazione.data_fine).format('DD/MM/YYYY')}</Td>
            <Td>
              {collaborazione.data_interruzione
                ? dayjs(collaborazione.data_interruzione).format('DD/MM/YYYY')
                : ''}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
