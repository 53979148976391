import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import ModalDelete from '../../components/ModalDelete'
import {
  useAssociaProgettoFornitore,
  useDeleteFornitore,
  useFornitore,
  useProgettiFornitore,
} from '../../hooks/fornitori'
import { useCanInsertFornitori } from '../../permissions'
import createStringLink, {
  getNameOfFileFromUrl,
  numberFormatter,
} from '../../utils'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import ModalAssociateProject from '../../components/ModalAssociateProject'

export default function FornitoreDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const { data } = useFornitore(Number(id!))
  const deleteFornitore = useDeleteFornitore()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const userCanInsertFornitori = useCanInsertFornitori()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('suppliers'),
        link: '/suppliers',
      },
      {
        label: data?.ragione_sociale ?? '',
      },
    ]
  }, [t, data])

  const { data: progettiFornitore, refetch } = useProgettiFornitore(Number(id!))

  const {
    isOpen: isOpenAssociate,
    onClose: onCloseAssociate,
    onOpen: onOpenAssociate,
    onToggle: onToggleAssociate,
  } = useDisclosure()

  const associaProgettoFornitore = useAssociaProgettoFornitore()

  const excludeProjects = useMemo(() => {
    return progettiFornitore?.results?.map((p) => p.id)
  }, [progettiFornitore])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                me={4}
                onClick={() => {
                  navigate(
                    createStringLink({
                      path: `/movements?fornitore=${id}`,
                      lang: i18n.language,
                    })
                  )
                }}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
              >
                {t('list_movements')}
              </Button>
              {userCanInsertFornitori && (
                <>
                  {data?.num_movimenti === 0 && (
                    <Button
                      size={'sm'}
                      me={4}
                      _hover={{
                        bg: 'error',
                        color: 'white',
                        opacity: 0.8,
                      }}
                      onClick={onOpen}
                      bg={'error'}
                      color={'white'}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/suppliers/${id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('business_name')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.ragione_sociale}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('vat_number')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.partita_iva}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('tax_id_code')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.codice_fiscale}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('progressive')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.progressivo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('address')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.indirizzo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('country')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.paese_intervento_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('email')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.mail}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('phone')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.telefono}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('notes')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.note}
                </Text>
              </GridItem>
            </Grid>
            {data?.allegato && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_2 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_2')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_2}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_2)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_3 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_3')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_3}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_3)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_4 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_4')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_4}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_4)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_5 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_5')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={data?.allegato_5}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(data?.allegato_5)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(data?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(data?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {t('projects')}
            <Button
              size={'sm'}
              color={'white'}
              onClick={onOpenAssociate}
              type={'submit'}
              _hover={{ bg: 'brand' }}
              bg={'brandSecondary'}
            >
              {t('associate_project')}{' '}
            </Button>
          </CardHeader>
          <CardBody>
            <Table variant={'pinc'}>
              <Thead>
                <Tr>
                  <Th>{t('code')}</Th>
                  <Th>{t('state')}</Th>
                  <Th>{t('code_donator')}</Th>
                  <Th>{t('date_start')}</Th>
                  <Th>{t('date_end')}</Th>
                  <Th>{t('country')}</Th>
                  <Th>{t('total_import')}</Th>
                  <Th>{t('main_entity')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {progettiFornitore!.results?.map((progetto) => (
                  <Tr padding={2} key={progetto.id}>
                    <Td>
                      <LangLinkWithStyle to={'/projects/' + progetto.id}>
                        {progetto.codice}
                      </LangLinkWithStyle>
                      <Box maxWidth={210}>{progetto.titolo}</Box>
                    </Td>
                    <Td>
                      {progetto.closed ? (
                        <Box display={'flex'} alignItems={'center'}>
                          <Box
                            display={'inline-block'}
                            width={2}
                            height={2}
                            borderRadius={'50%'}
                            bg={'error'}
                            mr={2}
                          />
                          <Text
                            fontSize={12}
                            fontWeight={400}
                            textTransform={'capitalize'}
                            color={'darkblue'}
                          >
                            {t('closed')}{' '}
                          </Text>
                        </Box>
                      ) : (
                        <Box display={'flex'} alignItems={'center'}>
                          <Box
                            display={'inline-block'}
                            width={2}
                            height={2}
                            borderRadius={'50%'}
                            bg={'success'}
                            mr={2}
                          />
                          <Text
                            fontSize={12}
                            fontWeight={400}
                            textTransform={'capitalize'}
                            color={'darkblue'}
                          >
                            {t('open')}{' '}
                          </Text>
                        </Box>
                      )}
                      {progetto.data_blocco_compentenza && (
                        <Badge
                          size={'xs'}
                          px={2}
                          fontSize={10}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          colorScheme={'red'}
                          borderRadius={'md'}
                        >
                          {t('block_on_date')}{' '}
                          {dayjs(progetto.data_blocco_compentenza).format(
                            'DD/MM/YYYY'
                          )}
                        </Badge>
                      )}
                    </Td>
                    <Td>{progetto.codice_progetto_donatore}</Td>
                    <Td whiteSpace={'nowrap'}>
                      {progetto.data_inizio
                        ? dayjs(progetto.data_inizio).format('DD/MM/YYYY')
                        : ''}
                    </Td>
                    <Td whiteSpace={'nowrap'}>
                      {progetto.data_fine
                        ? dayjs(progetto.data_fine).format('DD/MM/YYYY')
                        : ''}
                    </Td>
                    <Td>
                      {progetto?.paesi_intervento_data
                        ?.map((paese: any) => {
                          return paese.nome
                        })
                        .join(', ')}
                    </Td>
                    <Td textAlign={'right'}>
                      <Box color={'brand'} fontWeight={700}>
                        {numberFormatter.format(progetto.importo_totale ?? 0)}
                      </Box>
                      {progetto.valuta_rendicontazione_data?.codice}
                    </Td>
                    <Td>{progetto.ente_principale_data?.nome}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteFornitore.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/suppliers`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.ragione_sociale}
        title={t('delete_supplier')}
      />
      {isOpenAssociate && (
        <ModalAssociateProject
          isOpen={isOpenAssociate}
          fornitore={data}
          onToggle={onToggleAssociate}
          onClose={onCloseAssociate}
          excludeProjects={excludeProjects}
          onConfirm={async (values) => {
            await associaProgettoFornitore
              .mutateAsync({
                progetto: values.progetto,
                fornitore: Number(id!),
              })
              .then(() => {
                refetch()
              })
            onCloseAssociate()
          }}
          onOpen={onOpenAssociate}
        />
      )}
    </Box>
  )
}
