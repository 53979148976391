import { BaseReferenteGenerico, ReferenteGenerico } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  InputField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

const initialValues: BaseReferenteGenerico = {
  nome: '',
  email: '',
  note: '',
  allegato: null,
  telefono: '',
  ente: null,
}

interface ReferenteGenericoFormProps {
  referente?: ReferenteGenerico
  save: (referente: ReferenteGenerico) => Promise<void>
}

export default function ReferenteGenericoForm({
  referente,
  save,
}: ReferenteGenericoFormProps) {
  const { t } = useTranslation()

  const ReferenteGenericoSchema = Yup.object().shape({
    nome: Yup.string().required().label(t('name')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('contacts'),
        link: '/contacts',
      },
      {
        label: t('new_contact'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('contacts'),
        link: '/contacts',
      },
      {
        label: referente?.nome ?? '',
        link: `/contacts/${referente?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, referente])

  return (
    <Formik
      validationSchema={ReferenteGenericoSchema}
      onSubmit={(referente, { setErrors, setSubmitting }) =>
        save({
          ...referente,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(referente ?? initialValues) as ReferenteGenerico}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        handleReset,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    referente ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <InputField
                      isRequired
                      name="nome"
                      label={t('name') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField name="email" label={t('email') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField name="telefono" label={t('phone') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      name="ente"
                      label={t('entity') ?? ''}
                      url={'/api/enti/options/'}
                      keyQuery={'options-enti'}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <TextareaField name="note" label={t('notes') ?? ''} />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
