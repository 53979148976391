import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { useQsFilters } from '../../../hooks/filters'
import { useProgettoSimple } from '../../../hooks/progetti'
import { useCanCreateProgetti } from '../../../permissions'
import createStringLink, { numberFormatter } from '../../../utils'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'
import { useFinanziamenti } from '../../../hooks/finanziamenti'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'descrizione',
})

export default function FinanziamentiList() {
  const { id } = useParams()
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)

  const navigate = useNavigate()

  const filtersWithProgetto = useMemo(() => {
    return {
      ...filters,
      progetto: id,
    }
  }, [filters, id])

  const { data, isLoading } = useFinanziamenti(filtersWithProgetto)
  const { t, i18n } = useTranslation()
  const { data: progetto } = useProgettoSimple(Number(id!))

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('financing'),
      },
    ]
  }, [t, progetto, id])
  const userCanCreateFinanziamenti = useCanCreateProgetti()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanCreateFinanziamenti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: `/projects/${id}/financing/create`,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'descrizione', label: t('description') },
              {
                value: 'ente__nome',
                label: t('entity'),
              },
              {
                value: 'importo',
                label: t('amount'),
              },
              {
                value: 'annualita',
                label: t('annuity'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('entity')}</Th>
                      <Th>{t('description')}</Th>
                      <Th>{t('amount')}</Th>
                      <Th>{t('annuity')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((finanziamento) => (
                      <Tr padding={2} key={finanziamento.id}>
                        <Td>
                          <Box display={'flex'} alignItems={'center'}>
                            <LangLinkWithStyle
                              to={`/projects/${id}/financing/${finanziamento.id}`}
                            >
                              {finanziamento.ente_data?.nome}
                            </LangLinkWithStyle>
                          </Box>
                        </Td>
                        <Td>{finanziamento.descrizione}</Td>
                        <Td textAlign={'right'}>
                          <Box color={'brand'} fontWeight={700}>
                            {numberFormatter.format(finanziamento.importo)}
                          </Box>
                          {progetto?.valuta_rendicontazione_data?.codice}
                        </Td>
                        <Td>{finanziamento.annualita}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
