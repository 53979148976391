import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import DonatoriProgettoList from './DonatoriProgettoList'

export default function DonatoriProgetto() {
  return (
    <Routes>
      <Route index element={<DonatoriProgettoList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
