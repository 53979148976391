import { Box, Button, Flex, Spinner, useDisclosure } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { useDonatoriDownload } from '../../../hooks/donatori'
import { useQsFilters } from '../../../hooks/filters'
import {
  useAddDonatoreProgetto,
  useDonatoriProgetto,
  useProgetto,
  useProgettoSimple,
} from '../../../hooks/progetti'
import DonatoriTable from '../../Donatori/DonatoriTable'
import ModalAssociaDonatore from './ModalAssociaDonatore'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'generalita',
})

export default function FornitoriList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const { data: donatori, isLoading } = useDonatoriProgetto(
    Number(id!),
    filters
  )
  const { t } = useTranslation()
  const downloadWithParams = useDonatoriDownload(filters)
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const associaDonatore = useAddDonatoreProgetto()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('donors'),
      },
    ]
  }, [t, progetto, id])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                color={'white'}
                _hover={{ bg: 'brand' }}
                bg={'brandSecondary'}
                onClick={onOpen}
              >
                {t('associate_donor')}
              </Button>
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={donatori}
            filters={filters}
            options={[
              { value: 'generalita', label: t('generality') },
              {
                value: 'progressivo',
                label: t('progressive'),
              },
              {
                value: 'sede',
                label: t('site'),
              },
              {
                value: 'mail',
                label: t('email'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <DonatoriTable
                  showDelete
                  data={donatori}
                  progetto={Number(id!)}
                />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={donatori!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <ModalAssociaDonatore
          isOpen={isOpen}
          onClose={onClose}
          onToggle={onToggle}
          progetto={progetto}
          donatoriInProgetto={donatori?.results ?? []}
          onConfirm={async (donatore) => {
            await associaDonatore
              .mutateAsync({
                progetto: Number(id!),
                donatore: donatore.donatore,
              })
              .then(() => {
                onClose()
              })
          }}
          onOpen={onOpen}
          title={t('associate_supplier')}
        />
      )}
    </Box>
  )
}
