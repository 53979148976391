import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Progetto } from '../../types'
import { Formik } from 'formik'
import { CheckboxField, DateField } from '../../components/form'
import * as Yup from 'yup'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: any) => void
  progetto?: Progetto | undefined
}

export default function ModalBloccoData({
  progetto,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues: any = {
    data_blocco_compentenza: progetto?.data_blocco_compentenza ?? '',
    blocco_compentenza_gestori: progetto?.blocco_compentenza_gestori ?? false,
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('block_final_on_competence_date')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <DateField
                    name="data_blocco_compentenza"
                    label={t('block_date_competence') ?? ''}
                    value={values.data_blocco_compentenza}
                  />
                </Box>
                <Box>
                  <CheckboxField
                    name="blocco_compentenza_gestori"
                    label={t('block_competence_managers') ?? ''}
                    value={values.blocco_compentenza_gestori}
                  />
                </Box>
              </ModalBody>
              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('confirm')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
