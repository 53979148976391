import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useTipiCambio, useTipiCambioDownload } from '../../hooks/tipi-cambio'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import { useCanGestioneCambi } from '../../permissions'
import { Select } from 'chakra-react-select'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'codice',
  frequenza: params.get('frequenza') ?? '',
})

export default function TipiCambioList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useTipiCambio(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const downloadWithParams = useTipiCambioDownload(filters)
  const userCanGestioneCambi = useCanGestioneCambi()

  const optionsFrequenza = useMemo(() => {
    return [
      { value: 'giornaliero', label: t('giornaliero') },
      { value: 'mensile', label: t('mensile') },
      { value: 'libero', label: t('libero') },
    ]
  }, [t])

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('types_of_exchange'),
      },
    ]
  }, [t])

  const [showFilters, setShowFilters] = useState(true)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={breadCrumbsItems} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
              {userCanGestioneCambi && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/types-exchange/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            zIndex={99}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('frequency')}</FormLabel>
                    <Select
                      options={optionsFrequenza ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          frequenza: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        optionsFrequenza?.find(
                          (v) => v.value === filters.frequenza
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'codice', label: t('code') },
              { value: 'descrizione', label: t('description') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                 height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                mt={3}
                width={'100%'}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('code')}</Th>
                      <Th>{t('description')}</Th>
                      <Th>{t('frequency')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((tipo) => (
                      <Tr padding={2} key={tipo.id}>
                        <Td>
                          <LangLinkWithStyle to={`/types-exchange/${tipo.id}`}>
                            {tipo.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{tipo.descrizione}</Td>
                        <Td>{t(tipo.frequenza)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
