import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAuthUser } from 'use-eazy-auth'
import { AccountUser } from '../types'
import { InputField, transformErrorForForm } from './form'
import { useChangePassword } from '../hooks/profile'
import * as Yup from 'yup'

interface ModalChangePasswordProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: () => void
}

export default function ModalChangePassword({
  title,
  isOpen,
  onClose,
  onConfirm,
}: ModalChangePasswordProps) {
  const { user } = useAuthUser<AccountUser>()
  const { t } = useTranslation()
  const initialValues = {
    old_password: '',
    password: '',
    repeatPassword: '',
    email: user?.email,
  }

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('password_is_required') ?? '')
      .min(8, t('password_min_length') ?? ''),
    repeatPassword: Yup.string()
      .required(t('confirm_password_is_required') ?? '')
      .oneOf([Yup.ref('password'), ''], t('passwords_must_match') ?? ''),
  })

  const changePassword = useChangePassword()
  return (
    <Formik
    //   initialTouched={{ password: true, repeatPassword: true }}
      onSubmit={(data, { setErrors, setTouched }) =>
        changePassword({
          password: data.password,
          old_password: data.old_password,
        }).then(
          () => {
            onConfirm()

          },
          (error) => {
            setErrors(transformErrorForForm(error))
          }
        )
      }
      validationSchema={ResetPasswordSchema}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
        handleReset,
        dirty,
      }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('change_password')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <InputField
                    label="E-mail"
                    type="text"
                    isDisabled
                    name="email"
                  />
                </Box>
                <Box mt={3}>
                  <InputField
                    label="Current password"
                    type="password"
                    placeholder={'Enter password'}
                    name="old_password"
                  />
                </Box>
                <Box mt={3}>
                  <InputField
                    label="New password"
                    type="password"
                    placeholder={'Enter password'}
                    name="password"
                  />
                </Box>
                <Box mt={3}>
                  <InputField
                    label="Repeat new password"
                    type="password"
                    placeholder={'Enter password'}
                    name="repeatPassword"
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type='submit'
                  bg={'brand'}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('change_password')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
