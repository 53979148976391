import { BaseEnte, Ente } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  InputField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

const initialValues: BaseEnte = {
  codice: '',
  nome: '',
  descrizione: '',
}

interface EnteFormProps {
  ente?: Ente
  save: (ente: Ente) => Promise<void>
}

export default function EnteForm({ ente, save }: EnteFormProps) {
  const { t } = useTranslation()

  const EnteSchema = Yup.object().shape({
    codice: Yup.string().required().label(t('code')),
    nome: Yup.string().required().label(t('name')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('entities'),
        link: '/entities',
      },
      {
        label: t('new_entity'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('entities'),
        link: '/entities',
      },
      {
        label: ente?.nome ?? '',
        link: `/entities/${ente?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, ente])

  return (
    <Formik
      validationSchema={EnteSchema}
      onSubmit={(ente, { setErrors, setSubmitting }) =>
        save({
          ...ente,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(ente ?? initialValues) as Ente}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={ente ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <InputField name="codice" label={t('code') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField name="nome" label={t('name') ?? ''} />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <TextareaField
                      rows={5}
                      name="descrizione"
                      label={t('description') ?? ''}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
