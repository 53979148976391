import { Navigate } from 'react-router'

interface ProtectedRouteProps {
  redirectPath?: string
  children: React.ReactNode
  permessionCheck: boolean
}

export default function ProtectedRoute({
  redirectPath = '/',
  children,
  permessionCheck,
}: ProtectedRouteProps) {
  if (!permessionCheck) {
    return <Navigate to={redirectPath} />
  }

  return <>{children}</>
}
