import { Box, Button } from '@chakra-ui/react'
import { MdDownload } from 'react-icons/md'

interface DownloadButtonProps {
  onClick: () => void
  isDisabled?: boolean
}

export default function DownloadButton({ onClick, isDisabled }: DownloadButtonProps) {
  return (
    <Button
      size={'sm'}
      border={'none'}
      mt={1}
      fontSize={14}
      fontWeight={600}
      onClick={onClick}
      color={'brand'}
      isDisabled={isDisabled}
      bg={'brandLight'}
      _hover={{
        bg: 'brandSecondary',
        color: 'white',
      }}
    >
      Download{' '}
      <Box ms={3}>
        <MdDownload size={14} />
      </Box>
    </Button>
  )
}
