import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import { CollaboratoreSubtotaleProgetto } from '../../types'
import { numberFormatter } from '../../utils'

interface Props {
  data: CollaboratoreSubtotaleProgetto[] | undefined
}

export default function SubtotaliProgettiCollaboratoreTable({ data }: Props) {
  const { t } = useTranslation()
  return (
    <Table variant={'pinc'} mt={3}>
      <Thead>
        <Tr>
          <Th>{t('code')}</Th>
          <Th>{t('title')}</Th>
          <Th>{t('total_reporting')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data!.map((subtotale) => (
          <Tr padding={2} key={subtotale.progetto__pk}>
            <Td>{subtotale.progetto__codice}</Td>
            <Td>
              <LangLinkWithStyle to={`/projects/${subtotale.progetto__pk}`}>
                {subtotale.progetto__titolo}
              </LangLinkWithStyle>
            </Td>
            <Td>{numberFormatter.format(subtotale.totale_rendicontazione)}{' '}
              {subtotale.progetto__valuta_rendicontazione__codice}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
