import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import createStringLink from '../../utils'
import { useCreateSaldoCertificato } from '../../hooks/saldi-certificati'
import SaldoCertficatoForm from './SaldoCertificatoForm'

export default function SaldoCertificatoCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createSaldoCertificato = useCreateSaldoCertificato()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <SaldoCertficatoForm
        save={async (saldo) => {
          const savedSaldo = await createSaldoCertificato.mutateAsync(saldo)
          navigate(
            createStringLink({
              path: `/certified-balances/${savedSaldo.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
