import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import AutoCompleteField from '../../../components/form/fields'
import { Progetto } from '../../../types'

interface Props {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto?: Progetto | undefined
}

export default function ModalAssociaBene({
  progetto,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    bene: null,
  }

  const AssociaBeneSchema = Yup.object().shape({
    bene: Yup.string().required(t('good_is_required') ?? ''),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={AssociaBeneSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('associate_good')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'bene'}
                    label={t('good') ?? ''}
                    url={'/api/beni/options/'}
                    value={values.bene}
                    params={{
                      progetto: 'da_associare',
                    }}
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.bene}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('associate')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
