import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import ModalDelete from '../../../components/ModalDelete'
import {
  useDeleteRipartizioneBudgetCollaborazione,
  useUpdateRipartizioneBudgetCollaborazione,
} from '../../../hooks/collaborazioni'
import { Collaborazione, RipartizioneCollaborazione } from '../../../types'
import { numberFormatter } from '../../../utils'
import ModalFormRipartizione from './ModalFormRipartizione'

interface TableProps {
  data: RipartizioneCollaborazione[] | undefined
  valuta: string | undefined
  collaborazione: Collaborazione | undefined
}

export default function RipartizioniTable({
  data,
  valuta,
  collaborazione,
}: TableProps) {
  const { t } = useTranslation()
  const deleteRipartizione = useDeleteRipartizioneBudgetCollaborazione()
  const updateRipartizione = useUpdateRipartizioneBudgetCollaborazione()
  const {
    isOpen: isOpenRipartizioneDelete,
    onOpen: onOpenRipartizioneDelete,
    onClose: onCloseRipartizioneDelete,
    onToggle: onToggleRipartizioneDelete,
  } = useDisclosure()

  const {
    isOpen: isOpenRipartizioneEdit,
    onOpen: onOpenRipartizioneEdit,
    onClose: onCloseRipartizioneEdit,
    onToggle: onToggleRipartizioneEdit,
  } = useDisclosure()

  const [ripartizioneState, setRipartizioneState] = useState<
    RipartizioneCollaborazione | undefined
  >(undefined)
  return (
    <>
      <Table variant={'pinc'} mt={3}>
        <Thead>
          <Tr>
            <Th>{t('budget_line')}</Th>
            <Th>{t('import')}</Th>
            <Th>{t('actions')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data!.map((ripartizione) => (
            <Tr padding={2} key={ripartizione.id}>
              <Td
                cursor={'pointer'}
                onClick={() => {
                  setRipartizioneState(ripartizione)
                  onOpenRipartizioneEdit()
                }}
              >
                <Text
                  color={'darkblue'}
                  textDecoration={'underline'}
                  _hover={{
                    opacity: 0.8,
                  }}
                  fontWeight={600}
                >
                  {ripartizione.codice}
                </Text>
              </Td>
              <Td>
                {numberFormatter.format(ripartizione.importo ?? 0)} {valuta}
              </Td>
              <Td>
                <MdDelete
                  onClick={() => {
                    setRipartizioneState(ripartizione)
                    onOpenRipartizioneDelete()
                  }}
                  cursor={'pointer'}
                  size={20}
                  fill={'red'}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ModalDelete
        isOpen={isOpenRipartizioneDelete}
        onClose={onCloseRipartizioneDelete}
        onToggle={onToggleRipartizioneDelete}
        onConfirm={async () => {
          if (ripartizioneState) {
            await deleteRipartizione.mutateAsync(ripartizioneState.id)
          }
          setRipartizioneState(undefined)
          onCloseRipartizioneDelete()
        }}
        title={t('delete_budget_line')}
        onOpen={onOpenRipartizioneDelete}
        nameItem={ripartizioneState?.codice ?? ''}
      />
      <ModalFormRipartizione
        isOpen={isOpenRipartizioneEdit}
        onClose={onCloseRipartizioneEdit}
        onConfirm={async (data) => {
          await updateRipartizione.mutateAsync(data).then(() => {
            onCloseRipartizioneEdit()
          })
        }}
        onOpen={onOpenRipartizioneEdit}
        onToggle={onToggleRipartizioneEdit}
        collaborazione={collaborazione}
        ripartizione={ripartizioneState}
      />
    </>
  )
}
