import { useAuthCallPromise, useAuthUser } from 'use-eazy-auth'
import axios from 'axios'

export function useDownloadFile(blank: boolean = false) {

  const { user } = useAuthUser()
  const lang = user?.selected_language || 'it'

  return useAuthCallPromise(
    (token: string) => (downloadUrl: string, filters: any) =>
      axios
        .post(
          `/api/download-token/`,
          { download_url: downloadUrl },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response: any) => {
          const { data } = response
          const finalParams = new URLSearchParams({
            ...filters,
            download_token: data.download_token,
            lang,
          })
          const url = new URL(`${data.download_url}?${finalParams.toString()}`)
          if (blank) {
            window.open(url.toString(), '_blank')
          } else {
            window.location.href = url.toString()
          }
        })
  )
}
