import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { AssociazioneBene } from '../../types'
import dayjs from 'dayjs'

interface AssociazioniProps {
  associazioni: AssociazioneBene[] | undefined
}

export default function AssociazioniBeneTable({
  associazioni,
}: AssociazioniProps) {
  const { t } = useTranslation()
  return (
    <Table variant={'pinc'} mt={3}>
      <Thead>
        <Tr>
          <Th>{t('project_from')}</Th>
          <Th>{t('project_to')}</Th>
          <Th>{t('association_date')}</Th>
          <Th>{t('residual_amount')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {associazioni?.map((associazione) => (
          <Tr padding={2} key={associazione.id}>
            <Td>
              {associazione.progetto_data_da &&
                associazione.progetto_data_da.codice}
            </Td>
            <Td>
              {associazione.progetto_data_a &&
                associazione.progetto_data_a.codice}
            </Td>
            <Td>
              {associazione.data_associazione &&
                dayjs(associazione.data_associazione).format('DD/MM/YYYY')}
            </Td>
            <Td>
              {associazione.importo_residuo}
              {' '}
              {associazione.progetto_data_a &&
                associazione.progetto_data_a.valuta_rendicontazione_data
                  ?.codice}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
