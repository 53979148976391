import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import BeniProgettoList from './BeniProgettoList'

export default function BeniProgetto() {
  return (
    <Routes>
      <Route index element={<BeniProgettoList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
