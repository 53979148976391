import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseTipoCambio,
  PaginatedDJResponse,
  TipoCambio,
} from '../types'
import { useDownloadFile } from './download'

export function useTipiCambio(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<TipoCambio>>(
    ['tipi-cambio', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/tipi-cambio/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteTipoCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/tipi-cambio/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['tipi-cambio'])
        client.invalidateQueries(['tipo-cambio'])
      },
    }
  )
}

export function useCreateTipoCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (cambio: BaseTipoCambio) =>
        axios
          .post(`/api/tipi-cambio/`, cambio, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as TipoCambio)
    ),
    {
      onSuccess(savedCambio) {
        client.invalidateQueries(['tipi-cambio'])
        client.setQueryData(['tipo-cambio', savedCambio.id], savedCambio)
      },
    }
  )
}

export function useUpdateTipoCambio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (cambio: TipoCambio) =>
        axios
          .put(`/api/tipi-cambio/${cambio.id}/`, cambio, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as TipoCambio)
    ),
    {
      onSuccess(savedCambio) {
        client.invalidateQueries(['tipi-cambio'])
        client.setQueryData(['tipo-cambio', savedCambio.id], savedCambio)
      },
    }
  )
}

export function useTipoCambio(id: number) {
  return useQuery<TipoCambio>(
    ['tipo-cambio', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/tipi-cambio/${id}/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as TipoCambio)
    )
  )
}

export function useTipiCambioDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/tipi-cambio/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
