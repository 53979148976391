import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneCambi } from '../../permissions'
import ValoreCambioDetail from './ValoreCambioDetail'
import ValoriCambioCreate from './ValoriCambioCreate'
import ValoriCambioEdit from './ValoriCambioEdit'
import ValoriCambioList from './ValoriCambioList'
import ValoriCambioCreateMultiple from './ValoriCambioCreateMultiple'

export default function ValoriCambio() {
  const userCanGestioneCambi = useCanGestioneCambi()
  return (
    <Routes>
      <Route index element={<ValoriCambioList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneCambi}>
            <ValoriCambioCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/create-multiple'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneCambi}>
            <ValoriCambioCreateMultiple />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<ValoreCambioDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneCambi}>
            <ValoriCambioEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
