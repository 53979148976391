import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateTipoCambio } from '../../hooks/tipi-cambio'
import createStringLink from '../../utils'
import TipoCambioForm from './TipoCambioForm'

export default function TipiCambioCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createTipoCambio = useCreateTipoCambio()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <TipoCambioForm
        save={async (tipo) => {
          const savedTipo = await createTipoCambio.mutateAsync(tipo)
          navigate(
            createStringLink({
              path: `/types-exchange/${savedTipo.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
