import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  Collaborazione,
  PaginatedDJResponse,
  RipartizioneCollaborazione,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

const makeCollaborazioneFormData = makeFormDataEncoder({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

export function useCollaborazioni(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Collaborazione>>(
    ['collaborazioni', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/collaborazioni/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/collaborazioni/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['collaborazioni'])
        client.invalidateQueries(['collaborazioni'])
      },
    }
  )
}

export function useCreateCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (collaborazione: Collaborazione) =>
        axios
          .post(
            `/api/collaborazioni/`,
            makeCollaborazioneFormData(collaborazione),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Collaborazione)
    ),
    {
      onSuccess(savedCollaborazione) {
        client.invalidateQueries(['collaborazioni'])
        client.setQueryData(
          ['collaborazioni', savedCollaborazione.id],
          savedCollaborazione
        )
      },
    }
  )
}

export function useUpdateCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (collaborazione: Collaborazione) =>
        axios
          .put(
            `/api/collaborazioni/${collaborazione.id}/`,
            makeCollaborazioneFormData(collaborazione),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Collaborazione)
    ),
    {
      onSuccess(savedCollaborazione) {
        client.invalidateQueries(['collaborazioni'])
        client.setQueryData(
          ['collaborazioni', savedCollaborazione.id],
          savedCollaborazione
        )
      },
    }
  )
}

export function useCopiaCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ id, progetto_destinazione }) =>
          axios
            .post(
              `/api/collaborazioni/${id}/copia/`,
              {
                progetto_destinazione,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Collaborazione)
    ),
    {
      onSuccess(savedCollaborazione) {
        client.invalidateQueries(['collaborazioni'])
        client.setQueryData(
          ['collaborazioni', savedCollaborazione.id],
          savedCollaborazione
        )
      },
    }
  )
}

export function useCollaborazione(id: number) {
  return useQuery<Collaborazione>(
    ['collaborazione', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/collaborazioni/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Collaborazione)
    ),
    {
      enabled: !!id,
    }
  )
}

export function useCollaborazioniDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/collaborazioni/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

export function useRipartizioniBudgetCollaborazione(
  params: Record<string, any> = {}
) {
  return useQuery(
    ['ripartizioni-budget'],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/ripartizioni-budget/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as RipartizioneCollaborazione[])
    ),
    {
      keepPreviousData: true,
      enabled: !!params.collaborazione,
      suspense: !!params.suspense,
    }
  )
}

export function useCreateRipartizioneBudgetCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (ripartizione: RipartizioneCollaborazione) =>
        axios
          .post(`/api/ripartizioni-budget/`, ripartizione, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as RipartizioneCollaborazione)
    ),
    {
      onSuccess(savedRipartizione) {
        client.invalidateQueries(['ripartizioni-budget'])
        client.setQueryData(
          ['ripartizione-budget', savedRipartizione.id],
          savedRipartizione
        )
      },
    }
  )
}

export function useUpdateRipartizioneBudgetCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (ripartizione: RipartizioneCollaborazione) =>
        axios
          .put(`/api/ripartizioni-budget/${ripartizione.id}/`, ripartizione, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as RipartizioneCollaborazione)
    ),
    {
      onSuccess(savedRipartizione) {
        client.invalidateQueries(['ripartizioni-budget'])
        client.setQueryData(
          ['ripartizione-budget', savedRipartizione.id],
          savedRipartizione
        )
      },
    }
  )
}

export function useDeleteRipartizioneBudgetCollaborazione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/ripartizioni-budget/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['ripartizioni-budget'])
        client.invalidateQueries(['ripartizione-budget'])
      },
    }
  )
}
