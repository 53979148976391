import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseContoBancario,
  ContoBancario,
  PaginatedDJResponse,
  Progetto,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

const makeContoBancarioFormData = makeFormDataEncoder({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

export function useContiBancari(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<ContoBancario>>(
    ['conti-bancari', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/conti-bancari/conti-list-with-totali/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteContoBancario() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/conti-bancari/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['conti-bancari'])
        client.invalidateQueries(['conto-bancario'])
      },
    }
  )
}

export function useCreateContoBancario() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (conto: BaseContoBancario) =>
        axios
          .post(`/api/conti-bancari/`, makeContoBancarioFormData(conto), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ContoBancario)
    ),
    {
      onSuccess(savedConto) {
        client.invalidateQueries(['conti-bancari'])
        client.setQueryData(['conto-bancario', savedConto.id], savedConto)
      },
    }
  )
}

export function useUpdateContoBancario() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (conto: ContoBancario) =>
        axios
          .put(
            `/api/conti-bancari/${conto.id}/`,
            makeContoBancarioFormData(conto),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as ContoBancario)
    ),
    {
      onSuccess(savedConto) {
        client.invalidateQueries(['conti-bancari'])
        client.setQueryData(['conto-bancario', savedConto.id], savedConto)
      },
    }
  )
}

export function useContoBancarioTotali(id: number, filters: any) {
  return useQuery<{
    totale_movimenti: number
    totale_movimenti_controllati: number
  }>(
    ['conto-bancario-totali', id, filters],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/conti-bancari/${id}/totali-controllo/?${new URLSearchParams(
                filters
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useContoBancario(id: number) {
  return useQuery<ContoBancario>(
    ['conto-bancario', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/conti-bancari/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as ContoBancario)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useProgettiConContoBancario(id: number) {
  return useQuery<Progetto[]>(
    ['progetti-conti-bancari', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/conti-bancari/${id}/progetti-with-conto/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Progetto[])
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useCreateSaldiCertificatiContoBancario() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ conto, data_saldo }) =>
          axios
            .post(`/api/conti-bancari/${conto}/create-saldi-certificati/`, { data_saldo }, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['conti-bancari'])
      },
    }
  )
}

export function useContiBancariDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/conti-bancari/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

const makeImportContiBancari = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useImportContiBancari() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => ({ importFile }) => {
      const formData = makeImportContiBancari({ import_file: importFile })
      return axios
        .post(`/api/conti-bancari/import-accounts/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['conti-bancari'])
      },
    }
  )
}
