import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useTipoRetribuzione, useUpdateTipoRetribuzione } from '../../hooks/tipi-retribuzione'
import createStringLink from '../../utils'
import TipoRetribuzioneForm from './TipoRetribuzioneForm'

export default function TipiRetribuzioneEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useTipoRetribuzione(+id!)
  const editTipoRetribuzione = useUpdateTipoRetribuzione()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <TipoRetribuzioneForm
        tipo={data}
        save={async (tipo) => {
          const savedTipo = await editTipoRetribuzione.mutateAsync(tipo)
          navigate(
            createStringLink({
              path: `/renumerations/${savedTipo.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
