import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import {
  useCollaboratori,
  useCollaboratoriDownload,
  useImportCollaboratori,
} from '../../hooks/collaboratori'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import createStringLink from '../../utils'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import {  useCanInsertCollaboratori } from '../../permissions'
import ModalImportCollaboratori from './ModalImportCollaboratori'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '-num_collaborazioni',
})

export default function CollaboratoriList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useCollaboratori(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('employees'),
      },
    ]
  }, [t])

  const downloadWithParams = useCollaboratoriDownload(filters)
  const userCanInsertCollaboratori = useCanInsertCollaboratori()

  const importCollaboratori = useImportCollaboratori()

  const {
    isOpen: isOpenImportCollaboratori,
    onOpen: onOpenImportCollaboratori,
    onClose: onCloseImportCollaboratori,
    onToggle: onToggleImportCollaboratori,
  } = useDisclosure()

  const [isLoadingImport, setIsLoadingImport] = useState(false)

  const [errors, setErrors] = useState(null)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanInsertCollaboratori && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'darkblue'}
                  border={'1px solid'}
                  fontWeight={500}
                  _hover={{ bg: 'brand', color: 'white' }}
                  onClick={() => {
                    onOpenImportCollaboratori()
                  }}
                >
                  {t('import_employees')}
                </Button>
              )}
              {userCanInsertCollaboratori && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: '/employees/create',
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'progressivo', label: t('progressive') },
              {
                value: 'nome',
                label: t('name'),
              },
              {
                value: 'telefono',
                label: t('phone'),
              },
              {
                value: 'email',
                label: t('email'),
              },
              {
                value: 'paese_intervento',
                label: t('country_intervention'),
              },
              {
                value: 'num_collaborazioni',
                label: t('number_collaborations'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('progressive')}</Th>
                      <Th>{t('name')}</Th>
                      <Th>{t('phone')}</Th>
                      <Th>{t('email')}</Th>
                      <Th>{t('country_intervention')}</Th>
                      <Th>{t('number_collaborations')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((collaboratore) => (
                      <Tr padding={2} key={collaboratore.id}>
                        <Td>{collaboratore.progressivo}</Td>
                        <Td>
                          <LangLinkWithStyle
                            to={`/employees/${collaboratore.id}`}
                          >
                            {collaboratore.nome}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{collaboratore.telefono}</Td>
                        <Td>{collaboratore.email}</Td>
                        <Td>{collaboratore.paese_intervento_data?.nome}</Td>
                        <Td>{collaboratore.num_collaborazioni}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      <ModalImportCollaboratori
        isOpen={isOpenImportCollaboratori}
        onClose={onCloseImportCollaboratori}
        onOpen={onOpenImportCollaboratori}
        errors={errors}
        onToggle={onToggleImportCollaboratori}
        isLoading={isLoadingImport}
        onConfirm={async (values) => {
          setIsLoadingImport(true)
          await importCollaboratori
            .mutateAsync({
              importFile: values.import_file,
            })
            .then(() => {
              setIsLoadingImport(false)
              onCloseImportCollaboratori()
            })
            .catch((err) => {
              setErrors(err.response.data)
            })
        }}
      />
    </Box>
  )
}
