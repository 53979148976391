import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCheck, MdClose } from 'react-icons/md'
import { useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import {
  usePermessiUtenteProgetto,
  useProgettoSimple,
} from '../../hooks/progetti'
import { useCanGestioneUtenti } from '../../permissions'
import ModalAssociaUtenteProgetto from './ModalAssociaUtenteProgetto'
import { useAssociaProgettoAUtente } from '../../hooks/users'

export default function PermessiProgetto() {
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const { t } = useTranslation()
  const { data: permessiProgetto } = usePermessiUtenteProgetto(Number(id!))

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('permissions'),
      },
    ]
  }, [t, progetto, id])

  const userCanGestioneUtenti = useCanGestioneUtenti()

  const {
    isOpen: isOpenNewUserModal,
    onOpen: onOpenNewUserModal,
    onClose: onCloseNewUserModal,
    onToggle: onToggleNewUserModal,
  } = useDisclosure()

  const associaProgetto = useAssociaProgettoAUtente()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneUtenti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    onOpenNewUserModal()
                  }}
                >
                  {t('associate_user')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <Table variant={'pinc'} width={'100%'}>
            <Thead py={2}>
              <Tr>
                <Th>{t('user')}</Th>
                <Th>{t('manage_data')}</Th>
                <Th>{t('manage_suppliers')}</Th>
                <Th>{t('manage_goods')}</Th>
                <Th>{t('manage_accounts')}</Th>
                <Th>{t('insert_movements')}</Th>
                <Th>{t('edit_movements')}</Th>
                <Th>{t('insert_edit_budget')}</Th>
                <Th>{t('insert_edit_exchanges')}</Th>
                <Th>{t('only_reason_collaborators_expenses')}</Th>
                <Th>{t('only_causal_loco_expenses')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {permessiProgetto?.map((permesso) => (
                <Tr key={permesso.utente.id}>
                  <Td>
                    <LangLinkWithStyle
                      to={'/users/' + permesso.utente.id + '/edit'}
                    >
                      {permesso.utente.name}
                    </LangLinkWithStyle>
                  </Td>
                  <Td align="center">
                    {permesso.gestione_anagrafiche ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.gestione_fornitori ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.gestione_beni ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.gestione_conti ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.inserimento_movimenti ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.modifica_movimenti ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.inserimento_modifica_budget ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.inserimento_modifica_cambi ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.causale_spese_personale ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                  <Td align="center">
                    {permesso.causale_spese_loco ? (
                      <MdCheck size={20} color="green" />
                    ) : (
                      <MdClose size={20} color="red" />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
      {isOpenNewUserModal && (
        <ModalAssociaUtenteProgetto
          isOpen={isOpenNewUserModal}
          onClose={onCloseNewUserModal}
          progetto={progetto}
          onConfirm={async (values: any) => {
            await associaProgetto.mutateAsync({
              utente: values.user,
              progetto: id,
            })

            onCloseNewUserModal()
          }}
          onToggle={onToggleNewUserModal}
          onOpen={onOpenNewUserModal}
        />
      )}
    </Box>
  )
}
