import { Fragment, ReactNode, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Collapse,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'
import {
  MdArticle,
  MdFolder,
  MdHome,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdLocationPin,
  MdMonetizationOn,
  MdPeople,
  MdSettings,
} from 'react-icons/md'
import { IconType } from 'react-icons'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from './assets/logo.svg'
import { useTranslation } from 'react-i18next'
import LangLink from './LangLink'
import { Link } from 'react-router-dom'

interface ItemProps {
  name: string
  link: string
}
interface LinkItemProps {
  name: string
  icon: IconType
  link?: string
  items?: Array<ItemProps>
}

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={'block'} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={60} background={'#f8f8f8'} p="4">
        {children}
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const [menuOpen, setMenuOpen] = useState<string | null>(null)
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { pathname } = useLocation()

  const LinkItems: Array<LinkItemProps> = useMemo(() => {
    return [
      { name: t('projects'), icon: MdFolder, link: '/projects' },
      {
        name: t('personal_data'),
        icon: MdArticle,
        items: [
          {
            name: t('entities'),
            link: '/entities',
          },
          {
            name: t('employees'),
            link: '/employees',
          },
          {
            name: t('contacts'),
            link: '/contacts',
          },
          {
            name: t('suppliers'),
            link: '/suppliers',
          },
          {
            name: t('donors'),
            link: '/donors',
          },
          {
            name: t('goods'),
            link: '/goods',
          },
        ],
      },
      {
        name: t('accounting'),
        icon: MdMonetizationOn,
        items: [
          {
            name: t('accounts'),
            link: '/accounts',
          },
          {
            name: t('movements'),
            link: '/movements',
          },
          {
            name: t('exchange_rate_values'),
            link: '/exchange-rate-values',
          },
          {
            name: t('certified_balances'),
            link: '/certified-balances',
          },
        ],
      },
      {
        name: t('configuration'),
        icon: MdSettings,
        items: [
          {
            name: t('currencies'),
            link: '/currencies',
          },
          {
            name: t('renumerations'),
            link: '/renumerations',
          },
          {
            name: t('methods_of_payment'),
            link: '/methods-of-payment',
          },
          {
            name: t('types_of_exchange'),
            link: '/types-exchange',
          },
          {
            name: t('expense_items'),
            link: '/expense-items',
          },
          {
            name: t('intervention_areas'),
            link: '/intervention-areas',
          },
          {
            name: t('sectors_of_intervention'),
            link: '/sectors-of-intervention',
          },
          {
            name: t('duty_stations'),
            link: '/duty-stations',
          },
        ],
      },
      {
        name: t('countries'),
        icon: MdLocationPin,
        link: '/countries-of-intervention',
      },
      { name: t('users'), icon: MdPeople, link: '/users' },
    ]
  }, [t])

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      w={60}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="67"
        background={'brand'}
        alignItems="center"
        px={4}
        py={6}
        borderEndStartRadius={8}
        justifyContent="space-between"
      >
        <LangLink to="/">
          <MdHome size={30} color={'white'} />
        </LangLink>
        <Logo width={'100%'} />
      </Flex>
      <Box paddingTop={8}>
        {LinkItems.map((link, i) => {
          return (
            <Fragment key={i}>
              <Flex
                width={'100%'}
                pe={!link.link ? 5 : 0}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {link.link ? (
                  <Link style={{
                    width: 200
                  }} to={
                    `/` + i18n.language + link.link
                  }>
                    <NavItem
                      width={'100%'}
                      bg={
                        link.link && pathname.indexOf(link.link) > -1
                          ? 'tertiary'
                          : 'transparent'
                      }
                      color={
                        link.link && pathname.indexOf(link.link) > -1
                          ? 'white'
                          : 'secondary'
                      }
                      mt={2}
                      icon={link.icon}
                    >
                      {link.name}
                    </NavItem>
                  </Link>
                )
                : (
                <NavItem
                  width={'100%'}
                  onClick={() => {
                    if (link.link) {
                      setMenuOpen(null)
                      navigate(i18n.language + link.link)
                    } else {
                      menuOpen === link.name
                        ? setMenuOpen(null)
                        : setMenuOpen(link.name)
                    }
                  }}
                  bg={
                    link.link && pathname.indexOf(link.link) > -1
                      ? 'tertiary'
                      : 'transparent'
                  }
                  color={
                    link.link && pathname.indexOf(link.link) > -1
                      ? 'white'
                      : 'secondary'
                  }
                  mt={2}
                  icon={link.icon}
                >
                  {link.name}
                </NavItem>
              )}
                {!link.link && (
                  <Box
                    pt={2}
                    cursor={'pointer'}
                    onClick={() => {
                      if (link.link) {
                        setMenuOpen(null)
                        navigate(i18n.language + link.link)
                      } else {
                        menuOpen === link.name
                          ? setMenuOpen(null)
                          : setMenuOpen(link.name)
                      }
                    }}
                  >
                    {menuOpen === link.name ? (
                      <MdKeyboardArrowUp />
                    ) : (
                      <MdKeyboardArrowDown />
                    )}
                  </Box>
                )}
              </Flex>
              <Collapse in={menuOpen === link.name} animateOpacity>
                <Box>
                  {link.items &&
                    link.items.length > 0 &&
                    link.items.map((item, i) => (
                      <LangLink lang={i18n.language} key={i} to={item.link}>
                        <Flex
                          key={item.name}
                          mt={2}
                          py={1}
                          backgroundColor={
                            pathname.indexOf(item.link) > -1 &&
                            pathname.indexOf('/projects') === -1
                              ? 'tertiary'
                              : 'card'
                          }
                          mx={5}
                          borderRadius={8}
                          justifyContent={'start'}
                          fontWeight={600}
                          color={
                            pathname.indexOf(item.link) > -1 &&
                            pathname.indexOf('/projects') === -1
                              ? 'white'
                              : 'secondary'
                          }
                        >
                          <Box ps={4}>{item.name}</Box>
                        </Flex>
                      </LangLink>
                    ))}
                </Box>
              </Collapse>
            </Fragment>
          )
        })}
      </Box>
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  children: string
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      fontSize={16}
      py={1}
      px={2}
      fontWeight={600}
      color={'secondary'}
      borderRadius="lg"
      role="group"
      cursor="pointer"
      {...rest}
    >
      {icon && <Icon mr="2.5" fontSize="20" as={icon} />}
      {children}
    </Flex>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { user } = useAuthUser()
  const { logout } = useAuthActions()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  return (
    <Flex
      ml={60}
      px={4}
      position={'fixed'}
      right={0}
      left={0}
      py={6}
      zIndex={1}
      height="67"
      alignItems="center"
      //   bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="0px"
      borderBottomRightRadius={8}
      backgroundColor={'brand'}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      {/* <ScrollToTop /> */}
      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Flex
                  fontSize={16}
                  fontWeight={600}
                  marginRight={2}
                  color={'white'}
                >
                  {user.full_name}
                </Flex>
                <Avatar bg={'tertiary'} stroke={'brand'} size={'xs'} />
                <Box color={'white'} display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem
                onClick={() => {
                  navigate(i18n.language + '/profile')
                }}
              >
                {t('profile')}
              </MenuItem>
              <MenuItem onClick={() => logout()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}
