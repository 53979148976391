import { Box, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import ValoreCambioMultipleForm from './ValoreCambioMultipleForm'
import { useCreateMultipleValoreCambio } from '../../hooks/valori-cambio'
import createStringLink from '../../utils'

export default function ValoriCambioCreateMultiple() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const createMultipleValoriCambio = useCreateMultipleValoreCambio()

  const toast = useToast()

  const { t } = useTranslation()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <ValoreCambioMultipleForm
        save={async (cambi) => {
          await createMultipleValoriCambio.mutateAsync(cambi)
          toast({
            title: t('create_multiple'),
            description:
              t('you_have_created') +
              ' ' +
              cambi.cambi.length +
              ' ' +
              t('exchange_rate_values'),
            status: 'success',
            duration: 10000,
            isClosable: true,
          })
          navigate(
            createStringLink({
              path: `/exchange-rate-values/`,
              lang: i18n.language,
            })
          )
        }}
      />

      {/* <ConnectedUsers topic={'movimenti-edit-' + id} /> */}
    </Box>
  )
}
