import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import AutoCompleteField from '../../components/form/fields'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: any) => void
  fornitoriToUnify?: number[] | undefined
}

export default function ModalUnifyFornitori({
  isOpen,
  onClose,
  onConfirm,
  fornitoriToUnify = [],
}: Props) {
  const { t } = useTranslation()

  const FornitoreSchema = Yup.object().shape({
    fornitore: Yup.string().required().label(t('supplier')),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      validationSchema={FornitoreSchema}
      initialValues={{
        fornitore: undefined,
        fornitori_to_remove: fornitoriToUnify,
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{t('unify_suppliers')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    <AutoCompleteField
                      name="fornitori_to_remove"
                      label={t('suppliers_to_remove') ?? ''}
                      url={'/api/fornitori/options/'}
                      keyQuery={'fornitori-options'}
                      isMulti
                      isRequired
                      value={values.fornitori_to_remove}
                    />
                  </Box>
                  <Box mt={2}>
                    <AutoCompleteField
                      name={'fornitore'}
                      label={t('supplier_to_keep') ?? ''}
                      url={'/api/fornitori/options/'}
                      keyQuery={'fornitori-options-unify'}
                      isRequired
                      params={{
                        ids: values.fornitori_to_remove.join(','),
                      }}
                      value={values.fornitore}
                    />
                  </Box>
                </ModalBody>
                <ModalFooter display={'flex'} justifyContent={'space-between'}>
                  <Button
                    color={'brand'}
                    _hover={{
                      bg: 'brand',
                      color: 'white',
                    }}
                    bg={'brandLight'}
                    mr={3}
                    onClick={onClose}
                  >
                    {t('close')}
                  </Button>
                  <Button
                    type="submit"
                    bg={'brand'}
                    isDisabled={!values.fornitore}
                    _hover={{
                      bg: 'brandSecondary',
                    }}
                    color={'white'}
                  >
                    {t('unify_suppliers')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          </Modal>
        )
      }}
    </Formik>
  )
}
