import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import ModalDelete from '../../components/ModalDelete'
import { useRemoveDonatoreProgetto } from '../../hooks/progetti'
import { Donatore, PaginatedDJResponse } from '../../types'

interface Props {
  data: PaginatedDJResponse<Donatore> | undefined
  showDelete?: boolean
  progetto?: number
}

export default function DonatoriTable({ data, showDelete = false, progetto }: Props) {
  const { t } = useTranslation()
  const [donatoreToDelete, setDonatoreToDelete] = useState<Donatore>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const deleteDonatoreProgetto = useRemoveDonatoreProgetto()
  return (
    <>
      <Table variant={'pinc'}>
        <Thead position={'sticky'} top={0}>
          <Tr>
            <Th>{t('generality')}</Th>
            <Th>{t('progressive')}</Th>
            <Th>{t('site')}</Th>
            <Th>{t('email')}</Th>
            {showDelete && <Th>{t('actions')}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data!.results.map((donatore) => (
            <Tr padding={2} key={donatore.id}>
              <Td>
                <LangLinkWithStyle to={`/donors/${donatore.id}`}>
                  {donatore.generalita}
                </LangLinkWithStyle>
              </Td>
              <Td>{donatore.progressivo}</Td>
              <Td>{donatore.sede}</Td>
              <Td>{donatore.mail}</Td>
              {showDelete && (
                <Td>
                  <MdDelete
                    onClick={() => {
                      setDonatoreToDelete(donatore)
                      onOpen()
                    }}
                    cursor={'pointer'}
                    size={20}
                    fill={'red'}
                  />
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteDonatoreProgetto.mutateAsync({
            progetto: progetto!,
            donatore: donatoreToDelete!.id,
          })
          onClose()
        }}
        title={t('delete_donor_from_project')}
        nameItem={donatoreToDelete?.generalita}
        onOpen={onOpen}
        onToggle={onClose}
      />
    </>
  )
}
