import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import {
  useAreaIntervento,
  useUpdateAreaIntervento,
} from '../../hooks/aree-intervento'
import createStringLink from '../../utils'
import AreaInterventoForm from './AreaInterventoForm'

export default function AreeInterventoEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useAreaIntervento(+id!)
  const editAreaIntervento = useUpdateAreaIntervento()
  

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <AreaInterventoForm
        area={data}
        save={async (area) => {
          const savedPaese = await editAreaIntervento.mutateAsync(area)
          navigate(
            createStringLink({
              path: `/intervention-areas/${savedPaese.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
