import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  PaginatedDJResponse,
  TipoRetribuzione,
} from '../types'
import { useDownloadFile } from './download'

export function useTipiRetribuzione(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<TipoRetribuzione>>(
    ['tipi-retribuzione', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/tipi-retribuzione/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      suspense: params.suspense ?? true,
    }
  )
}

export function useDeleteTipoRetribuzione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/tipi-retribuzione/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['tipi-retribuzione'])
        client.invalidateQueries(['tipo-retribuzione'])
      },
    }
  )
}

export function useCreateTipoRetribuzione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (tipo: TipoRetribuzione) =>
        axios
          .post(`/api/tipi-retribuzione/`, tipo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as TipoRetribuzione)
    ),
    {
      onSuccess(savedTipo) {
        client.invalidateQueries(['tipi-retribuzione'])
        client.setQueryData(['tipo-retribuzione', savedTipo.id], savedTipo)
      },
    }
  )
}

export function useUpdateTipoRetribuzione() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (tipo: TipoRetribuzione) =>
        axios
          .put(`/api/tipi-retribuzione/${tipo.id}/`, tipo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as TipoRetribuzione)
    ),
    {
      onSuccess(savedTipo) {
        client.invalidateQueries(['tipi-retribuzione'])
        client.setQueryData(['tipo-retribuzione', savedTipo.id], savedTipo)
      },
    }
  )
}

export function useTipoRetribuzione(id: number) {
    return useQuery<TipoRetribuzione>(
      ['tipo-retribuzione', id],
      useAuthCallPromise(
        (token: string) =>
          ({ signal }) =>
            axios
              .get(`/api/tipi-retribuzione/${id}/`, {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((r) => r.data)
      ),
      {
        keepPreviousData: true,
      }
    )
  }

export function useTipiRetribuzioneDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/tipi-retribuzione/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
