import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import LangLink from './LangLink'

interface BreadCrumbItemProps {
  label: string
  link?: string
}

interface BreadCrumbProps {
  items: Array<BreadCrumbItemProps>
}

export default function BreadCrumb({ items }: BreadCrumbProps) {
  const { i18n } = useTranslation()
  return (
    <Flex width={'max-content'}>
      {items.map((item, index) => (
        <Flex alignItems={'center'} key={index}>
          {item.link ? (
            <LangLink lang={i18n.language} to={item.link}>
              <Text
                color={'secondary'}
                fontSize={14}
                fontWeight={400}
                textTransform={'uppercase'}
                textDecoration={'underline'}
              >
                {item.label}
              </Text>
            </LangLink>
          ) : (
            <Text
              fontWeight={600}
              fontSize={14}
              textTransform={'uppercase'}
              color={'darkblue'}
            >
              {item.label}
            </Text>
          )}
          {index < items.length - 1 && (
            <Text color={'secondary'} fontSize={14} fontWeight={400} mx={2}>
              /
            </Text>
          )}
        </Flex>
      ))}
    </Flex>
  )
}
