import {
  Badge,
  Box,
  Checkbox,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { BsFilePdfFill } from 'react-icons/bs'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import { OPTIONS_CAUSALI } from '../../consts'
import {
  useCopiaMovimento,
  useMovimentoPdfDownload,
} from '../../hooks/movimenti'
import { Movimento, PaginatedDJResponse } from '../../types'
import createStringLink, { numberFormatter } from '../../utils'
import { FaCheck } from 'react-icons/fa'
import { MdAttachment, MdClose } from 'react-icons/md'
import { BiCheck, BiX } from 'react-icons/bi'
import { FiCopy } from 'react-icons/fi'
import ModalCopiaMovimento from '../Progetti/MovimentiProgetto/ModalCopiaMovimento'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import {
  useCanEditMultipleMovimenti,
  useCanGestioneCambi,
} from '../../permissions'

interface Props {
  data: PaginatedDJResponse<Movimento> | undefined
  showProgetto?: boolean
  filtersOpen?: boolean
  isRefetching?: boolean
  movimentiForEdit?: number[]
  sticky?: boolean
  setMovimentiForEdit?: (movimenti: number[]) => void
}

function PdfMovimento(movimento: Movimento) {
  const downloadWithParams = useMovimentoPdfDownload(movimento.id)
  return (
    <Link
      isExternal
      pt={1}
      as={'button'}
      onClick={() => downloadWithParams()}
      target={'_blank'}
    >
      <BsFilePdfFill fill="orange" size={14} cursor={'pointer'} />
    </Link>
  )
}

export default function MovimentiTable({
  data,
  showProgetto = true,
  filtersOpen = false,
  isRefetching = false,
  movimentiForEdit = [],
  setMovimentiForEdit = () => {},
  sticky = true,
}: Props) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [movimentoForCopy, setMovimentoForCopy] = useState<
    Movimento | undefined
  >(undefined)
  const {
    isOpen: isOpenCopia,
    onClose: onCloseCopia,
    onOpen: onOpenCopia,
    onToggle: onToggleCopia,
  } = useDisclosure()

  const toast = useToast()

  const copiaMovimento = useCopiaMovimento()

  const userCanGestioneCambi = useCanGestioneCambi()
  const userCanEditMultipleMovimenti = useCanEditMultipleMovimenti()

  return (
    <Box
      mt={2}
      height={
        sticky
          ? filtersOpen
            ? 'calc(100vh - 475px)'
            : 'calc(100vh - 240px)'
          : 'auto'
      }
      overflowY={'auto'}
      width={'100%'}
      bg={'white'}
      boxShadow={
        '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }
    >
      <Table variant={'pinc'} bg={'white'}>
        <Thead
          position={sticky ? 'sticky' : 'relative'}
          top={0}
          zIndex={sticky ? 2 : 0}
        >
          <Tr>
            {!showProgetto && userCanEditMultipleMovimenti && (
              <Th>
                <Checkbox
                  width={5}
                  height={5}
                  size={'md'}
                  colorScheme={'orange'}
                  borderColor={'brandLight'}
                  isChecked={movimentiForEdit.length === data?.results.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMovimentiForEdit(
                        data?.results.map((movimento) => movimento.id) ?? []
                      )
                    } else {
                      setMovimentiForEdit([])
                    }
                  }}
                />
              </Th>
            )}
            {showProgetto && <Th>{t('project')}</Th>}
            <Th>{t('prog')}</Th>
            {!showProgetto && <Th>{t('draft')}</Th>}
            <Th>{t('date_document')}</Th>
            <Th>{t('date_competence')}</Th>
            <Th>{t('transaction_type')}</Th>
            <Th>{t('description')}</Th>
            <Th>{t('budget')}</Th>
            <Th>{t('amount')}</Th>
            <Th>{t('amount_rendicontation')}</Th>
            <Th>{<MdAttachment width={15} />}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isRefetching ? (
            <Tr>
              <Td colSpan={11}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={'calc(100% - 77px)'}
                  pt={'100px'}
                >
                  <Spinner size={'sm'} color="brand" />
                </Box>
              </Td>
            </Tr>
          ) : (
            data!.results.map((movimento) => (
              <Tr padding={2} key={movimento.id}>
                {!showProgetto && userCanEditMultipleMovimenti && (
                  <Td>
                    <Checkbox
                      width={5}
                      height={5}
                      size={'md'}
                      colorScheme={'orange'}
                      borderColor={'brandLight'}
                      isChecked={movimentiForEdit.includes(movimento.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMovimentiForEdit([
                            ...movimentiForEdit,
                            movimento.id,
                          ])
                        } else {
                          setMovimentiForEdit(
                            movimentiForEdit.filter((id) => id !== movimento.id)
                          )
                        }
                      }}
                    />
                  </Td>
                )}
                {showProgetto && (
                  <Td>
                    <LangLinkWithStyle to={`/projects/${movimento.progetto}/`}>
                      {movimento.progetto_data?.codice}
                    </LangLinkWithStyle>
                  </Td>
                )}
                <Td>
                  <Box display={'flex'} alignItems={'center'}>
                    <LangLinkWithStyle
                      to={`/projects/${movimento.progetto}/movements/${movimento.id}`}
                    >
                      {movimento.progressivo ?? t('draft')}
                    </LangLinkWithStyle>
                    <Box ms={2}>{movimento.duty_station_data?.nome}</Box>
                    <Box ms={3}>
                      <PdfMovimento {...movimento} />
                    </Box>
                    <Box ms={2}>
                      <FiCopy
                        onClick={() => {
                          setMovimentoForCopy(movimento)
                          onOpenCopia()
                        }}
                        cursor={'pointer'}
                        title={t('copy') ?? ''}
                      />
                    </Box>
                  </Box>
                </Td>
                {!showProgetto && (
                  <Td align={'center'}>
                    {movimento.bozza ? (
                      <BiCheck size={20} color="green" />
                    ) : (
                      <BiX size={20} color="red" />
                    )}
                  </Td>
                )}
                <Td>{dayjs(movimento.data_documento).format('DD/MM/YYYY')}</Td>
                <Td>{dayjs(movimento.data_competenza).format('DD/MM/YYYY')}</Td>
                <Td>
                  {t(
                    OPTIONS_CAUSALI.find(
                      (causale) => causale.value === movimento.causale
                    )?.label ?? ''
                  )}
                  {movimento.causale === 'anticipo-cassa' && (
                    <Box mt={1}>
                      {movimento.anticipo_chiuso ? (
                        <Badge colorScheme={'green'}>{t('Closed')}</Badge>
                      ) : (
                        <Badge colorScheme={'red'}>{t('Open')}</Badge>
                      )}
                    </Box>
                  )}
                  {movimento.causale === 'anticipo-cassa' &&
                    movimento.residuo_anticipo > 0 &&
                    !movimento.anticipo_chiuso && (
                      <Box mt={1}>
                        {movimento.residuo_anticipo &&
                          !movimento.anticipo_chiuso && (
                            <Badge colorScheme={'orange'}>
                              {numberFormatter.format(
                                movimento.residuo_anticipo
                              )}{' '}
                              {movimento.valuta_data?.codice}
                            </Badge>
                          )}
                      </Box>
                    )}
                  {movimento.movimento_anticipo_progressivo && (
                    <Box>
                      ({t('advance')}:{' '}
                      <Link
                        as={LangLinkWithStyle}
                        to={`/projects/${movimento.progetto}/movements/${movimento.movimento_anticipo_pk}`}
                      >
                        {movimento?.movimento_anticipo_progressivo})
                      </Link>
                    </Box>
                  )}
                </Td>
                <Td className="text-wrap">{movimento.descrizione}</Td>
                <Td>
                  {movimento.budget_data?.codice}{' '}
                  {movimento.budget_data?.codice_paese
                    ? `- ${movimento.budget_data?.codice_paese}`
                    : ''}
                  {movimento.budget_data?.codice_piano_conti 
                    ? ` - ${movimento.budget_data?.codice_piano_conti}`
                    : ''}
                </Td>
                <Td textAlign={'right'}>
                  <Box color={'brand'} fontWeight={700}>
                    {numberFormatter.format(movimento.importo ?? 0)}
                  </Box>
                  {movimento.valuta_data?.codice}
                </Td>
                <Td textAlign={'right'}>
                  {movimento.importo_rendicontazione ? (
                    <>
                      <Box color={'brand'} fontWeight={700}>
                        {numberFormatter.format(
                          movimento.importo_rendicontazione ?? 0
                        )}
                      </Box>
                      {
                        movimento.progetto_data?.valuta_rendicontazione_data
                          ?.codice
                      }
                      <Box
                        fontSize={10}
                        color={'gray.500'}
                        fontWeight={500}
                        mt={1}
                      >
                        {movimento.valore_cambio_utilizzato}
                      </Box>
                    </>
                  ) : (
                    <Box color={'brand'} fontWeight={700}>
                      {userCanGestioneCambi ? (
                        <LangLinkWithStyle
                          to={`/exchange-rate-values/create?valuta_da=${
                            movimento.valuta
                          }&valuta_a=${
                            movimento.progetto_data?.valuta_rendicontazione
                          }&tipo_cambio=${
                            movimento.progetto_data?.tipo_cambio
                          }&data_da=${dayjs(movimento.data_competenza).format(
                            'YYYY-MM-DD'
                          )}`}
                        >
                          {t('no_exchange_rate')}
                        </LangLinkWithStyle>
                      ) : (
                        t('no_exchange_rate')
                      )}
                    </Box>
                  )}
                </Td>
                <Td>
                  {movimento.has_allegato ? (
                    <FaCheck color={'green'} />
                  ) : (
                    <MdClose color={'red'} />
                  )}
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {movimentoForCopy && (
        <ModalCopiaMovimento
          movimento={movimentoForCopy}
          isOpen={isOpenCopia}
          onClose={onCloseCopia}
          onConfirm={async (values) => {
            await copiaMovimento
              .mutateAsync({
                id: movimentoForCopy.id,
                ...values,
              })
              .then((res) => {
                const numeroCopie = res
                onCloseCopia()
                toast({
                  title: t('copy_movement'),
                  description:
                    t('you_have_created') +
                    ' ' +
                    numeroCopie +
                    ' ' +
                    t('copies'),
                  status: 'success',
                  duration: 10000,
                  isClosable: true,
                })
                navigate(
                  createStringLink({
                    path: !showProgetto
                      ? `/projects/${movimentoForCopy.progetto}/movements`
                      : `/movements`,
                    lang: i18n.language,
                  })
                )
                setMovimentoForCopy(undefined)
              })
          }}
          onOpen={onOpenCopia}
          onToggle={onToggleCopia}
        />
      )}
    </Box>
  )
}
