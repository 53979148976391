import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useMovimento, useUpdateMovimento } from '../../../hooks/movimenti'
import createStringLink from '../../../utils'
import MovimentoForm from './MovimentoForm'
// import ConnectedUsers from '../../../components/ConnectedUsers'

export default function MovimentoEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id, movimento } = useParams()
  const { data } = useMovimento(+movimento!)
  const editMovimento = useUpdateMovimento()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <MovimentoForm
        movimento={data}
        save={async (movimento) => {
          await editMovimento.mutateAsync(movimento)
          navigate(
            createStringLink({
              path: `/projects/${id}/movements/`,
              lang: i18n.language,
            })
          )
        }}
      />
      {/* <ConnectedUsers topic={'movimenti-edit-'+id} /> */}
    </Box>
  )
}
