import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Link,
  Modal,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import ModalDelete from '../../components/ModalDelete'
import {
  useContoBancario,
  useCreateSaldiCertificatiContoBancario,
  useDeleteContoBancario,
  useProgettiConContoBancario,
} from '../../hooks/conti-bancari'
import { useCanGestioneConti } from '../../permissions'
import createStringLink, {
  getNameOfFileFromUrl,
  numberFormatter,
} from '../../utils'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import { MdSearch } from 'react-icons/md'
import { Formik } from 'formik'
import ModalSaldiCertificati from './ModalSaldiCertificati'

export default function ContoDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const { data } = useContoBancario(Number(id!))
  const deleteContoBancario = useDeleteContoBancario()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const {
    isOpen: isOpenSaldi,
    onClose: onCloseSaldi,
    onOpen: onOpenSaldi,
    onToggle: onToggleSaldi,
  } = useDisclosure()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('accounts'),
        link: '/accounts',
      },
      {
        label: data?.nome_banca ?? '',
      },
    ]
  }, [t, data])

  const userCanGestioneConti = useCanGestioneConti()
  const { data: progetti } = useProgettiConContoBancario(Number(id!))

  const createSaldiCertificati = useCreateSaldiCertificatiContoBancario()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneConti && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    onClick={() => {
                      onOpenSaldi()
                    }}
                    bg={'brand'}
                    _hover={{ bg: 'brandSecondary' }}
                    color={'white'}
                  >
                    {t('create_saldi_certificati')}
                  </Button>
                  <Button
                    size={'sm'}
                    me={4}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/accounts/create`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    bg={'brand'}
                    _hover={{ bg: 'brandSecondary' }}
                    color={'white'}
                  >
                    {t('new_account')}
                  </Button>
                  <Button
                    size={'sm'}
                    me={4}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/accounts/${id}/movements`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    border={'1px solid'}
                    borderColor={'brandBlue'}
                    color={'brandBlue'}
                    bg={'card'}
                    _hover={{
                      bg: 'brandBlue',
                      color: 'white',
                    }}
                  >
                    {t('control_movements')}
                  </Button>
                  {data?.can_delete && (
                    <Button
                      size={'sm'}
                      me={4}
                      _hover={{
                        bg: 'error',
                        color: 'white',
                        opacity: 0.8,
                      }}
                      onClick={onOpen}
                      bg={'error'}
                      color={'white'}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/accounts/${id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('payment_method')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.modalita_pagamento_data?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('name_bank')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.nome_banca}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('account_number')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.numero_conto}
                </Text>
              </GridItem>
            </Grid>
            {data?.paese_intervento_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('country')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {data?.paese_intervento_data?.nome}
                  </Text>
                </GridItem>
              </Grid>
            )}
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('currency')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.valuta_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('bank_headquarters')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.sede_banca}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('bank_phone')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.tel_banca}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('bank_contact')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.referente_banca}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('abi')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.abi}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('cab')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.cab}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('bic')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.bic}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('swift')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.swift}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('iban')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.iban}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('balance')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.saldo_estratto_conto} {data?.valuta_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('account_holder')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.titolare_conto}
                </Text>
              </GridItem>
            </Grid>
            {data?.data_chiusura && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('closing_date')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {dayjs(data?.data_chiusura).format('DD/MM/YYYY')}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {data?.allegato && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Link isExternal target={'_blank'} href={data?.allegato}>
                    <Text color={'brand'} fontWeight={700} fontSize={14}>
                      {getNameOfFileFromUrl(data?.allegato)}
                    </Text>
                  </Link>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_2 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_2')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Link isExternal target={'_blank'} href={data?.allegato_2}>
                    <Text color={'brand'} fontWeight={700} fontSize={14}>
                      {getNameOfFileFromUrl(data?.allegato_2)}
                    </Text>
                  </Link>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_3 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_3')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Link isExternal target={'_blank'} href={data?.allegato_3}>
                    <Text color={'brand'} fontWeight={700} fontSize={14}>
                      {getNameOfFileFromUrl(data?.allegato_3)}
                    </Text>
                  </Link>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_4 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_4')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Link isExternal target={'_blank'} href={data?.allegato_4}>
                    <Text color={'brand'} fontWeight={700} fontSize={14}>
                      {getNameOfFileFromUrl(data?.allegato_4)}
                    </Text>
                  </Link>
                </GridItem>
              </Grid>
            )}
            {data?.allegato_5 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_5')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Link isExternal target={'_blank'} href={data?.allegato_5}>
                    <Text color={'brand'} fontWeight={700} fontSize={14}>
                      {getNameOfFileFromUrl(data?.allegato_5)}
                    </Text>
                  </Link>
                </GridItem>
              </Grid>
            )}
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(data?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(data?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            {t('projects_associated')}
          </CardHeader>
          <CardBody>
            <Table variant={'pinc'}>
              <Thead position={'sticky'} top={0}>
                <Tr>
                  <Th>{t('code')}</Th>
                  <Th>{t('state')}</Th>
                  <Th>{t('code_donator')}</Th>
                  <Th>{t('date_start')}</Th>
                  <Th>{t('date_end')}</Th>
                  <Th>{t('country')}</Th>
                  <Th>{t('total_import')}</Th>
                  <Th>{t('main_entity')}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {progetti?.map((progetto) => (
                  <Tr padding={2} key={progetto.id}>
                    <Td>
                      <LangLinkWithStyle to={'/projects/' + progetto.id}>
                        {progetto.codice}
                      </LangLinkWithStyle>
                      {/* <Box maxWidth={210}>{progetto.titolo}</Box> */}
                    </Td>
                    <Td>
                      {progetto.closed ? (
                        <Box display={'flex'} alignItems={'center'}>
                          <Box
                            display={'inline-block'}
                            width={2}
                            height={2}
                            borderRadius={'50%'}
                            bg={'error'}
                            mr={2}
                          />
                          <Text
                            fontSize={12}
                            fontWeight={400}
                            textTransform={'capitalize'}
                            color={'darkblue'}
                          >
                            {t('closed')}{' '}
                          </Text>
                        </Box>
                      ) : (
                        <Box display={'flex'} alignItems={'center'}>
                          <Box
                            display={'inline-block'}
                            width={2}
                            height={2}
                            borderRadius={'50%'}
                            bg={'success'}
                            mr={2}
                          />
                          <Text
                            fontSize={12}
                            fontWeight={400}
                            textTransform={'capitalize'}
                            color={'darkblue'}
                          >
                            {t('open')}{' '}
                          </Text>
                        </Box>
                      )}
                      {progetto.data_blocco_compentenza && (
                        <Badge
                          size={'xs'}
                          px={2}
                          fontSize={10}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          colorScheme={'red'}
                          borderRadius={'md'}
                        >
                          {t('block_on_date')}{' '}
                          {dayjs(progetto.data_blocco_compentenza).format(
                            'DD/MM/YYYY'
                          )}
                        </Badge>
                      )}
                    </Td>
                    <Td>{progetto.codice_progetto_donatore}</Td>
                    <Td whiteSpace={'nowrap'}>
                      {progetto.data_inizio
                        ? dayjs(progetto.data_inizio).format('DD/MM/YYYY')
                        : ''}
                    </Td>
                    <Td whiteSpace={'nowrap'}>
                      {progetto.data_fine
                        ? dayjs(progetto.data_fine).format('DD/MM/YYYY')
                        : ''}
                    </Td>
                    <Td>
                      {progetto.paesi_intervento_data?.map((p) => (
                        <Box key={p.id}>{p.nome}</Box>
                      ))}
                    </Td>
                    <Td textAlign={'right'}>
                      <Box color={'brand'} fontWeight={700}>
                        {numberFormatter.format(progetto.importo_totale ?? 0)}
                      </Box>
                      {progetto.valuta_rendicontazione_data?.codice}
                    </Td>
                    <Td>{progetto.ente_principale_data?.nome}</Td>
                    <Td>
                      <Button
                        leftIcon={<MdSearch />}
                        border={'1px solid'}
                        borderColor={'brandBlue'}
                        color={'brandBlue'}
                        bg={'card'}
                        _hover={{
                          bg: 'brandBlue',
                          color: 'white',
                        }}
                        size={'xs'}
                        onClick={() => {
                          navigate(
                            createStringLink({
                              path: `/projects/${progetto.id}/accounts/${id}/movements`,
                              lang: i18n.language,
                            })
                          )
                        }}
                      >
                        {t('control_movements')}
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
      <ModalSaldiCertificati
        isOpen={isOpenSaldi}
        onClose={onCloseSaldi}
        onOpen={onOpenSaldi}
        onToggle={onToggleSaldi}
        onConfirm={async (values) => {
          await createSaldiCertificati
            .mutateAsync({
              conto: +id!,
              data_saldo: values.data_saldo,
            })
            .then(() => {
              onCloseSaldi()
            })
        }}
        conto={data}
      />

      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteContoBancario.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/accounts`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.nome_banca}
        title={t('delete_account')}
      />
    </Box>
  )
}
