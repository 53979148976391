import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useDonatore, useUpdateDonatore } from '../../hooks/donatori'
import createStringLink from '../../utils'
import DonatoreForm from './DonatoreForm'

export default function DonatoriEdit() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { data } = useDonatore(+id!)
  const editDonatore = useUpdateDonatore()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <DonatoreForm
        donatore={data}
        save={async (donatore) => {
          const savedDonatore = await editDonatore.mutateAsync(donatore)
          navigate(
            createStringLink({
              path: `/donors/${savedDonatore.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
