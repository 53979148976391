import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCreateReferente } from '../../../hooks/referenti'
import createStringLink from '../../../utils'
import ReferenteForm from './ReferenteForm'
// import ReferenteForm from './ReferenteForm'

export default function ReferenteCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const createReferente = useCreateReferente()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <ReferenteForm
        save={async (referente) => {
          const savedReferente = await createReferente.mutateAsync(referente)
          navigate(
            createStringLink({
              path: `/projects/${id}/contacts/${savedReferente.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
