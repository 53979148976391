import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import { useCanInsertBeni } from '../../permissions'
import BeneDetail from './BeneDetail'
import BeniCreate from './BeniCreate'
import BeniEdit from './BeniEdit'
import BeniList from './BeniList'

export default function Beni() {
  const userCanGestioneBeni = useCanInsertBeni()
  return (
    <Routes>
      <Route index element={<BeniList />} />
      {userCanGestioneBeni && (
        <Route path={'/create'} element={<BeniCreate />} />
      )}
      <Route path={'/:id'} element={<BeneDetail />} />
      {userCanGestioneBeni && (
        <Route path={'/:id/edit'} element={<BeniEdit />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
