import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneConti } from '../../permissions'
import ContiList from './ContiList'
import ContoCreate from './ContoCreate'
import ContoDetail from './ContoDetail'
import ContoEdit from './ContoEdit'
import MovimentoConto from './MovimentiConto'

export default function Conti() {
  const canGestioneConti = useCanGestioneConti()
  return (
    <Routes>
      <Route index element={<ContiList />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={canGestioneConti}>
            <ContoCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:id/edit"
        element={
          <ProtectedRoute permessionCheck={canGestioneConti}>
            <ContoEdit />
          </ProtectedRoute>
        }
      />
      <Route path="/:id" element={<ContoDetail />} />
      <Route path="/:id/movements" element={<MovimentoConto />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
