import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { CheckboxField, InputField } from '../../../components/form/fields'
import { Movimento } from '../../../types'
import { OPTIONS_CAUSALI } from '../../../consts'
import { numberFormatter } from '../../../utils'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  movimenti: number[] | undefined
}

export default function ModalCopiaMovimenti({
  movimenti,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    numero_copie: 1,
    bozza: false,
  }

  const AssociaFornitoreSchema = Yup.object().shape({
    numero_copie: Yup.string().required(t('number_of_copies') ?? ''),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={AssociaFornitoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t('copy_movements')} - </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <InputField
                    name="numero_copie"
                    label={t('number_of_copies') ?? ''}
                    type="number"
                    min={1}
                    max={100}
                    step={1}
                  />
                </Box>
                <Box mt={3}>
                  <CheckboxField name="bozza" label={t('draft') ?? ''} />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.numero_copie}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('copy')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
