import { Box, Button, ButtonGroup, Select, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { MdArrowCircleDown, MdArrowCircleUp } from 'react-icons/md'

interface Option {
  value: string
  label: string
}

interface OrderingBlockProps {
  value: string
  options: Array<Option>
  onChange: (value: string) => void
  noMargin?: boolean
}

export default function OrderingBlock({
  value,
  options,
  onChange,
  noMargin = false,
}: OrderingBlockProps) {
  const valueSelect = useMemo(() => {
    if (value.startsWith('-')) {
      return value.slice(1)
    }
    return value
  }, [value])

  return (
    <Box ms={
      noMargin ? 0 : 8
    } display={'flex'}>
      <Box display={'flex'} alignItems={'center'}>
        <Text color={'darkblue'} fontSize={14} fontWeight={500} me={2}>
          Order
        </Text>
        <Select
          size={'sm'}
          bg={'white'}
          fontSize={14}
          color={'brandBlue'}
          fontWeight={400}
          width={200}
          focusBorderColor={'transparent'}
          textTransform={'capitalize'}
          value={valueSelect}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Box>
      <Box ms={3}>
        <ButtonGroup isAttached spacing={0}>
          <Button
            size={'sm'}
            _hover={{
              opacity: 0.8,
            }}
            onClick={() => {
              value.startsWith('-')
                ? onChange(value.slice(1))
                : onChange(`-${value}`)
            }}
            bg={value.startsWith('-') ? 'tertiary' : 'darkblue'}
          >
            <MdArrowCircleUp size={20} color="white" />
          </Button>
          <Button
            size={'sm'}
            _hover={{
              opacity: 0.8,
            }}
            onClick={() => {
              value.startsWith('-')
                ? onChange(value.slice(1))
                : onChange(`-${value}`)
            }}
            bg={value.startsWith('-') ? 'darkblue' : 'tertiary'}
          >
            <MdArrowCircleDown size={20} color="white" />
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  )
}
