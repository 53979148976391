import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useDownloadFile } from './download'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  Bene,
  Fornitore,
  PaeseIntervento,
  PaeseInterventoOverview,
  PaginatedDJResponse,
} from '../types'

export function usePaesiIntervento(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<PaeseIntervento>>(
    ['paesi-intervento', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/paesi-intervento/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePaesiInterventoOverview(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<PaeseInterventoOverview>>(
    ['paesi-intervento-attivi', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/paesi-intervento/paesi-attivi/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeletePaeseIntervento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/paesi-intervento/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['paesi-intervento'])
        client.invalidateQueries(['paese-intervento'])
      },
    }
  )
}

export function useCreatePaeseIntervento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (paese: PaeseIntervento) =>
        axios
          .post(`/api/paesi-intervento/`, paese, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as PaeseIntervento)
    ),
    {
      onSuccess(savedPaeseIntervento) {
        client.invalidateQueries(['paesi-intervento'])
        client.setQueryData(
          ['paese-intervento', savedPaeseIntervento.id],
          savedPaeseIntervento
        )
      },
    }
  )
}

export function useUpdatePaeseIntervento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (paese: PaeseIntervento) =>
        axios
          .put(`/api/paesi-intervento/${paese.id}/`, paese, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as PaeseIntervento)
    ),
    {
      onSuccess(savedPaeseIntervento) {
        client.invalidateQueries(['paesi-intervento'])
        client.setQueryData(
          ['paese-intervento', savedPaeseIntervento.id],
          savedPaeseIntervento
        )
      },
    }
  )
}

export function usePaeseIntervento(id: number) {
  return useQuery<PaeseIntervento>(
    ['paese-intervento', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/paesi-intervento/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePaeseFornitori(
  id: number,
  params: Record<string, any> = {}
) {
  return useQuery<PaginatedDJResponse<Fornitore>>(
    ['paese-fornitori', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/paesi-intervento/${id}/fornitori/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePaeseBeni(id: number, params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Bene>>(
    ['paese-beni', id, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/paesi-intervento/${id}/beni-paese/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function usePaesiInterventoDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/paesi-intervento/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
