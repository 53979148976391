import { Select } from 'chakra-react-select'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOptions } from '../hooks/options'

export default function SelectAutoCompleteFilter({
  value,
  onChange,
  url = '/api/progetti/options/',
  keyQuery = 'id',
  isMulti = true,
  params = {},
}: {
  value: any
  onChange: any
  url?: string
  isMulti?: boolean
  keyQuery?: string
  params?: any
}) {
  const [search, setSearch] = useState<string>('')
  const [focus, setFocus] = useState<boolean>(false)
  const { t } = useTranslation()

  const valueToSend = useMemo(() => {
    if (!isMulti) {
      return value
    }
    if (!value || value.length === 0) {
      return []
    }
    const finalValue = value.join(',')
    return finalValue
  }, [value, isMulti])

  const { data: items, isFetching: isFetchingPrimary } = useOptions(
    { ...params, search, exclude_ids: valueToSend },
    !!search || focus,
    keyQuery,
    url
  )

  const paramsToSend = useMemo(() => {
    if (!isMulti) {
      return {
        id: valueToSend,
      }
    } else {
      return {
        ids: valueToSend,
      }
    }
  }, [valueToSend, isMulti])

  const { data: values, isFetching } = useOptions(
    paramsToSend,
    !!valueToSend || focus,
    keyQuery,
    url
  )

  const valueForSelect = useMemo(() => {
    if (!value || value.length === 0 || isFetching) {
      return []
    }
    return values?.map((v) => ({
      value: String(v.value),
      label: v.label,
    }))
  }, [values, value, isFetching])

  return (
    <Select
      isMulti={isMulti}
      isClearable
      isSearchable
      options={items ?? []}
      onMenuOpen={() => {
        setFocus(true)
      }}
      value={valueForSelect}
      className={isMulti ? 'basic-multi-select' : 'basic-single-select'}
      selectedOptionColorScheme={'brand'}
      openMenuOnClick={true}
      focusBorderColor="brandSecondary"
      hideSelectedOptions={true}
      placeholder={''}
      noOptionsMessage={() => t('digit_for_search')}
      onChange={onChange}
      isLoading={isFetchingPrimary}
      size={'sm'}
      onInputChange={(value) => {
        setSearch(value)
      }}
    />
  )
}
