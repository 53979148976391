import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { AssociazioneBene, BaseBene, Bene, PaginatedDJResponse } from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

export function useBeni(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Bene>>(
    ['beni', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/beni/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteBene() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/beni/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['beni'])
        client.invalidateQueries(['bene'])
      },
    }
  )
}

export function useCreateBene() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (bene: BaseBene) =>
        axios
          .post(`/api/beni/`, bene, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Bene)
    ),
    {
      onSuccess(savedBene) {
        client.invalidateQueries(['beni'])
        client.setQueryData(['bene', savedBene.id], savedBene)
      },
    }
  )
}

export function useUpdateBene() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (bene: Bene) =>
        axios
          .put(`/api/beni/${bene.id}/`, bene, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Bene)
    ),
    {
      onSuccess(savedBene) {
        client.invalidateQueries(['beni'])
        client.setQueryData(['bene', savedBene.id], savedBene)
      },
    }
  )
}

export function useAssociazioniBene(id: number) {
  return useQuery<AssociazioneBene[]>(
    ['associazioniBene', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/beni/${id}/associazioni/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      enabled: id !== undefined,
    }
  )
}

export function useBene(id: number) {
  return useQuery<Bene>(
    ['bene', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/beni/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      enabled: id !== undefined,
    }
  )
}

export function useBeniDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/beni/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

const makeImportBeni = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useImportBeni() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => ({ importFile }) => {
      const formData = makeImportBeni({ import_file: importFile })
      return axios
        .post(`/api/beni/import-beni/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['beni'])
      },
    }
  )
}
