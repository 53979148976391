export default function createStringLink({
  path,
  lang,
}: {
  path: string
  lang: string
}) {
  return '/' + lang + path
}

export const numberFormatter = new Intl.NumberFormat('it-IT', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export function getFileNameFromPath(path: string) {
  return path.split('/').pop()
}

export function getNameOfFileFromUrl (url: string) {
  return url.split('/').pop()?.split('?')[0] ?? ''
}