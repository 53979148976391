import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdBarChart, MdList, MdVisibility } from 'react-icons/md'
import { useAuthUser } from 'use-eazy-auth'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLink from '../../components/LangLink'
import { useProgettiPreferiti } from '../../hooks/progetti-preferiti'

import createStringLink from '../../utils'
import { useNavigate } from 'react-router-dom'
import { useHomeLinks } from '../../permissions'

export default function Home() {
  const { user } = useAuthUser()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const links = useHomeLinks()
  const filtersPreferiti = useMemo(() => {
    return {
      utente: user.id,
    }
  }, [user])
  const { data: progettiPreferiti } = useProgettiPreferiti(filtersPreferiti)

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('my_projects'),
      },
    ]
  }, [t])

  // const connectedUsers = useTopicWs('home')
  // console.log('connected users', connectedUsers)


  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={breadCrumbsItems} />}
          blockRight={
            <Box>
              {links.map((link, i) => {
                return (
                  <Button
                    key={i}
                    size={'sm'}
                    me={2}
                    onClick={() =>
                      navigate(
                        createStringLink({
                          path: link.path,
                          lang: i18n.language,
                        })
                      )
                    }
                  >
                    {t(link.label)}
                  </Button>
                )
              })}
            </Box>
          }
        />
        <Grid mt={5} templateColumns={'repeat(2, 1fr)'} gap={6}>
          {progettiPreferiti?.map((progettoPreferito) => {
            return (
              <GridItem colSpan={1} key={progettoPreferito.id}>
                <Box
                  height={202}
                  background={'white'}
                  borderRadius={8}
                  display={'flex'}
                  boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
                >
                  <Box
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <Box height={'100%'} px={4}>
                      <Box
                        mt={3}
                        fontSize={13}
                        alignItems={'center'}
                        fontWeight={500}
                        display={'flex'}
                      >
                        {dayjs().isBefore(
                          dayjs(progettoPreferito.progetto_data?.data_fine)
                        ) ? (
                          <Box
                            display={'inline-block'}
                            width={2}
                            height={2}
                            borderRadius={'50%'}
                            bg={'success'}
                            mr={2}
                          />
                        ) : (
                          <Box
                            display={'inline-block'}
                            width={2}
                            height={2}
                            borderRadius={'50%'}
                            bg={'error'}
                            mr={2}
                          />
                        )}

                        {progettoPreferito.progetto_data?.codice}
                      </Box>
                      <Box
                        mt={3}
                        fontSize={12}
                        fontWeight={400}
                        maxWidth={'max-content'}
                      >
                        {progettoPreferito.progetto_data?.titolo}
                      </Box>
                      <Box mt={6} fontSize={12} fontWeight={400}>
                        {
                          progettoPreferito.progetto_data?.paesi_intervento_data?.map(
                            (paese) => {
                              return paese.nome
                            }
                          ).join(', ')
                        }
                      </Box>
                    </Box>
                    <Box
                      bg={'card'}
                      py={4}
                      px={8}
                      justifyContent={'space-between'}
                      display={'flex'}
                    >
                      <Text fontSize={12} fontWeight={700}>
                        {progettoPreferito.progetto_data?.data_inizio &&
                          dayjs(
                            progettoPreferito.progetto_data?.data_inizio
                          ).format('DD/MM/YYYY')}
                      </Text>
                      <Text fontSize={12} fontWeight={700}>
                        {progettoPreferito.progetto_data?.data_fine &&
                          dayjs(
                            progettoPreferito.progetto_data?.data_fine
                          ).format('DD/MM/YYYY')}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    // justifyContent={'space-between'}
                    flexDirection={'column'}
                    // alignItems={'center'}
                    width={70}
                    height={'100%'}
                    background={'#F8F8F8'}
                  >
                    <LangLink
                      to={`/projects/${progettoPreferito.progetto_data?.id}/`}
                    >
                      <Box
                        width={'100%'}
                        pt={4}
                        pb={4}
                        height={67}
                        _hover={{
                          bg: 'brandLight',
                        }}
                      >
                        <Box
                          flexDirection={'column'}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <MdVisibility width={18} />
                          <Text fontSize={10} fontWeight={400}>
                            {t('details')}
                          </Text>
                        </Box>
                      </Box>
                    </LangLink>
                    <LangLink
                      to={`/projects/${progettoPreferito.progetto_data?.id}/movements/`}
                    >
                      <Box>
                        <Box
                          flexDirection={'column'}
                          display={'flex'}
                          pt={4}
                          height={67}
                          _hover={{
                            bg: 'brandLight',
                          }}
                          pb={4}
                          alignItems={'center'}
                        >
                          <MdList width={18} />
                          <Text fontSize={10} fontWeight={400}>
                            {t('movements')}
                          </Text>
                        </Box>
                      </Box>
                    </LangLink>
                    <LangLink
                      to={`/projects/${progettoPreferito.progetto_data?.id}/budgets/`}
                    >
                      <Box>
                        <Box
                          flexDirection={'column'}
                          display={'flex'}
                          pt={4}
                          height={68}
                          _hover={{
                            bg: 'brandLight',
                          }}
                          pb={4}
                          alignItems={'center'}
                        >
                          <MdBarChart width={18} />
                          <Text fontSize={10} fontWeight={400}>
                            {t('budgets')}
                          </Text>
                        </Box>
                      </Box>
                    </LangLink>
                  </Box>
                </Box>
              </GridItem>
            )
          })}
        </Grid>
        {/* <ConnectedUsers topic={'home'} /> */}
      </Flex>
    </Box>
  )
}
