import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsClockFill } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import ModalDelete from '../../../components/ModalDelete'
import ModalLogs from '../../../components/ModalLogs'
import { useBudget, useDeleteBudget } from '../../../hooks/budgets'
import { useProgettoSimple } from '../../../hooks/progetti'
import { useCanCreateEditBudget } from '../../../permissions'
import createStringLink, { numberFormatter } from '../../../utils'

export default function BudgetDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id, budget } = useParams()
  const { data: budgetData } = useBudget(Number(budget!))
  const { data: progetto } = useProgettoSimple(Number(id!))
  const deleteBudget = useDeleteBudget()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const {
    isOpen: isOpenLogs,
    onClose: onCloseLogs,
    onOpen: onOpenLogs,
    onToggle: onToggleLogs,
  } = useDisclosure()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('budgets'),
        link: `/projects/${id}/budgets`,
      },
      {
        label: budgetData?.codice ?? '',
      },
    ]
  }, [t, budgetData, progetto, id])

  const userCanCreateEditBudgets = useCanCreateEditBudget()

  const createdAt = useMemo(() => {
    if (budgetData?.logs) {
      const date = budgetData?.logs.find(
        (log) => log.action === 'create'
      )?.timestamp
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''
    }
  }, [budgetData])

  const updatedAt = useMemo(() => {
    if (budgetData?.logs) {
      const date = budgetData?.logs[0]?.timestamp
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''
    }
  }, [budgetData])

  const updateBy = useMemo(() => {
    if (budgetData?.logs && budgetData?.logs[0]?.actor) {
      return (
        budgetData?.logs[0]?.actor.name +
        ' <' +
        budgetData?.logs[0]?.actor.email +
        '>'
      )
    }
  }, [budgetData])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanCreateEditBudgets && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/projects/${id}/budgets/${budgetData?.id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('project')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.progetto_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('code')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('description')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('annuity')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.annualita}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('amount')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(budgetData?.importo ?? 0)} {
                    progetto?.valuta_rendicontazione_data?.codice
                  }
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('updated_amount')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(budgetData?.importo_aggiornato ?? 0)} {
                    progetto?.valuta_rendicontazione_data?.codice
                  }
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('Mago Pdc')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.voce_spesa_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('country_code')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.codice_paese}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('account_plan_code')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {budgetData?.codice_piano_conti}
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')} {createdAt}, {t('last_update')} {updatedAt}{' '}
              {t('by')} {updateBy}
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              _hover={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => onOpenLogs()}
            >
              <BsClockFill color="orange" />{' '}
              <Text ms={2}>{t('view_the_movement_story')}</Text>
            </Box>
          </CardFooter>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteBudget.mutateAsync(+budget!)
          navigate(
            createStringLink({
              path: `/projects/${id}/budgets`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={budgetData?.codice}
        title={t('delete_budget')}
      />
      <ModalLogs
        isOpen={isOpenLogs}
        onClose={onCloseLogs}
        onOpen={onOpenLogs}
        onToggle={onToggleLogs}
        title={
          t('history_of_budget') +
          ' - ' +
          budgetData?.codice +
          ' - ' +
          budgetData?.descrizione
        }
        logs={budgetData?.logs || []}
      />
    </Box>
  )
}
