import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import {
  useSaldiCertificati,
  useSaldiCertificatiDownload,
} from '../../hooks/saldi-certificati'
import { useCanGestioneConti } from '../../permissions'
import dayjs from 'dayjs'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'
import { MdSearch } from 'react-icons/md'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'nome',
  progetto: params.get('progetto') ?? '',
  conto: params.get('conto') ?? '',
})

export default function SaldiCertficatiList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useSaldiCertificati(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('certified_balances'),
      },
    ]
  }, [t])

  const userCanGestioneConti = useCanGestioneConti()
  const downloadWithParams = useSaldiCertificatiDownload(filters)

  const [showFilters, setShowFilters] = useState(true)

  const areFiltersActive = useMemo(() => {
    return filters.progetto !== '' || filters.conto !== ''
  }, [filters])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Box position={'relative'}>
                <Button
                  size={'sm'}
                  bg={'brandLight'}
                  color={'brand'}
                  me={2}
                  _hover={{
                    bg: 'brandSecondary',
                    color: 'white',
                  }}
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}
                >
                  {showFilters ? t('hide_filters') : t('show_filters')}
                </Button>
                {areFiltersActive && (
                  <Box
                    width={3}
                    height={3}
                    borderRadius={'50%'}
                    position={'absolute'}
                    top={-1}
                    right={1}
                    bg={'brand'}
                  ></Box>
                )}
              </Box>
              {userCanGestioneConti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/certified-balances/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            mb={2}
            zIndex={99}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('project')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.progetto}
                      keyQuery={'progetti-options'}
                      isMulti={false}
                      url={'/api/progetti/options/'}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            progetto: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            progetto: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('account')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.conto}
                      keyQuery={'conti-options'}
                      isMulti={false}
                      url={'/api/conti-bancari/options/'}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            conto: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            conto: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            uiFilters={uiFilters}
            data={data}
            downloadWithParams={downloadWithParams}
            filters={filters}
            options={[
              { value: 'pk', label: t('id') },
              {
                value: 'conto',
                label: t('account'),
              },
              {
                value: 'progetto',
                label: t('project'),
              },
              {
                value: 'saldo',
                label: t('balance'),
              },
              {
                value: 'data_saldo',
                label: t('date_balance'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
                width={'100%'}
                mt={3}
                overflowY={'auto'}
              >
                <Table variant={'pinc'}>
                  <Thead>
                    <Tr>
                      <Th>{t('id')}</Th>
                      <Th>{t('account')}</Th>
                      <Th>{t('project')}</Th>
                      <Th>{t('balance')}</Th>
                      <Th>{t('date_balance')}</Th>
                      <Th>{t('actions')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((saldo) => (
                      <Tr padding={2} key={saldo.id}>
                        <Td>
                          <Button
                            leftIcon={<MdSearch />}
                            bg={'white'}
                            border={'1px solid'}
                            borderColor={'brand'}
                            color={'brand'}
                            _hover={{ bg: 'brand', color: 'white' }}
                            size={'xs'}
                            onClick={() => {
                              navigate(
                                createStringLink({
                                  path: `/certified-balances/${saldo.id}`,
                                  lang: i18n.language,
                                })
                              )
                            }}
                          >
                            {saldo.id}
                          </Button>
                        </Td>
                        <Td>
                          <LangLinkWithStyle
                            to={`/accounts/${saldo.conto_bancario_data?.id}`}
                          >
                            {saldo.conto_bancario_data?.nome_banca}{' '} - {saldo.conto_bancario_data?.numero_conto} - {saldo.conto_bancario_data?.valuta_data
                              ?.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>
                          <LangLinkWithStyle
                            to={`/projects/${saldo.progetto_data?.id}`}
                          >
                            {saldo.progetto_data?.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>
                          {saldo.saldo}{' '}
                          {saldo.conto_bancario_data?.valuta_data?.codice}
                        </Td>
                        <Td>{dayjs(saldo.data_saldo).format('DD/MM/YYYY')}</Td>
                        <Td>
                          <Button
                            leftIcon={<MdSearch />}
                            border={'1px solid'}
                            borderColor={'brandBlue'}
                            color={'brandBlue'}
                            bg={'card'}
                            _hover={{
                              bg: 'brandBlue',
                              color: 'white',
                            }}
                            size={'xs'}
                            onClick={() => {
                              navigate(
                                createStringLink({
                                  path: `/projects/${saldo.progetto}/accounts/${saldo.conto}/movements`,
                                  lang: i18n.language,
                                })
                              )
                            }}
                          >
                            {t('control_movements')}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
