import {
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import ModalDelete from '../../components/ModalDelete'
import { useRemoveFornitoreProgetto } from '../../hooks/progetti'
import { Fornitore, PaginatedDJResponse } from '../../types'
import { numberFormatter } from '../../utils'

interface Props {
  data: PaginatedDJResponse<Fornitore> | undefined
  showNumMovimenti?: boolean
  showDelete?: boolean
  progetto?: number
  notSticky?: boolean
  paese_intervento?: number | string
  fornitoriToUnify?: number[] | undefined
  setFornitoriToUnify?: (value: number[]) => void
}

export default function FornitoriTable({
  data,
  showNumMovimenti = false,
  showDelete = false,
  progetto,
  notSticky = false,
  paese_intervento,
  fornitoriToUnify = [],
  setFornitoriToUnify = () => {},
}: Props) {
  const { t } = useTranslation()
  const deleteFornitoreProgetto = useRemoveFornitoreProgetto()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fornitoreToDelete, setFornitoreToDelete] = useState<Fornitore>()

  return (
    <>
      <Table variant={'pinc'}>
        <Thead
          position={!notSticky ? 'sticky' : undefined}
          top={!notSticky ? 0 : undefined}
        >
          <Tr>
            <Th></Th>
            <Th>{t('progressive')}</Th>
            <Th>{t('business_name')}</Th>
            <Th>{t('email')}</Th>
            <Th>{t('num_movements')}</Th>
            <Th>{t('country')}</Th>
            {showNumMovimenti && <Th>{t('total_euro_movements')}</Th>}
            {!progetto && <Th>{t('projects')}</Th>}
            {showDelete && <Th>{t('actions')}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data!.results.map((fornitore) => (
            <Tr padding={2} key={fornitore.id}>
              <Td>
                <Checkbox
                  width={5}
                  height={5}
                  size={'md'}
                  colorScheme={'orange'}
                  borderColor={'brandLight'}
                  isChecked={fornitoriToUnify.includes(fornitore.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFornitoriToUnify([...fornitoriToUnify, fornitore.id])
                    } else {
                      setFornitoriToUnify(
                        fornitoriToUnify.filter((id) => id !== fornitore.id)
                      )
                    }
                  }}
                />
              </Td>
              <Td whiteSpace={'nowrap'}>{fornitore.progressivo}</Td>
              <Td>
                <LangLinkWithStyle to={`/suppliers/${fornitore.id}`}>
                  {fornitore.ragione_sociale}
                </LangLinkWithStyle>
              </Td>
              <Td>{fornitore.mail}</Td>
              <Td>
                {paese_intervento ? (
                  <LangLinkWithStyle
                    to={`/movements?fornitore=${
                      fornitore.id
                    }&paesi_intervento=${paese_intervento ?? ''}`}
                  >
                    {fornitore.num_movimenti}
                  </LangLinkWithStyle>
                ) : (
                  <LangLinkWithStyle
                    to={`/movements?fornitore=${fornitore.id}`}
                  >
                    {fornitore.num_movimenti}
                  </LangLinkWithStyle>
                )}
              </Td>

              <Td>
                {fornitore.paese_intervento &&
                  fornitore.paese_intervento_data?.nome}
              </Td>
              {!progetto && (
              <Td>
                {fornitore.progetti?.map((progetto) => (
                  <Box key={progetto.id}>
                  <LangLinkWithStyle
                    
                    to={`/projects/${progetto.id}`}
                  >
                    {progetto.codice}
                  </LangLinkWithStyle>
                  </Box>
                ))}
              </Td>
              )}
              {showNumMovimenti && (
                <Td>
                  {numberFormatter.format(fornitore.totale_euro ?? 0)} EUR
                </Td>
              )}
              {showDelete && (
                <Td>
                  {fornitore.can_delete && (
                    <MdDelete
                      onClick={() => {
                        setFornitoreToDelete(fornitore)
                        onOpen()
                      }}
                      cursor={'pointer'}
                      size={20}
                      fill={'red'}
                    />
                  )}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteFornitoreProgetto.mutateAsync({
            progetto: progetto!,
            fornitore: fornitoreToDelete!.id,
          })
          onClose()
        }}
        title={t('delete_supplier_from_project')}
        nameItem={fornitoreToDelete?.ragione_sociale}
        onOpen={onOpen}
        onToggle={onClose}
      />
    </>
  )
}
