import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import ModalDelete from '../../../components/ModalDelete'
import { useDeleteReferente, useReferente } from '../../../hooks/referenti'
import createStringLink from '../../../utils'
import { useProgettoSimple } from '../../../hooks/progetti'

export default function Referente() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id, referente } = useParams()
  const { data: referenteData } = useReferente(Number(referente!))
  const { data: progetto } = useProgettoSimple(Number(id!))
  const deleteReferente = useDeleteReferente()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('contacts'),
        link: `/projects/${id}/contacts`,
      },
      {
        label: referenteData?.referente_generico_data.nome ?? '',
      },
    ]
  }, [t, referenteData, progetto, id])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                me={4}
                _hover={{
                  bg: 'error',
                  color: 'white',
                  opacity: 0.8,
                }}
                onClick={onOpen}
                bg={'error'}
                color={'white'}
              >
                {t('delete')}
              </Button>
              <Button
                size={'sm'}
                color={'white'}
                onClick={() => {
                  navigate(
                    createStringLink({
                      path: `/projects/${id}/contacts/${referenteData?.id}/edit`,
                      lang: i18n.language,
                    })
                  )
                }}
                type={'submit'}
                _hover={{ bg: 'brand' }}
                bg={'brandSecondary'}
              >
                {t('edit')}
              </Button>
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('generic_contact')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {referenteData?.referente_generico_data.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('date_start')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(referenteData?.data_inizio).format('DD/MM/YYYY')}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('date_end')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(referenteData?.data_fine).format('DD/MM/YYYY')}
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(referenteData?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(referenteData?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteReferente.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/projects/${id}/contacts`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={referenteData?.referente_generico_data.nome ?? ''}
        title={t('delete_contact')}
      />
    </Box>
  )
}
