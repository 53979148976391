import { AccountUser, BaseAccountUser } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  CheckboxField,
  InputField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

const initialValues: BaseAccountUser = {
  full_name: '',
  email: '',
  is_staff: false,
  is_active: false,
  is_superuser: false,
  permessi: {
    causale_spese_personale: false,
    causale_spese_loco: false,
    conti_abilitati: [],
    creazione_progetti: false,
    gestione_anagrafiche: false,
    gestione_beni: false,
    gestione_conti: false,
    gestione_fornitori: false,
    gestione_utenti: false,
    inserimento_modifica_budget: false,
    inserimento_modifica_cambi: false,
    inserimento_movimenti: false,
    limitazione_beni_fornitori: false,
    inserimento_multiplo_movimenti: false,
    modifica_multipla_movimenti: false,
    modifica_movimenti: false,
    paesi_intervento: [],
    progetti: [],
    inserimento_beni: false,
    inserimento_fornitori: false,
    gestione_collaborazioni: false,
    importazione_spese_loco: false,
    inserimento_collaboratori: false,
    inserimento_conti: false,
    duty_station: undefined,
  },
}

interface AccountUserFormProps {
  user?: AccountUser
  save: (user: AccountUser) => Promise<void>
}

export default function TipoRetribuzioneForm({
  user,
  save,
}: AccountUserFormProps) {
  const { t } = useTranslation()

  const UserSchema = Yup.object().shape({
    full_name: Yup.string().required().label(t('name')),
    email: Yup.string().required().label(t('email')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('users'),
        link: '/users',
      },
      {
        label: t('new_user'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('users'),
        link: '/users',
      },
      {
        label: user?.full_name ?? '',
        link: `/users/${user?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, user])

  return (
    <Formik
      validationSchema={UserSchema}
      onSubmit={(user, { setErrors, setSubmitting }) =>
        save({
          ...user,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(user ?? initialValues) as AccountUser}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        dirty,
        values,
      }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={user ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card overflow={'auto'} mt={2} className={'card-scroll-height'}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(4, 1fr)" gap={4}>
                  <GridItem>
                    <InputField
                      name="full_name"
                      isRequired
                      label={t('name') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="email"
                      isRequired
                      type={'email'}
                      label={t('email') ?? ''}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <AutoCompleteField
                      label={t('duty_station') ?? ''}
                      name="permessi.duty_station"
                      url={'/api/duty-stations/options/'}
                      value={values?.permessi?.duty_station}
                      keyQuery={'options-duty-stations'}
                    />
                  </GridItem>
                  <GridItem>
                    <CheckboxField
                      name="is_staff"
                      label={t('administrator') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <CheckboxField
                      name="is_active"
                      label={t('active') + ' ?'}
                    />
                  </GridItem>
                </Grid>
                {!values.is_staff && (
                  <>
                    <Grid
                      mt={3}
                      minHeight="100%"
                      templateColumns="repeat(1, 1fr)"
                      gap={4}
                    >
                      <GridItem>
                        <AutoCompleteField
                          label={t('countries') ?? ''}
                          name="permessi.paesi_intervento"
                          isMulti
                          url={'/api/paesi-intervento/options/'}
                          value={values?.permessi?.paesi_intervento}
                          keyQuery={'options-paesi-intervento'}
                        />
                      </GridItem>
                      <GridItem>
                        <AutoCompleteField
                          label={t('projects') ?? ''}
                          name="permessi.progetti"
                          isMulti
                          url={'/api/progetti/options/'}
                          value={values?.permessi?.progetti}
                          keyQuery={'options-progetti'}
                        />
                      </GridItem>
                    </Grid>
                    <Grid
                      mt={4}
                      minHeight="100%"
                      templateColumns="repeat(5, 1fr)"
                      gap={4}
                    >
                      <GridItem>
                        <CheckboxField
                          name="permessi.gestione_utenti"
                          label={t('manage_users') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.gestione_anagrafiche"
                          label={t('manage_data') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.gestione_fornitori"
                          label={t('manage_suppliers') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.gestione_beni"
                          label={t('manage_goods') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.gestione_conti"
                          label={t('manage_accounts') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_beni"
                          label={t('insert_goods') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_fornitori"
                          label={t('insert_suppliers') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.gestione_collaborazioni"
                          label={t('manage_collaborators') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_collaboratori"
                          label={t('insert_collaborators') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_conti"
                          label={t('insert_accounts') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.importazione_spese_loco"
                          label={t('import_loco_expenses') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_movimenti"
                          label={t('insert_movements') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.modifica_movimenti"
                          label={t('modify_movements') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_multiplo_movimenti"
                          label={t('insert_multiple_movements') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.modifica_multipla_movimenti"
                          label={t('edit_multiple_movements') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_modifica_budget"
                          label={t('insert_edit_budget') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.inserimento_modifica_cambi"
                          label={t('insert_edit_exchanges') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.limitazione_beni_fornitori"
                          label={t('limit_goods_suppliers') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.causale_spese_personale"
                          label={t('only_reason_collaborators_expenses') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.causale_spese_loco"
                          label={t('only_causal_loco_expenses') ?? ''}
                        />
                      </GridItem>
                      <GridItem>
                        <CheckboxField
                          name="permessi.creazione_progetti"
                          label={t('create_projects') ?? ''}
                        />
                      </GridItem>
                    </Grid>
                  </>
                )}
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
