import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import Referente from './Referente'
import ReferenteCreate from './ReferenteCreate'
import ReferenteEdit from './ReferenteEdit'
import ReferentiList from './ReferentiList'

export default function Referenti() {
  return (
    <Routes>
      <Route index element={<ReferentiList />} />
      <Route path="/create" element={<ReferenteCreate />} />
      <Route path="/:referente/edit" element={<ReferenteEdit />} />
      <Route path="/:referente" element={<Referente />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
