import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise, useAuthActions, useAuthUser } from 'use-eazy-auth'


export function useChangePassword() {
  const changePassword = useAuthCallPromise(
    useCallback(
      (token: string) => (data: { password: string; old_password: string }) =>
        axios
          .put('/api/change-password/', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as void),
      []
    )
  )
  return changePassword
}

export function useChangeLanguage() {
  const { user } = useAuthUser()
  const { updateUser } = useAuthActions()
  const changeLanguage = useAuthCallPromise(
    useCallback(
      (token: string) => (data: { selected_language: string }) =>
        axios
          .put('/api/change-language/', data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => {
            updateUser({ ...user, selected_language: data.selected_language })
            return r.data as void
          }),
      [updateUser, user]
    )
  )
  return changeLanguage
}

export function activateAccount(token: string, password: string) {
  return axios
    .post(`/api/users/activate_account/`, { token, password })
    .then((r) => r.data as void)
}

export function recoverPassword(email: string) {
  return axios
    .post('/api/users/recover_password/', { email })
    .then((r) => r.data as void)
}

export function resetPassword(token: string, password: string) {
  return axios
    .post(`/api/users/reset_password/`, { token, password })
    .then((r) => r.data as void)
}
