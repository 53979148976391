import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  InputGroup,
  Stack,
  Heading,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { resetPassword } from '../../hooks/profile'
import { InputField, transformErrorForForm } from '../../components/form'
import { useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password è un campo obbligatorio')
    .min(8, 'La password deve essere lunga almeno 8 caratteri.'),
  repeatPassword: Yup.string()
    .required('Conferma Password è un campo obbligatorio')
    .oneOf([Yup.ref('password'), ''], 'Le password non corrispondono.'),
})

const initialValues = {
  password: '',
  repeatPassword: '',
}

export default function RecuperaPassword() {
  const { token } = useParams()
  const [resetOk, setResetOk] = useState(false)
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="orange" />
        <Heading color="orange">Reset password</Heading>
        <Box minW={{ base: '90%', md: '468px' }}>
          <Formik
            onSubmit={(data, { setErrors }) =>
              resetPassword(token!, data.password).then(
                () => {
                  setResetOk(true)
                },
                (error) => {
                  setErrors(transformErrorForForm(error))
                }
              )
            }
            validationSchema={ResetPasswordSchema}
            initialValues={initialValues}
          >
            {({ handleSubmit, errors, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Stack
                  spacing={4}
                  p="1rem"
                  backgroundColor="whiteAlpha.900"
                  boxShadow="md"
                >
                  <FormControl>
                    <InputGroup>
                      <InputField
                        type={'password'}
                        name="password"
                        placeholder="Password"
                      />
                    </InputGroup>
                    <InputGroup mt={4}>
                      <InputField
                        type={'password'}
                        name="repeatPassword"
                        placeholder="Conferma password"
                      />
                    </InputGroup>
                  </FormControl>

                  {resetOk && (
                    <Box
                      bg="green.500"
                      p={3}
                      color="white"
                      width={'100%'}
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="sm"
                      textAlign="center"
                    >
                      Password resettata con successo! <br />
                      Vai al <Link to="/login">login</Link>
                    </Box>
                  )}
                  <Button
                    borderRadius={0}
                    type="submit"
                    variant="solid"
                    isDisabled={isSubmitting || !isValid}
                    colorScheme="orange"
                    width="full"
                  >
                    Reset password
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  )
}
