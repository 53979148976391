import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseSaldoCertificato,
  PaginatedDJResponse,
  SaldoCertificato,
} from '../types'
import { useDownloadFile } from './download'
import { useCallback } from 'react'

export function useSaldiCertificati(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<SaldoCertificato>>(
    ['saldi-certificati', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/saldi-certificati/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteSaldoCertificato() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/saldi-certificati/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['saldi-certificati'])
        client.invalidateQueries(['saldo-certificato'])
      },
    }
  )
}

export function useCreateSaldoCertificato() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (saldo: BaseSaldoCertificato) =>
        axios
          .post(`/api/saldi-certificati/`, saldo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as SaldoCertificato)
    ),
    {
      onSuccess(savedSaldo) {
        client.invalidateQueries(['saldi-certificati'])
        client.setQueryData(['saldo-certificato', savedSaldo.id], savedSaldo)
      },
    }
  )
}

export function useUpdateSaldoCertificato() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (saldo: SaldoCertificato) =>
        axios
          .put(`/api/saldi-certificati/${saldo.id}/`, saldo, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as SaldoCertificato)
    ),
    {
      onSuccess(savedSaldo) {
        client.invalidateQueries(['saldi-certificati'])
        client.setQueryData(['saldo-certificato', savedSaldo.id], savedSaldo)
      },
    }
  )
}

export function useSaldoCertificato(id: number) {
  return useQuery<SaldoCertificato>(
    ['saldo-certificato', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/saldi-certificati/${id}/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as SaldoCertificato)
    ),
    {
      enabled: !!id,
    }
  )
}

export function useLastSaldoCertificato(conto: number, progetto: number | '') {
  return useQuery<SaldoCertificato>(
    ['last-saldo-certificato', conto, progetto],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(
            `/api/saldi-certificati/get-last-saldo-certificato-conto-progetto/?conto=${conto}&progetto=${progetto}`,
            {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as SaldoCertificato)
    ),
    {
      enabled: !!conto
    }
  )
}

export function useLastSaldiCertificatiConto(conto:number) {
  return useQuery<SaldoCertificato[]>(
    ['last-saldi-certificati-conto', conto],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(
            `/api/saldi-certificati/get-last-saldo-certificato-conto-progetto/?conto=${conto}`,
            {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as SaldoCertificato[])
    ),
    {
      enabled: !!conto
    }
  )
}



export function useSaldiCertificatiDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/saldi-certificati/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

export function useDownloadLastSaldoCertificatiConti(conti: number[]) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/saldi-certificati/download-last-saldo-certificato-for-conti', { conti })
  }, [downloader, conti])
  return downloadWithParams
}
