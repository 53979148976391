import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Select,
  Text,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'
import { useQsFilters } from '../../../hooks/filters'
import {
  useConsuntivoBudget,
  useDownloadConsuntivoBudget,
  useProgettoSimple,
} from '../../../hooks/progetti'
import createStringLink, { numberFormatter } from '../../../utils'

const initFilters = (params: URLSearchParams) => ({
  annualita: Number(params.get('annualita') ?? 1),
})

export default function ConsuntivoBudgets() {
  const { id } = useParams()
  const { filters, setFilters } = useQsFilters(initFilters)

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const { data: consuntivoBudget, isLoading } = useConsuntivoBudget(
    Number(id!),
    filters
  )

  const downloadWithParams = useDownloadConsuntivoBudget(Number(id!), filters)

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('budget_balance'),
      },
    ]
  }, [t, progetto, id])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Text mr={2} fontSize={12}>
                {t('num_annualità')}
              </Text>
              <Select
                width={'auto'}
                size={'sm'}
                mr={2}
                _focusVisible={{ borderColor: 'brand' }}
                value={filters.annualita}
                onChange={(e) => {
                  setFilters({ annualita: Number(e.target.value) })
                }}
              >
                <option value="1">1</option>
                <option value="2">2</option>
              </Select>
              <Button
                size={'sm'}
                color={'white'}
                _hover={{ bg: 'brand' }}
                bg={'brandSecondary'}
                onClick={() => {
                  downloadWithParams()
                }}
              >
                {t('download_balance')}
              </Button>
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 180px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('item_budget')}</Th>
                      <Th>{t('account_plan_code')}</Th>
                      <Th>{t('description')}</Th>
                      <Th>
                        {t('budget_total')}{' '}
                        {progetto?.valuta_rendicontazione_data?.codice}
                      </Th>
                      <Th>
                        {t('total_expenses')}{' '}
                        {progetto?.valuta_rendicontazione_data?.codice}
                      </Th>
                      <Th>
                        {t('total_remaining')}{' '}
                        {progetto?.valuta_rendicontazione_data?.codice}
                      </Th>
                      <Th>
                        {t('annual_budget')}{' '}
                        {progetto?.valuta_rendicontazione_data?.codice}
                      </Th>
                      <Th>
                        {t('annual_expenses')}{' '}
                        {progetto?.valuta_rendicontazione_data?.codice}
                      </Th>
                      <Th>
                        {t('annual_remaining')}{' '}
                        {progetto?.valuta_rendicontazione_data?.codice}
                      </Th>
                      <Th>{t('list_movements')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr
                      top={'0px'}
                      style={{
                        fontWeight: 'bold',
                        zIndex: 1,
                      }}
                      position={'sticky'}
                    >
                      <Td colSpan={3} bg={'brandLight'}>
                        {t('total')}
                      </Td>
                      <Td bg={'brandLight'}>
                        {consuntivoBudget?.totals?.totale_tot &&
                          numberFormatter.format(
                            consuntivoBudget?.totals?.totale_tot
                          )}
                      </Td>
                      <Td bg={'brandLight'}>
                        {consuntivoBudget?.totals?.totale_movimenti_tot &&
                          numberFormatter.format(
                            consuntivoBudget?.totals?.totale_movimenti_tot
                          )}
                      </Td>
                      <Td bg={'brandLight'}>
                        {consuntivoBudget?.totals?.totale_tot &&
                          numberFormatter.format(
                            consuntivoBudget?.totals?.totale_tot -
                              consuntivoBudget?.totals?.totale_movimenti_tot
                          )}
                      </Td>
                      <Td bg={'brandLight'}>
                        {consuntivoBudget?.totals?.totale_anno &&
                          numberFormatter.format(
                            consuntivoBudget?.totals?.totale_anno
                          )}
                      </Td>
                      <Td bg={'brandLight'}>
                        {consuntivoBudget?.totals?.totale_movimenti_anno &&
                          numberFormatter.format(
                            consuntivoBudget?.totals?.totale_movimenti_anno
                          )}
                      </Td>
                      <Td bg={'brandLight'}>
                        {consuntivoBudget?.totals?.totale_anno &&
                          numberFormatter.format(
                            consuntivoBudget?.totals?.totale_anno -
                              consuntivoBudget?.totals?.totale_movimenti_anno
                          )}
                      </Td>
                      <Td bg={'brandLight'} />
                    </Tr>

                    {consuntivoBudget?.results.map((item) => (
                      <Tr key={item.codice}>
                        <Td>
                          <LangLinkWithStyle
                            to={`/projects/${id}/budgets/${item.pk}`}
                          >
                            {item.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{item.codice_piano_conti}</Td>
                        <Td>{item.descrizione}</Td>
                        <Td>{numberFormatter.format(item.totale_tot)}</Td>
                        <Td>
                          {numberFormatter.format(item.totale_movimenti_tot)}
                        </Td>
                        <Td>
                          {numberFormatter.format(
                            item.totale_tot - item.totale_movimenti_tot
                          )}
                        </Td>
                        <Td>{numberFormatter.format(item.totale_anno)}</Td>
                        <Td>
                          {numberFormatter.format(item.totale_movimenti_anno)}
                        </Td>
                        <Td>
                          {numberFormatter.format(
                            item.totale_anno - item.totale_movimenti_anno
                          )}
                        </Td>
                        <Td>
                          <Button
                            size={'xs'}
                            color={'white'}
                            _hover={{ bg: 'brand' }}
                            bg={'brandSecondary'}
                            onClick={() => {
                              navigate(
                                createStringLink({
                                  path: `/projects/${id}/movements?budget=${item.pk}`,
                                  lang: i18n.language,
                                })
                              )
                            }}
                          >
                            {t('list_movements')}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
