import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import { useDownloadProgetti, useProgetti } from '../../hooks/progetti'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import dayjs from 'dayjs'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink, { numberFormatter } from '../../utils'
import { Select } from 'chakra-react-select'
import { useCanCreateProgetti } from '../../permissions'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'
import { enGB, it, pt, es, fr } from 'date-fns/locale'
registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('fr', fr)
registerLocale('es', es)
registerLocale('pt', pt)

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'codice',
  valute: params.getAll('valute') ?? [],
  paesi_intervento: params.getAll('paesi_intervento') ?? [],
  stato_progetto: params.get('stato_progetto') ?? '',
  data_inizio: params.get('data_inizio') ?? '',
  data_fine: params.get('data_fine') ?? '',
})

export default function ProgettiList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useProgetti(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const userCanCreateProgetti = useCanCreateProgetti()

  const statiProgetto = useMemo(() => {
    return [
      {
        value: 'aperto',
        label: t('open'),
      },
      {
        value: 'chiuso',
        label: t('closed'),
      },
    ]
  }, [t])

  const filtersForDownload = useMemo(() => {
    let nextFilters = { ...filters } as any
    if (!filters.valute.length) {
      delete nextFilters.valute
    }
    if (!filters.paesi_intervento.length) {
      delete nextFilters.paesi_intervento
    }
    return nextFilters
  }, [filters])

  const downloadWithParams = useDownloadProgetti(filtersForDownload)

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('projects'),
      },
    ]
  }, [t])

  const [showFilters, setShowFilters] = useState(true)

  const areFiltersActive = useMemo(() => {
    return (
      filters.valute.length ||
      filters.paesi_intervento.length ||
      filters.stato_progetto ||
      filters.data_inizio ||
      filters.data_fine
    )
  }, [filters])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={breadCrumbsItems} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Box position={'relative'}>
                <Button
                  size={'sm'}
                  bg={'brandLight'}
                  color={'brand'}
                  me={2}
                  _hover={{
                    bg: 'brandSecondary',
                    color: 'white',
                  }}
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}
                >
                  {showFilters ? t('hide_filters') : t('show_filters')}
                </Button>
                {areFiltersActive && (
                  <Box
                    width={3}
                    height={3}
                    borderRadius={'50%'}
                    position={'absolute'}
                    top={-1}
                    right={1}
                    bg={'brand'}
                  ></Box>
                )}
              </Box>

              {userCanCreateProgetti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: '/projects/create',
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            zIndex={99}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('state_project')}</FormLabel>
                    <Select
                      options={statiProgetto ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          stato_progetto: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        statiProgetto?.find(
                          (v) => v.value === filters.stato_progetto
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('currency')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.valute}
                      keyQuery={'valute-options'}
                      url={'/api/valute/options/'}
                      onChange={(values: any) => {
                        if (!values || values.length === 0) {
                          setFilters({
                            ...filters,
                            valute: [],
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            valute: values.map((v: any) => v.value),
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl size={'sm'}>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('countries')}
                    </FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.paesi_intervento}
                      url={'/api/paesi-intervento/options/'}
                      keyQuery={'paesi-intervento-options'}
                      onChange={(values: any) => {
                        if (!values || values.length === 0) {
                          setFilters({
                            ...filters,
                            paesi_intervento: [],
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            paesi_intervento: values.map((v: any) => v.value),
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('date_start')}</FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_inizio: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_inizio: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_inizio
                          ? dayjs(filters.data_inizio).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('date_end')}</FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_fine: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_fine: '',
                            page: 1,
                          })
                        }
                      }}
                      clearButtonClassName={'clear-button'}
                      dateFormat={'dd/MM/yyyy'}
                      selected={
                        filters.data_fine
                          ? dayjs(filters.data_fine).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'codice', label: t('code') },
              { value: 'codice_progetto_donatore', label: t('code_donator') },
              { value: 'data_inizio', label: t('date_start') },
              { value: 'data_fine', label: t('date_end') },
              // { value: 'paese_intervento__nome', label: t('country') },
              // { value: 'importo_totale', label: t('total_import') },
              { value: 'ente_principale__nome', label: t('main_entity') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
                width={'100%'}
                mt={3}
                overflowY={'auto'}
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('code')}</Th>
                      <Th>{t('state')}</Th>
                      <Th>{t('code_donator')}</Th>
                      <Th>{t('date_start')}</Th>
                      <Th>{t('date_end')}</Th>
                      <Th>{t('country')}</Th>
                      <Th>{t('total_import')}</Th>
                      <Th>{t('main_entity')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((progetto) => (
                      <Tr padding={2} key={progetto.id}>
                        <Td>
                          <LangLinkWithStyle to={'/projects/' + progetto.id}>
                            {progetto.codice}
                          </LangLinkWithStyle>
                          <Box maxWidth={210}>{progetto.titolo}</Box>
                        </Td>
                        <Td>
                          {progetto.closed ? (
                            <Box display={'flex'} alignItems={'center'}>
                              <Box
                                display={'inline-block'}
                                width={2}
                                height={2}
                                borderRadius={'50%'}
                                bg={'error'}
                                mr={2}
                              />
                              <Text
                                fontSize={12}
                                fontWeight={400}
                                textTransform={'capitalize'}
                                color={'darkblue'}
                              >
                                {t('closed')}{' '}
                              </Text>
                            </Box>
                          ) : (
                            <Box display={'flex'} alignItems={'center'}>
                              <Box
                                display={'inline-block'}
                                width={2}
                                height={2}
                                borderRadius={'50%'}
                                bg={'success'}
                                mr={2}
                              />
                              <Text
                                fontSize={12}
                                fontWeight={400}
                                textTransform={'capitalize'}
                                color={'darkblue'}
                              >
                                {t('open')}{' '}
                              </Text>
                            </Box>
                          )}
                          {progetto.data_blocco_compentenza && (
                            <Badge
                              size={'xs'}
                              px={2}
                              fontSize={10}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              colorScheme={'red'}
                              borderRadius={'md'}
                            >
                              {t('block_on_date')}{' '}
                              {dayjs(progetto.data_blocco_compentenza).format(
                                'DD/MM/YYYY'
                              )}
                            </Badge>
                          )}
                        </Td>
                        <Td>{progetto.codice_progetto_donatore}</Td>
                        <Td whiteSpace={'nowrap'}>
                          {progetto.data_inizio
                            ? dayjs(progetto.data_inizio).format('DD/MM/YYYY')
                            : ''}
                        </Td>
                        <Td whiteSpace={'nowrap'}>
                          {progetto.data_fine
                            ? dayjs(progetto.data_fine).format('DD/MM/YYYY')
                            : ''}
                        </Td>
                        <Td>
                          {progetto.paesi_intervento_data
                            ?.map((paese) => {
                              return paese.nome
                            })
                            .join(', ')}
                        </Td>
                        <Td textAlign={'right'}>
                          <Box color={'brand'} fontWeight={700}>
                            {numberFormatter.format(
                              progetto.importo_totale ?? 0
                            )}
                          </Box>
                          {progetto.valuta_rendicontazione_data?.codice}
                        </Td>
                        <Td>{progetto.ente_principale_data?.nome}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
