import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import SaldiCertficatiList from './SaldiCertificatiList'
import SaldoCertificato from './SaldoCertficato'
import SaldoCertificatoCreate from './SaldoCertificatoCreate'
import SaldoCertficatoEdit from './SaldoCertificatoEdit'

export default function SaldiCertificati() {
  return (
    <Routes>
      <Route index element={<SaldiCertficatiList />} />
      <Route path="create" element={<SaldoCertificatoCreate  />} />
      <Route path="/:id" element={<SaldoCertificato />} />
      <Route path="/:id/edit" element={<SaldoCertficatoEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
