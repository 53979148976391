import { useAuthCallPromise } from 'use-eazy-auth'
import axios from 'axios'
import { serializeQueryParams } from './utils'
import { useQuery } from '@tanstack/react-query'

export interface OptionsType {
  label: string
  value: string
}

export function useOptions(
  params: Record<string, any> = {},
  enabled: boolean,
  key: string,
  url: string = `/api/progetti/options/`
) {
  return useQuery<OptionsType[]>(
    [key, params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(url, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
              params: serializeQueryParams(params),
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: false,
      enabled: enabled,
      suspense: false,
    }
  )
}
