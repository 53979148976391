import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { useQsFilters } from '../../../hooks/filters'
import { useFornitoriDownload } from '../../../hooks/fornitori'
import {
  useAddFornitoreProgetto,
  useFornitoriProgetto,
  useProgettoSimple,
} from '../../../hooks/progetti'
import { useCanGestioneFornitori } from '../../../permissions'
import FornitoriTable from '../../Fornitori/FornitoriTable'
import ModalAssociaFornitore from './ModalAssociaFornitore'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'progressivo',
  partita_iva: params.get('partita_iva') ?? '',
  codice_fiscale: params.get('codice_fiscale') ?? '',
})

export default function FornitoriList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const { data: fornitori, isLoading } = useFornitoriProgetto(
    Number(id!),
    filters
  )

  const { t } = useTranslation()
  const downloadWithParams = useFornitoriDownload(filters)
  const userCanGestioneFornitori = useCanGestioneFornitori()
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const associaFornitore = useAddFornitoreProgetto()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('suppliers'),
      },
    ]
  }, [t, progetto, id])

  const [showFilters, setShowFilters] = useState(true)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
              {userCanGestioneFornitori && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={onOpen}
                >
                  {t('associate_supplier')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            zIndex={99}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl size={'sm'}>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('vat_number')}
                    </FormLabel>
                    <Input
                      size={'sm'}
                      value={filters.partita_iva}
                      onChange={(e) => {
                        setFilters({
                          partita_iva: e.target.value,
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl size={'sm'}>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('tax_id_code')}
                    </FormLabel>
                    <Input
                      size={'sm'}
                      value={filters.codice_fiscale}
                      onChange={(e) => {
                        setFilters({
                          codice_fiscale: e.target.value,
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={fornitori}
            filters={filters}
            options={[
              { value: 'progressivo', label: t('progressive') },
              {
                value: 'ragione_sociale',
                label: t('business_name'),
              },
              {
                value: 'mail',
                label: t('email'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <FornitoriTable
                  progetto={Number(id)}
                  showDelete
                  data={fornitori}
                />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={fornitori!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <ModalAssociaFornitore
          isOpen={isOpen}
          onClose={onClose}
          onToggle={onToggle}
          progetto={progetto}
          onConfirm={async (fornitore) => {
            await associaFornitore
              .mutateAsync({
                progetto: Number(id!),
                fornitore: fornitore.fornitore,
              })
              .then(() => {
                onClose()
              })
          }}
          onOpen={onOpen}
          title={t('associate_supplier')}
        />
      )}
    </Box>
  )
}
