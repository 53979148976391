import { FieldArray, Formik } from 'formik'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField, transformErrorForForm } from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import BreadCrumb from '../../components/BreadCrumb'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'

import { ValoreCambio } from '../../types'
import * as Yup from 'yup'
import { BiDuplicate, BiPlus, BiTrash } from 'react-icons/bi'
import AutoCompleteField, { DateField } from '../../components/form/fields'

export default function ValoreCambioMultipleForm({
  save,
  cambi,
}: {
  save: (values: any) => Promise<void>
  cambi?: ValoreCambio[]
}) {
  const { t } = useTranslation()
  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('exchange_rate_values'),
        link: '/exchange-rate-values',
      },
      {
        label: t('create_multiple'),
      },
    ]
  }, [t])

  const initialValues = useMemo(() => {
    return {
      cambi: [
        {
          tipo_cambio: '',
          da_data: '',
          a_data: '',
          valore: '',
          valuta_a: '',
          valuta_da: '',
        },
      ],
    }
  }, [])

  const ValoriCamboSchema = Yup.object().shape({
    cambi: Yup.array().of(
      Yup.object().shape({
        tipo_cambio: Yup.string().required().label(t('type_of_exchange')),
        da_data: Yup.string().required().label(t('from_date')),
        a_data: Yup.string().required().label(t('to_date')),
        valore: Yup.string().required().label(t('value')),
        valuta_a: Yup.string().required().label(t('currency_to')),
        valuta_da: Yup.string().required().label(t('currency_from')),
      })
    ),
  })

  return (
    <Formik
      validationSchema={ValoriCamboSchema}
      onSubmit={(valore, { setErrors, setSubmitting }) =>
        save({
          ...valore,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        handleReset,
        validateForm,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={<BreadCrumb items={itemsBreadCrumbCreate} />}
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
          </Flex>
          <FieldArray
            name="cambi"
            render={(arrayHelpers) => (
              <Box
                maxHeight={'calc(100vh - 150px)'}
                className={'card-scroll-height'}
                overflow={'auto'}
              >
                <Button
                  position={'fixed'}
                  right={10}
                  bottom={10}
                  zIndex={100}
                  onClick={() => {
                    // validate form before adding new movimento
                    validateForm()

                    arrayHelpers.push({
                      da_data: '',
                      a_data: '',
                      tipo_cambio: '',
                      valore: '',
                      valuta_a: '',
                      valuta_da: '',
                    })
                  }}
                  size={'lg'}
                  color={'white'}
                  bg={'brand'}
                  borderRadius={'50%'}
                  padding={0}
                  boxShadow={'0 0 10px rgba(0,0,0,0.3)'}
                  _hover={{
                    bg: 'brandSecondary',
                    color: 'white',
                  }}
                >
                  <BiPlus size={30} />
                </Button>
                <Grid
                  width={`calc(350px * ${values.cambi.length ?? 0})`}
                  templateColumns={'repeat(auto-fit, minmax(250px, 1fr))'}
                >
                  {values.cambi.map((mov, index) => (
                    <GridItem key={index} width={'97%'}>
                      <Card mb={4}>
                        <CardHeader
                          py={2}
                          bg={'brandLight'}
                          fontSize={14}
                          fontWeight={500}
                        >
                          <Flex alignItems={'center'}>
                            <Box>
                              {t('exchange_rate_values')} {index + 1}
                            </Box>
                            <Button
                              ms={4}
                              onClick={() => arrayHelpers.remove(index)}
                              outlineColor={'red.400'}
                              bg={'white'}
                              color={'red.400'}
                              _hover={{ bg: 'red.400', color: 'white' }}
                              size={'xs'}
                              outline={'1px solid'}
                              leftIcon={<BiTrash />}
                            >
                              {t('delete')}
                            </Button>

                            <Button
                              ms={4}
                              onClick={() => {
                                validateForm()
                                const cambio = values.cambi[index]
                                const newCambio = {
                                  ...cambio,
                                  id: undefined,
                                }
                                arrayHelpers.insert(index + 1, newCambio)
                              }}
                              outlineColor={'brandSecondary'}
                              color={'brandSecondary'}
                              bg={'white'}
                              _hover={{
                                bg: 'brandSecondary',
                                color: 'white',
                              }}
                              outline={'1px solid'}
                              size={'xs'}
                              leftIcon={<BiDuplicate />}
                            >
                              {t('duplicate')}
                            </Button>
                          </Flex>
                        </CardHeader>
                        <CardBody>
                          <Grid
                            width={'300px'}
                            overflowX={'scroll'}
                            gridTemplateColumns={
                              'repeat(auto-fit, minmax(150px, 1fr))'
                            }
                            gap={4}
                          >
                            <GridItem>
                              <AutoCompleteField
                                name={`cambi.${index}.tipo_cambio`}
                                label={t('type_of_exchange') ?? ''}
                                url="/api/tipi-cambio/options/"
                                keyQuery="tipi-cambio-options"
                                value={values.cambi[index].tipo_cambio}
                                isRequired
                              />
                            </GridItem>
                            <GridItem>
                              <DateField
                                name={`cambi.${index}.da_data`}
                                label={t('from_date') ?? ''}
                                isRequired
                              />
                            </GridItem>
                            <GridItem>
                              <DateField
                                name={`cambi.${index}.a_data`}
                                label={t('to_date') ?? ''}
                                isRequired
                              />
                            </GridItem>
                            <GridItem>
                              <AutoCompleteField
                                name={`cambi.${index}.valuta_da`}
                                label={t('currency_from') ?? ''}
                                url="/api/valute/options/"
                                keyQuery="valute-options"
                                value={values.cambi[index].valuta_da}
                                isRequired
                              />
                            </GridItem>
                            <GridItem>
                              <AutoCompleteField
                                name={`cambi.${index}.valuta_a`}
                                label={t('currency_to') ?? ''}
                                url="/api/valute/options/"
                                keyQuery="valute-options"
                                value={values.cambi[index].valuta_a}
                                isRequired
                              />
                            </GridItem>
                            <GridItem>
                              <InputField
                                name={`cambi.${index}.valore`}
                                label={t('value') ?? ''}
                                type={'number'}
                                isRequired
                              />
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            )}
          />
        </form>
      )}
    </Formik>
  )
}
