import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import {
  useFornitori,
  useFornitoriDownload,
  useUnificaFornitori,
  useImportFornitori,
} from '../../hooks/fornitori'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import { useCanInsertFornitori } from '../../permissions'
import FornitoriTable from './FornitoriTable'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'
import ModalUnifyFornitori from './ModalUnifyFornitori'
import ModalImportFornitori from './ModalImportFornitori'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'progressivo',
  paese_intervento: params.get('paese_intervento') ?? '',
  partita_iva: params.get('partita_iva') ?? '',
  codice_fiscale: params.get('codice_fiscale') ?? '',
})

export default function FornitoriList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading } = useFornitori(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const downloadWithParams = useFornitoriDownload(filters)
  const userCanInsertFornitori = useCanInsertFornitori()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('suppliers'),
      },
    ]
  }, [t])

  const [showFilters, setShowFilters] = useState(true)
  const [fornitoriToUnify, setFornitoriToUnify] = useState<number[]>([])

  const {
    isOpen: isOpenModalUnify,
    onOpen: onOpenModalUnify,
    onClose: onCloseModalUnify,
    onToggle: onToggleModalUnify,
  } = useDisclosure()

  const {
    isOpen: isOpenImportFornitori,
    onOpen: onOpenImportFornitori,
    onClose: onCloseImportFornitori,
    onToggle: onToggleImportFornitori,
  } = useDisclosure()

  const unificaFornitori = useUnificaFornitori()

  const toast = useToast()

  const [errors, setErrors] = useState(null)

  const importFornitori = useImportFornitori()

  const [isLoadingImport, setIsLoadingImport] = useState(false)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanInsertFornitori && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'darkblue'}
                  border={'1px solid'}
                  fontWeight={500}
                  _hover={{ bg: 'brand', color: 'white' }}
                  onClick={() => {
                    onOpenImportFornitori()
                  }}
                >
                  {t('import_suppliers')}
                </Button>
              )}
              {userCanInsertFornitori && fornitoriToUnify.length > 0 && (
                <Button
                  size={'sm'}
                  border={'1px solid'}
                  borderColor={'brandBlue'}
                  color={'brandBlue'}
                  bg={'card'}
                  _hover={{
                    bg: 'brandBlue',
                    color: 'white',
                  }}
                  me={2}
                  onClick={() => {
                    onOpenModalUnify()
                  }}
                >
                  {t('unify_suppliers')}
                </Button>
              )}
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
              {userCanInsertFornitori && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: '/suppliers/create',
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            zIndex={99}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl size={'sm'}>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('country')}
                    </FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.paese_intervento}
                      isMulti={false}
                      url={'/api/paesi-intervento/options/'}
                      keyQuery={'paesi-intervento-options'}
                      onChange={(values: any) => {
                        setFilters({
                          paese_intervento: values?.value ?? '',
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl size={'sm'}>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('vat_number')}
                    </FormLabel>
                    <Input
                      size={'sm'}
                      value={filters.partita_iva}
                      onChange={(e) => {
                        setFilters({
                          partita_iva: e.target.value,
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl size={'sm'}>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('tax_id_code')}
                    </FormLabel>
                    <Input
                      size={'sm'}
                      value={filters.codice_fiscale}
                      onChange={(e) => {
                        setFilters({
                          codice_fiscale: e.target.value,
                        })
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'progressivo', label: t('progressive') },
              {
                value: 'ragione_sociale',
                label: t('business_name'),
              },
              {
                value: 'mail',
                label: t('email'),
              },
              {
                value: 'paese_intervento__nome',
                label: t('country'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
                width={'100%'}
                mt={3}
                overflowY={'auto'}
              >
                <FornitoriTable
                  data={data}
                  fornitoriToUnify={fornitoriToUnify}
                  setFornitoriToUnify={setFornitoriToUnify}
                />
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      <ModalUnifyFornitori
        isOpen={isOpenModalUnify}
        onClose={onCloseModalUnify}
        fornitoriToUnify={fornitoriToUnify}
        onOpen={onOpenModalUnify}
        onToggle={onToggleModalUnify}
        onConfirm={(values) => {
          unificaFornitori.mutate(
            {
              fornitore: values.fornitore,
              fornitori_to_remove: values.fornitori_to_remove,
            },
            {
              onSuccess: () => {
                toast({
                  title: t('success'),
                  description: t('you_have_unified_suppliers'),
                  status: 'success',
                  duration: 10000,
                  isClosable: true,
                  position: 'bottom',
                })
                setFornitoriToUnify([])
                onCloseModalUnify()
              },
            }
          )
        }}
      />
       <ModalImportFornitori
        isOpen={isOpenImportFornitori}
        onClose={onCloseImportFornitori}
        onOpen={onOpenImportFornitori}
        errors={errors}
        onToggle={onToggleImportFornitori}
        isLoading={isLoadingImport}
        onConfirm={async (values) => {
          setIsLoadingImport(true)
          await importFornitori
            .mutateAsync({
              importFile: values.import_file,
            })
            .then(() => {
              setIsLoadingImport(false)
              onCloseImportFornitori()
            })
            .catch((err) => {
              setErrors(err.response.data)
            })
        }}
      />
    </Box>
  )
}
