import { Route, Routes } from 'react-router'
import NotFound from '../../../components/NotFound'
import ContiProgettoList from './ContiProgettoList'
import MovimentiContoProgetto from './MovimentiContoProgetto'

export default function ContiProgetto() {
  return (
    <Routes>
      <Route index element={<ContiProgettoList />} />
      <Route path={'/:contoId/movements'} element={<MovimentiContoProgetto />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
