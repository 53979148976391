import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import {
  useImportCambi,
  useValoriCambio,
  useValoriCambioDownload,
} from '../../hooks/valori-cambio'
import Paginator from '../../components/Paginator'
import { useNavigate } from 'react-router'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import createStringLink from '../../utils'
import { useCanGestioneCambi } from '../../permissions'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import dayjs from 'dayjs'
import { enGB, it, pt, es, fr } from 'date-fns/locale'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'
import { BiCheck, BiX } from 'react-icons/bi'
import ImportCambiModal from './ImportCambiModal'

registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('fr', fr)
registerLocale('es', es)
registerLocale('pt', pt)

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'codice',
  valuta_da: params.get('valuta_da') ?? '',
  valuta_a: params.get('valuta_a') ?? '',
  data: params.get('data') ?? '',
  tipo_cambio: params.get('tipo_cambio') ?? '',
})

export default function ValoriCambioList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { data, isLoading, refetch } = useValoriCambio(filters)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const userCanGestioneCambi = useCanGestioneCambi()

  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const [errors, setErrors] = useState(null)

  const importCambi = useImportCambi()

  const breadCrumbsItems = useMemo(() => {
    return [
      {
        label: t('exchange_rate_values'),
      },
    ]
  }, [t])

  const downloadWithParams = useValoriCambioDownload(filters)

  const [showFilters, setShowFilters] = useState(true)

  const areFiltersActive = useMemo(() => {
    if (
      filters.valuta_da ||
      filters.valuta_a ||
      filters.data ||
      filters.tipo_cambio
    ) {
      return true
    }
    return false
  }, [filters])

  const toast = useToast()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={breadCrumbsItems} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Box position={'relative'}>
                {userCanGestioneCambi && (
                  <Button
                    size={'sm'}
                    me={2}
                    color={'darkblue'}
                    border={'1px solid'}
                    fontWeight={500}
                    _hover={{ bg: 'darkblue', color: 'white' }}
                    onClick={onOpen}
                  >
                    {t('import')}
                  </Button>
                )}
                <Button
                  size={'sm'}
                  bg={'brandLight'}
                  color={'brand'}
                  me={2}
                  _hover={{
                    bg: 'brandSecondary',
                    color: 'white',
                  }}
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}
                >
                  {showFilters ? t('hide_filters') : t('show_filters')}
                </Button>
                {areFiltersActive && (
                  <Box
                    width={3}
                    height={3}
                    borderRadius={'50%'}
                    position={'absolute'}
                    top={-1}
                    right={1}
                    bg={'brand'}
                  ></Box>
                )}
              </Box>
              {userCanGestioneCambi && (
                <Button
                  size={'sm'}
                  color={'white'}
                  me={2}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: '/exchange-rate-values/create-multiple',
                      })
                    )
                  }}
                >
                  {t('create_multiple')}
                </Button>
              )}
              {userCanGestioneCambi && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: '/exchange-rate-values/create',
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            mb={2}
            zIndex={99}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(4, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('currency_from')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.valuta_da}
                      keyQuery={'valute-options'}
                      isMulti={false}
                      url={'/api/valute/options/'}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            valuta_da: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            valuta_da: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('currency_to')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.valuta_a}
                      keyQuery={'valute-options'}
                      isMulti={false}
                      url={'/api/valute/options/'}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            valuta_a: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            valuta_a: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('date')}</FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data: '',
                            page: 1,
                          })
                        }
                      }}
                      clearButtonClassName={'clear-button'}
                      dateFormat={'dd/MM/yyyy'}
                      selected={
                        filters.data ? dayjs(filters.data).toDate() : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={14}>{t('exchange_type')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.tipo_cambio}
                      keyQuery={'tipi-cambio-options'}
                      isMulti={false}
                      url={'/api/tipi-cambio/options/'}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            tipo_cambio: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            tipo_cambio: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'tipo_cambio__codice', label: t('code') },
              { value: 'valuta_da__codice', label: t('currency_from') },
              { value: 'valuta_a__codice', label: t('currency_to') },
              { value: 'da_data', label: t('date_from') },
              { value: 'a_data', label: t('date_to') },
              { value: 'valore', label: t('value') },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                height={
                  showFilters ? 'calc(100vh - 337px)' : 'calc(100vh - 240px)'
                }
                mt={3}
                width={'100%'}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead>
                    <Tr>
                      <Th>{t('code')}</Th>
                      <Th>{t('currency_from')}</Th>
                      <Th>{t('currency_to')}</Th>
                      <Th>{t('date_from')}</Th>
                      <Th>{t('date_to')}</Th>
                      <Th>{t('value')}</Th>
                      <Th>{t('editable')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((valore) => (
                      <Tr padding={2} key={valore.id}>
                        <Td>
                          <LangLinkWithStyle
                            to={`/exchange-rate-values/${valore.id}`}
                          >
                            {valore.tipo_cambio_data?.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{valore.valuta_da_data?.codice}</Td>
                        <Td>{valore.valuta_a_data?.codice}</Td>
                        <Td>{dayjs(valore.da_data).format('DD/MM/YYYY')}</Td>
                        <Td>{dayjs(valore.a_data).format('DD/MM/YYYY')}</Td>
                        <Td>{valore.valore}</Td>
                        <Td>
                          {valore.modificabile ? (
                            <BiCheck size={20} color="green" />
                          ) : (
                            <BiX size={20} color="red" />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <ImportCambiModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          errors={errors}
          onToggle={onToggle}
          onConfirm={async (values) => {
            await importCambi
              .mutateAsync({
                importFile: values.import_file,
              })
              .then(() => {
                onClose()
                refetch()
                toast({
                  title: t('exchange_rate_values_imported'),
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                })
              })
              .catch((err) => {
                setErrors(err.response.data.error)
              })
          }}
        />
      )}
    </Box>
  )
}
