import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  PaginatedDJResponse,
  ReferenteGenerico,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

const makeReferenteGenericoFormData = makeFormDataEncoder({
  fileFields: ['allegato'],
  excludeFields: [],
})

export function useReferentiGenerici(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<ReferenteGenerico>>(
    ['referenti-generici', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/referenti-generici/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteReferenteGenerico() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/referenti-generici/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['referenti-generici'])
        client.invalidateQueries(['referente-generico'])
      },
    }
  )
}

export function useCreateReferenteGenerico() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (referente: ReferenteGenerico) =>
        axios
          .post(
            `/api/referenti-generici/`,
            makeReferenteGenericoFormData(referente),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as ReferenteGenerico)
    ),
    {
      onSuccess(savedReferente) {
        client.invalidateQueries(['referenti-generici'])
        client.setQueryData(
          ['referente-generico', savedReferente.id],
          savedReferente
        )
      },
    }
  )
}

export function useUpdateReferenteGenerico() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (referente: ReferenteGenerico) =>
        axios
          .put(
            `/api/referenti-generici/${referente.id}/`,
            makeReferenteGenericoFormData(referente),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as ReferenteGenerico)
    ),
    {
      onSuccess(referente) {
        client.invalidateQueries(['referenti-generici'])
        client.setQueryData(['referente-generico', referente.id], referente)
      },
    }
  )
}

export function useReferenteGenerico(id: number) {
  return useQuery<ReferenteGenerico>(
    ['referente-generico', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/referenti-generici/${id}/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ReferenteGenerico)
    )
  )
}

export function useReferentiGenericiDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/referenti-generici/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
