import { Box } from '@chakra-ui/react'

export default function NotFound() {
  return (
    <Box
      pt={67}
      width={'100%'}
      minHeight={'100%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <h1 className={'not-found'}>404 Page not found</h1>
    </Box>
  )
}
