import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { CheckboxField, DateField } from '../../../components/form/fields'
import { ContoBancario, Progetto } from '../../../types'
import dayjs from 'dayjs'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto?: Progetto | undefined
  conto: ContoBancario | undefined
  only_data_pagamento?: boolean
  data_pagamento?: string | null
}

export default function ModalCheckMovimenti({
  progetto,
  conto,
  isOpen,
  onClose,
  onConfirm,
  only_data_pagamento = false,
  data_pagamento,
}: Props) {
  const { t } = useTranslation()

  const initialValues = only_data_pagamento
    ? { data_pagamento: data_pagamento ? dayjs(data_pagamento).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD') }
    : { data_pagamento: dayjs().format('YYYY-MM-DD'), riportata_e_c: false }

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('control_movements')} - {progetto?.codice} -{' '}
                {conto?.nome_banca} {conto?.valuta_data?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <DateField
                    name="data_pagamento"
                    label={t('payment_date') ?? ''}
                    isRequired
                  />
                </Box>
                {!only_data_pagamento && (
                  <Box mt={2}>
                    <CheckboxField
                      name="riportata_e_c"
                      label={t('Riportata_e_c') ?? ''}
                    />
                  </Box>
                )}
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('save')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
