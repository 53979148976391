import { BaseValuta, Valuta } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { InputField, transformErrorForForm } from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

const initialValues: BaseValuta = {
  codice: '',
  descrizione: '',
  simbolo: '',
}

interface ValutaFormProps {
  valuta?: Valuta
  save: (valuta: Valuta) => Promise<void>
}

export default function ValutaForm({ valuta, save }: ValutaFormProps) {
  const { t } = useTranslation()

  const PaeseInterventoSchema = Yup.object().shape({
    codice: Yup.string().required().label(t('code')),
    descrizione: Yup.string().required().label(t('description')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('currencies'),
        link: '/currencies',
      },
      {
        label: t('new_currency'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('currencies'),
        link: '/currencies',
      },
      {
        label: valuta?.descrizione ?? '',
        link: `/currencies/${valuta?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, valuta])

  return (
    <Formik
      validationSchema={PaeseInterventoSchema}
      onSubmit={(valuta, { setErrors, setSubmitting }) =>
        save({
          ...valuta,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(valuta ?? initialValues) as Valuta}
    >
      {({ handleSubmit, isSubmitting, isValid, handleReset, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={valuta ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem>
                    <InputField
                      isRequired
                      name="codice"
                      label={t('code') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="descrizione"
                      isRequired
                      label={t('description') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField name="simbolo" label={t('symbol') ?? ''} />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
