import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import BeniProgetto from './BeniProgetto/BeniProgetto'
import Budgets from './Budgets'
import Collaborazioni from './Collaborazioni'
import ConsuntivoBudgets from './ConsuntivoBudgets'
import ContiProgetto from './ContiProgetto'
import DonatoriProgetto from './DonatoriProgetto'
import FornitoriProgetto from './FornitoriProgetto'
import MovimentiProgetto from './MovimentiProgetto'
import PermessiProgetto from './PermessiProgetto'
import ProgettiCreate from './ProgettiCreate'
import ProgettiEdit from './ProgettiEdit'
import ProgettiList from './ProgettiList'
import Progetto from './Progetto'
import Referenti from './Referenti'
import Finanziamenti from './Finanziamenti/Finanziamenti'

export default function Progetti() {
  return (
    <Routes>
      <Route index element={<ProgettiList />} />
      <Route path={'/create'} element={<ProgettiCreate />} />
      <Route path=":id" element={<Progetto />} />
      <Route path="/edit/:id" element={<ProgettiEdit />} />
      <Route path=":id/collaborations/*" element={<Collaborazioni />} />
      <Route path=":id/budgets/*" element={<Budgets />} />
      <Route path=":id/suppliers/*" element={<FornitoriProgetto />} />
      <Route path=":id/donors/*" element={<DonatoriProgetto />} />
      <Route path=":id/contacts/*" element={<Referenti />} />
      <Route path=":id/movements/*" element={<MovimentiProgetto />} />
      <Route path=":id/balance/*" element={<ConsuntivoBudgets />} />
      <Route path=":id/accounts/*" element={<ContiProgetto />} />
      <Route path=":id/goods/*" element={<BeniProgetto />} />
      <Route path=":id/financing/*" element={<Finanziamenti />} />
      <Route path=":id/permissions/*" element={<PermessiProgetto />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
