import {
  Box,
  Button,
  Checkbox,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdSearch } from 'react-icons/md'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import ModalDelete from '../../components/ModalDelete'
import { useRemoveContoProgetto } from '../../hooks/progetti'
import { ContoBancario, PaginatedDJResponse } from '../../types'
import { useNavigate } from 'react-router'
import createStringLink, { numberFormatter } from '../../utils'

interface Props {
  data: PaginatedDJResponse<ContoBancario> | undefined
  showDelete?: boolean
  progetto?: number
  showMovimenti?: boolean
  setSelectedConti?: any
  contiSelected?: number[]
  isRefetching?: boolean
}

export default function ContiTable({
  data,
  showDelete = false,
  progetto,
  showMovimenti = false,
  setSelectedConti,
  contiSelected,
  isRefetching = false,
}: Props) {
  const { t, i18n } = useTranslation()
  const [contoToDelete, setContoToDelete] = useState<ContoBancario>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const deleteContoBancario = useRemoveContoProgetto()
  const navigate = useNavigate()
  return (
    <>
      <Table variant={'pinc'}>
        <Thead position={'sticky'} top={0}>
          <Tr>
            {setSelectedConti && (
              <Th>
                <Checkbox
                  width={5}
                  height={5}
                  size={'md'}
                  colorScheme={'orange'}
                  borderColor={'brandLight'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedConti(data?.results.map((c) => c.id))
                    } else {
                      setSelectedConti([])
                    }
                  }}
                />
              </Th>
            )}
            <Th>{t('name_bank')}</Th>
            <Th>{t('currency')}</Th>
            <Th>{t('account_number')}</Th>
            <Th>{t('account_type')}</Th>
            <Th>{t('country')}</Th>
            <Th>{t('balance')}</Th>
            <Th>{t('total_movements')}</Th>
            {showMovimenti && <Th>{t('movements')}</Th>}
            {showDelete && <Th>{t('actions')}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {isRefetching ? (
            <Tr>
              <Td colSpan={8}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={'calc(100% - 77px)'}
                  pt={'140px'}
                  pb={'140px'}
                >
                  <Spinner size={'sm'} color="brand" />
                </Box>
              </Td>
            </Tr>
          ) : (
            data!.results.map((conto) => (
              <Tr padding={2} key={conto.id}>
                {setSelectedConti && (
                  <Td>
                    <Checkbox
                      width={5}
                      height={5}
                      size={'md'}
                      colorScheme={'orange'}
                      borderColor={'brandLight'}
                      isChecked={contiSelected?.includes(conto.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedConti([...contiSelected!, conto.id])
                        } else {
                          setSelectedConti?.(
                            contiSelected!.filter((id) => id !== conto.id)
                          )
                        }
                      }}
                    />
                  </Td>
                )}
                <Td>
                  <LangLinkWithStyle to={`/accounts/${conto.id}`}>
                    {conto.nome_banca}
                  </LangLinkWithStyle>
                </Td>
                <Td>{conto.valuta_data?.codice}</Td>
                <Td>{conto.numero_conto}</Td>
                <Td>{t(conto.tipo_conto)}</Td>
                <Td>{conto.paese_intervento_data?.nome ?? ''}</Td>
                <Td textAlign={'right'}>
                  <Box color={'brand'} fontWeight={700}>
                    {numberFormatter.format(conto.saldo_estratto_conto ?? 0)}
                  </Box>
                  {conto.valuta_data?.codice}
                </Td>
                <Td textAlign={'right'}>
                  <Box color={'brand'} fontWeight={700}>
                    {numberFormatter.format(conto.totale_movimenti ?? 0)}
                  </Box>
                  {conto.valuta_data?.codice}
                </Td>
                {showMovimenti && (
                  <Td>
                    <Button
                      leftIcon={<MdSearch />}
                      border={'1px solid'}
                      borderColor={'brandBlue'}
                      color={'brandBlue'}
                      bg={'card'}
                      _hover={{
                        bg: 'brandBlue',
                        color: 'white',
                      }}
                      size={'xs'}
                      onClick={() => {
                        navigate(
                          createStringLink({
                            path: `/projects/${progetto}/accounts/${conto.id}/movements`,
                            lang: i18n.language,
                          })
                        )
                      }}
                    >
                      {t('control_movements')}
                    </Button>
                  </Td>
                )}
                {showDelete && (
                  <Td display={'flex'} alignItems={'center'}>
                    {showDelete && conto.can_delete && (
                      <MdDelete
                        size={20}
                        color={'red'}
                        cursor={'pointer'}
                        onClick={() => {
                          setContoToDelete(conto)
                          onOpen()
                        }}
                      />
                    )}
                  </Td>
                )}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteContoBancario.mutateAsync({
            progetto: progetto!,
            conto: contoToDelete!.id,
          })
          onClose()
        }}
        title={t('delete_account_from_project')}
        nameItem={contoToDelete?.numero_conto ?? ''}
        onOpen={onOpen}
        onToggle={onClose}
      />
    </>
  )
}
