import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import LangLinkWithStyle from '../../components/LangLinkWithStyle'
import { Bene, PaginatedDJResponse } from '../../types'

interface Props {
  data: PaginatedDJResponse<Bene> | undefined
}

export default function BeniTable({ data }: Props) {
  const { t } = useTranslation()
  return (
    <Table variant={'pinc'}>
      <Thead position={'sticky'} top={0}>
        <Tr>
          <Th>{t('progressive')}</Th>
          <Th>{t('description')}</Th>
          <Th>{t('project')}</Th>
          <Th>{t('place_of_use')}</Th>
          <Th>{t('movements')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data!.results.map((bene) => (
          <Tr padding={2} key={bene.id}>
            <Td>{bene.progressivo}</Td>
            <Td>
              <LangLinkWithStyle to={`/goods/${bene.id}`}>
                {bene.descrizione}
              </LangLinkWithStyle>
            </Td>
            <Td>{bene.progetto_data && bene.progetto_data.titolo}</Td>
            <Td>{bene.luogo_utilizzo}</Td>
            <Td></Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
