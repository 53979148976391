import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { CollaborazioneGridForm } from './CollaborazioneForm'
import { BaseCollaborazione, Collaboratore, Progetto } from '../../../types'
import { useCanInsertCollaboratori } from '../../../permissions'
import { useCreateCollaboratore } from '../../../hooks/collaboratori'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: BaseCollaborazione) => void
  progetto?: Progetto | undefined
  progettoAsField?: boolean
  collaboratore?: Collaboratore | undefined
  paesiIntervento?: number[]
  showNewCollaboratore?: boolean
  onOpenNewCollaborator?: () => void
  onCloseNewCollaborator?: () => void
  isOpenNewCollaborator?: boolean
}

export default function ModalFormCollaborazione({
  progetto,
  isOpen,
  onClose,
  onConfirm,
  progettoAsField = false,
  collaboratore,
  paesiIntervento,
  showNewCollaboratore = false,
  onOpenNewCollaborator,
  onCloseNewCollaborator,
  isOpenNewCollaborator,
}: Props) {
  const { t } = useTranslation()

  const ModalitaPagamentoSchema = Yup.object().shape({
    collaboratore: Yup.string().required().label(t('collaborator')),
    data_inizio: Yup.string().required().label(t('date_start')),
    data_fine: Yup.string().required().label(t('date_end')),
    valuta: Yup.string().required().label(t('currency')),
    importo_retribuzione: Yup.number()
      .required()
      .typeError(t('must_be_a_number') as string)
      .label(t('import_retribution')),
    progetto: progettoAsField
      ? Yup.string().required().label(t('project'))
      : Yup.string().notRequired().label(t('project')),
    tipo_retribuzione: Yup.string().required().label(t('type_retribution')),
  })

  const initialValues: BaseCollaborazione = {
    collaboratore: collaboratore?.id ?? undefined,
    allegato: null,
    data_inizio: '',
    data_fine: '',
    descrizione_collaborazione: '',
    localita_progetto: '',
    importo_retribuzione: null,
    tipo_lavoro: '',
    tipo_retribuzione: undefined,
    qualifica_contrattuale: '',
    valuta: null,
    allegato_2: null,
    allegato_3: null,
    allegato_4: null,
    allegato_5: null,
    note: '',
    progetto: progettoAsField ? progetto?.id : undefined,
  }

  const userCanInsertCollaboratori = useCanInsertCollaboratori()

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
      }}
      validationSchema={ModalitaPagamentoSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('new_collaboration')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <CollaborazioneGridForm
                  progettoAsField
                  collaboratore={collaboratore}
                  paesiIntervento={paesiIntervento}
                />
              </ModalBody>
              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Box>
                  {onOpenNewCollaborator && userCanInsertCollaboratori && (
                    <Button
                      color={'brand'}
                      me={3}
                      _hover={{
                        bg: 'brand',
                        color: 'white',
                      }}
                      onClick={() => {
                        onOpenNewCollaborator()
                      }}
                    >
                      {t('new_collaborator')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    bg={'brand'}
                    _hover={{
                      bg: 'brandSecondary',
                    }}
                    color={'white'}
                  >
                    {t('create')}
                  </Button>
                </Box>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
