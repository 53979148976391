import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseCollaboratore, Collaboratore, CollaboratoreSubtotaleProgetto, PaginatedDJResponse } from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

const makeCollaboratoreFormData = makeFormDataEncoder({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

export function useCollaboratori(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Collaboratore>>(
    ['collaboratori', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/collaboratori/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      enabled: params.enabled ?? true,
      suspense: params.suspense ?? true,
    }
  )
}

export function useDeleteCollaboratore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/collaboratori/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['collaboratori'])
        client.invalidateQueries(['collaboratore'])
      },
    }
  )
}

export function useCreateCollaboratore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (collaboratore: BaseCollaboratore) =>
        axios
          .post(
            `/api/collaboratori/`,
            makeCollaboratoreFormData(collaboratore),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Collaboratore)
    ),
    {
      onSuccess(savedCollaboratore) {
        client.invalidateQueries(['collaboratori'])
        client.setQueryData(
          ['collaboratore', savedCollaboratore.id],
          savedCollaboratore
        )
      },
    }
  )
}

export function useUpdateCollaboratore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (collaboratore: Collaboratore) =>
        axios
          .put(
            `/api/collaboratori/${collaboratore.id}/`,
            makeCollaboratoreFormData(collaboratore),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Collaboratore)
    ),
    {
      onSuccess(savedCollaboratore) {
        client.invalidateQueries(['collaboratori'])
        client.setQueryData(
          ['collaboratore', savedCollaboratore.id],
          savedCollaboratore
        )
      },
    }
  )
}

export function useCollaboratore(id: number) {
  return useQuery<Collaboratore>(
    ['collaboratore', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/collaboratori/${id}/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Collaboratore)
    ),
    {
      enabled: !!id,
    }
  )
}

export function useCollaboratoreSubtotaliProgetto(id: number){
  return useQuery<CollaboratoreSubtotaleProgetto[]>(
    ['collaboratore-subtotali', id],
    useAuthCallPromise(
      (token: string) => ({ signal }) =>
        axios
          .get(`/api/collaboratori/${id}/subtotali-progetto/`, {
            signal,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as CollaboratoreSubtotaleProgetto[])
    ),
    {
      enabled: !!id,
    }
  )
}

export function useCollaboratoriDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/collaboratori/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

const makeImportCollaboratori = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useImportCollaboratori() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => ({ importFile }) => {
      const formData = makeImportCollaboratori({ import_file: importFile })
      return axios
        .post(`/api/collaboratori/import-collaboratori/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['collaboratori'])
      },
    }
  )
}



