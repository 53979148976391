import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Grid,
  GridItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import {
  useDeleteFinanziamento,
  useFinanziamento,
} from '../../../hooks/finanziamenti'
import { useProgettoSimple } from '../../../hooks/progetti'
import { useCanCreateProgetti } from '../../../permissions'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import BreadCrumb from '../../../components/BreadCrumb'
import createStringLink from '../../../utils'
import ModalDelete from '../../../components/ModalDelete'

export default function Finanziamento() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id, finanziamento } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const { data } = useFinanziamento(Number(finanziamento!))
  const deleteFinanziamento = useDeleteFinanziamento()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const userCanCreateProgetto = useCanCreateProgetti()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('financing'),
        link: `/projects/${id}/financing`,
      },
      {
        label: data?.ente_data?.nome ?? '',
      },
    ]
  }, [t, progetto?.codice, id, data?.ente_data?.nome])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanCreateProgetto && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/projects/${id}/financing/${finanziamento}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('entity')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.ente_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={2} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('description')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={2} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('amount')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.importo} {progetto?.valuta_rendicontazione_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={2} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('annuity')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                    {data?.annualita}
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(data?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(data?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteFinanziamento.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/projects/${id}/financing`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.ente_data?.nome}
        title={t('delete_financing')}
      />
    </Box>
  )
}
