import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { useContiBancariDownload } from '../../../hooks/conti-bancari'
import { useQsFilters } from '../../../hooks/filters'
import {
  useAddContoProgetto,
  useContiProgetto,
  useProgettoSimple,
} from '../../../hooks/progetti'
import { useCanGestioneConti } from '../../../permissions'
import ContiTable from '../../Conti/ContiTable'
import ModalAssociaConto from './ModalAssociaConto'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'nome_banca',
})

export default function ContiProgettoList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { id } = useParams()
  const { data: progetto } = useProgettoSimple(Number(id!))

  const { data: contiProgetto } = useContiProgetto(
    Number(id!),
    filters
  )

  const filtersDownload = useMemo(() => {
    return {
      ...filters,
      progetto: Number(id!), 
    }
  }, [filters])

  const { t } = useTranslation()
  const downloadWithParams = useContiBancariDownload(filtersDownload)
  const userCanGestioneConti = useCanGestioneConti()
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const associaConto = useAddContoProgetto()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('accounts'),
      },
    ]
  }, [t, progetto, id])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneConti && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={onOpen}
                >
                  {t('associate_account')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={contiProgetto}
            filters={filters}
            options={[
              { value: 'nome_banca', label: t('name_bank') },
              { value: 'numero_conto', label: t('account_number') },
              { value: 'tipo_conto', label: t('account_type') },
              { value: 'valuta__codice', label: t('currency') },
              { value: 'saldo_estratto_conto', label: t('balance') },
            ]}
          />
          <Box
            width={'100%'}
            height={'calc(100vh - 240px)'}
            mt={3}
            overflowY={'auto'}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <ContiTable
              progetto={Number(id)}
              showDelete
              showMovimenti
              data={contiProgetto}
            />
          </Box>
          <Box
            width={'100%'}
            mt={3}
            display={'flex'}
            justifyContent={'flex-end'}
          >
            <Paginator
              count={contiProgetto!.count}
              currentPage={uiFilters.page}
              goToPage={(page) => setFilters({ page })}
            />
          </Box>
        </Flex>
      </Flex>
      {isOpen && (
        <ModalAssociaConto
          isOpen={isOpen}
          onClose={onClose}
          onToggle={onToggle}
          progetto={progetto}
          onConfirm={async (conto) => {
            await associaConto
              .mutateAsync({
                progetto: Number(id!),
                conto: conto.conto,
              })
              .then(() => {
                onClose()
              })
          }}
          onOpen={onOpen}
          title={t('associate_account')}
        />
      )}
    </Box>
  )
}
