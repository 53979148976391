import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCreateBudget } from '../../../hooks/budgets'
import createStringLink from '../../../utils'
import BudgetForm from './BudgetForm'

export default function BudgetCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const createBudget = useCreateBudget()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <BudgetForm
        save={async (budget) => {
          await createBudget.mutateAsync(budget)
          navigate(
            createStringLink({
              path: `/projects/${id}/budgets/`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
