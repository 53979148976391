import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  BaseRipartizioneCollaborazione,
  Collaborazione,
  RipartizioneCollaborazione,
} from '../../../types'
import { InputField } from '../../../components/form'
import AutoCompleteField from '../../../components/form/fields'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (values: RipartizioneCollaborazione) => void
  ripartizione?: RipartizioneCollaborazione
  collaborazione: Collaborazione | undefined
}

const initialValues: BaseRipartizioneCollaborazione = {
  collaborazione: undefined,
  codice: '',
  importo: undefined,
}

export default function ModalFormRipartizione({
  ripartizione,
  collaborazione,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const RipartizioneSchema = Yup.object().shape({
    codice: Yup.string().required().label(t('budget_line')),
    importo: Yup.number().required().label(t('amount')),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values as RipartizioneCollaborazione)
      }}
      validationSchema={RipartizioneSchema}
      initialValues={
        (ripartizione || initialValues) as RipartizioneCollaborazione
      }
    >
      {({ handleSubmit, values, resetForm }) => (
        <Modal
          isCentered
          size={'xl'}
          isOpen={isOpen}
          onClose={() => {
            onClose()
            resetForm()
          }}
        >
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {ripartizione ? t('edit_ripartition') : t('create_ripartition')}{' '}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <AutoCompleteField
                      name="codice"
                      label={t('budget_line') ?? ''}
                      isRequired
                      filterName={'codice'}
                      url={'/api/budgets/options-with-codice/'}
                      keyQuery={'budgets'}
                      params={{
                        progetto: collaborazione?.progetto,
                      }}
                      value={values.codice}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="importo"
                      label={t('amount') ?? ''}
                      isRequired
                      type={'number'}
                    />
                  </GridItem>
                </Grid>
              </ModalBody>
              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {ripartizione ? t('edit') : t('create')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
