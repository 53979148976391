import { BaseContoBancario, ContoBancario } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  DateField,
  InputField,
  InputFileField,
  ReactSelectField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

const initialValues: BaseContoBancario = {
  abi: '',
  cab: '',
  iban: '',
  valuta: undefined,
  modalita_pagamento: null,
  nome_banca: '',
  data_chiusura: null,
  numero_conto: '',
  saldo_estratto_conto: null,
  tipo_conto: '',
  sede_banca: '',
  titolare_conto: '',
  bic: '',
  swift: '',
  credenziali: '',
  referente_banca: '',
  tel_banca: '',
  allegato: null,
  allegato_2: null,
  allegato_3: null,
  allegato_4: null,
  allegato_5: null,
  paese_intervento: undefined,
}

interface ContoFormProps {
  conto?: ContoBancario
  save: (conto: ContoBancario) => Promise<void>
}

export default function ContoForm({ conto, save }: ContoFormProps) {
  const { t } = useTranslation()

  const [showAllegati, setShowAllegati] = useState(0)

  const optionsTipiConto = useMemo(() => {
    return [
      {
        label: t('bank'),
        value: 'banca',
      },
      {
        label: t('cassa'),
        value: 'cassa',
      },
    ]
  }, [t])

  const ContoSchema = Yup.object().shape({
    modalita_pagamento: Yup.string().required().label(t('payment_method')),
    nome_banca: Yup.string().required().label(t('bank_name')),
    tipo_conto: Yup.string().required().label(t('account_type')),
    valuta: Yup.number().required().label(t('currency')),
    numero_conto: Yup.string()
      .label(t('account_number'))
      .when('tipo_conto', {
        is: (val: string) => val === 'banca',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('accounts'),
        link: '/accounts',
      },
      {
        label: t('new_account'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('accounts'),
        link: '/accounts',
      },
      {
        label: conto?.nome_banca ?? '',
        link: `/accounts/${conto?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, conto])

  useEffect(() => {
    if (conto) {
      setShowAllegati(conto.numero_allegati ?? 0)
    }
  }, [conto])

  return (
    <Formik
      validationSchema={ContoSchema}
      onSubmit={(conto, { setErrors, setSubmitting }) =>
        save({
          ...conto,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(conto ?? initialValues) as ContoBancario}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={conto ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2} overflow={'auto'} className={'card-scroll-height'}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(3, 1fr)" gap={4}>
                  <GridItem>
                    <AutoCompleteField
                      isRequired
                      name="modalita_pagamento"
                      url={'/api/modalita-pagamento/options/'}
                      label={t('payment_method') ?? ''}
                      keyQuery={'modalita-pagamento-options'}
                      value={values.modalita_pagamento}
                    />
                  </GridItem>
                  <GridItem>
                    <ReactSelectField
                      isRequired
                      name="tipo_conto"
                      options={optionsTipiConto}
                      label={t('account_type') ?? ''}
                      value={optionsTipiConto.find(
                        (tipo) => tipo.value === values.tipo_conto
                      )}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      isRequired
                      name="paese_intervento"
                      url={'/api/paesi-intervento/options/'}
                      label={t('country') ?? ''}
                      keyQuery={'paesi-options'}
                      value={values.paese_intervento}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(2, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <InputField
                      isRequired
                      name="nome_banca"
                      label={t('name_bank') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="numero_conto"
                      label={t('account_number') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <AutoCompleteField
                      name="valuta"
                      url={'/api/valute/options/'}
                      keyQuery={'valute-options'}
                      label={t('currency') ?? ''}
                      value={values.valuta}
                      isRequired
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="sede_banca"
                      label={t('bank_headquarters') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="tel_banca"
                      label={t('bank_phone') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="referente_banca"
                      label={t('bank_contact') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <InputField name="abi" label={t('abi') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField name="cab" label={t('cab') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField name="bic" label={t('bic') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField name="swift" label={t('swift') ?? ''} />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(3, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <InputField name="saldo_estratto_conto" label={t('balance') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField name="iban" label={t('iban') ?? ''} />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="titolare_conto"
                      label={t('account_holder') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(1, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <InputField
                      name="credenziali"
                      label={t('credentials') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(2, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <DateField
                      name="data_chiusura"
                      label={t('closing_date') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={4}
                  minHeight="100%"
                  templateColumns="repeat(1, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <Button
                      onClick={() => {
                        setShowAllegati(showAllegati + 1)
                      }}
                      isDisabled={showAllegati === 5}
                    >
                      {t('add_file_attachment')}
                    </Button>
                    {showAllegati > 0 &&
                      Array.from(Array(showAllegati)).map((item, index) => (
                        <GridItem mt={2} colSpan={4} key={index}>
                          <InputFileField
                            name={
                              index === 0 ? 'allegato' : `allegato_${index + 1}`
                            }
                            label={t('attachment') + ' ' + (index + 1) ?? ''}
                          />
                        </GridItem>
                      ))}
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
