import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateProgetto } from '../../hooks/progetti'
import createStringLink from '../../utils'
import ProgettoForm from './ProgettoForm'

export default function ProgettiCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createProgetto = useCreateProgetto()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <ProgettoForm
        save={async (progetto) => {
          const savedProgetto = await createProgetto.mutateAsync(progetto)
          navigate(
            createStringLink({
              path: `/projects/${savedProgetto.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
