import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCreateMovimento } from '../../../hooks/movimenti'
import createStringLink from '../../../utils'
import MovimentoForm from './MovimentoForm'
import { useQsFilters } from '../../../hooks/filters'
// import ConnectedUsers from '../../../components/ConnectedUsers'

function removeUndefinedFromObjectWithTypes(obj: any) {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])
  return obj
}

const initFilters = (params: URLSearchParams) => ({
  movimento: params.get('movimento') ?? undefined,
  progetti_copia: params.get('progetti_copia') ?? 0
})

export default function MovimentoCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { id } = useParams()
  const { filters } = useQsFilters(initFilters)
  const createMovimento = useCreateMovimento()

  return (
    <Box width={'100%'} pt={67} overflow={'hidden'} background={'#F8F8F8'}>
      <MovimentoForm
        save={async (movimento) => {
          await createMovimento.mutateAsync(
            removeUndefinedFromObjectWithTypes(movimento)
          )
          navigate(
            createStringLink({
              path: `/projects/${id}/movements/`,
              lang: i18n.language,
            })
          )
        }}
        movimentoFromCopia={Number(filters.movimento)}
        progettiCopia={Number(filters.progetti_copia)}
      />
      {/* <ConnectedUsers topic={'movimenti-edit-'+id} /> */}
    </Box>
  )
}
