import { Route, Routes, useParams } from 'react-router'
import NotFound from '../../../components/NotFound'
import Movimento from './Movimento'
import MovimentoCreate from './MovimentoCreate'
import MovimentoEdit from './MovimentoEdit'
import MovimentoProgettoList from './MovimentoProgettoList'
import ProtectedRoute from '../../../components/ProtectedRoute'
import { useCanEditMovimenti, useCanInsertMovimenti } from '../../../permissions'
import MovimentoCreateMultiple from './MovimentoCreateMultiple'
import MovimentoEditMultiple from './MovimentoEditMultiple'

export default function MovimentiProgetto() {
  const { id } = useParams()
  const userCanInsertMovimenti = useCanInsertMovimenti(Number(id!))
  const userCanEditMovimenti = useCanEditMovimenti(Number(id!))
  
  return (
    <Routes>
      <Route index element={<MovimentoProgettoList />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute permessionCheck={userCanInsertMovimenti}>
            <MovimentoCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-multiple"
        element={
          <ProtectedRoute permessionCheck={userCanInsertMovimenti}>
            <MovimentoCreateMultiple />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-multiple"
        element={
          <ProtectedRoute permessionCheck={userCanEditMovimenti}>
            <MovimentoEditMultiple />
          </ProtectedRoute>
        }
      />
      <Route path={':movimento'} element={<Movimento />} />
      <Route
        path={':movimento/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanEditMovimenti}>
            <MovimentoEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
