import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import AutoCompleteField from '../../../components/form/fields'
import { Donatore, Progetto } from '../../../types'

interface Props {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto: Progetto | undefined
  donatoriInProgetto: Donatore[]
}

export default function ModalAssociaDonatore({
  progetto,
  isOpen,
  onClose,
  onConfirm,
  donatoriInProgetto,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    donatore: null,
  }

  const AssociaDonatoreSchema = Yup.object().shape({
    donatore: Yup.string().required(t('donor_is_required') ?? ''),
  })

  const excludeIds = useMemo(() => {
    const ids = donatoriInProgetto.map((d) => d.id)
    if (ids.length > 1) {
      return ids.join(',')
    }
    if (ids.length === 1) {
      return ids[0]
    }
    return null
  }, [donatoriInProgetto])

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={AssociaDonatoreSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, resetForm }) => (
        <Modal
          isCentered
          size={'xl'}
          isOpen={isOpen}
          onClose={() => {
            resetForm()
            onClose()
          }}
        >
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('associate_donor')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'donatore'}
                    label={t('donor') ?? ''}
                    url={'/api/donatori/options/'}
                    keyQuery={'donatori-options'}
                    value={values.donatore}
                    isRequired
                    params={{
                      exclude_ids: excludeIds,
                    }}
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.donatore}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('associate')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
