import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Spinner,
} from '@chakra-ui/react'
import { useQsFilters } from '../../hooks/filters'
import { useTranslation } from 'react-i18next'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import SubHeaderBlockPage from '../../components/SubHeaderBlockPage'
import Paginator from '../../components/Paginator'
import { useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import { useMovimenti, useMovimentiDownload } from '../../hooks/movimenti'
import MovimentiTable from './MovimentiTable'
import { Select } from 'chakra-react-select'
import { OPTIONS_CAUSALI } from '../../consts'
import { orderBy } from 'lodash'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import dayjs from 'dayjs'
import SelectAutoCompleteFilter from '../../components/SelectAutocompleFilter'
import { enGB, it, pt, es, fr } from 'date-fns/locale'

registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('fr', fr)
registerLocale('es', es)
registerLocale('pt', pt)

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '-data_documento',
  paesi_intervento: params.getAll('paesi_intervento') ?? [],
  causale: params.get('causale') ?? '',
  data_competenza_da: params.get('data_competenza_da') ?? '',
  data_competenza_a: params.get('data_competenza_a') ?? '',
  progetti: params.getAll('progetti') ?? [],
  collaboratore: params.get('collaboratore') ?? '',
  fornitore: params.get('fornitore') ?? '',
  has_allegato: params.get('has_allegato') ?? '',
  budget: params.get('budget') ?? '',
  bozza: params.get('bozza') ?? 'false',
  area_intervento: params.get('area_intervento') ?? '',
  duty_station: params.get('duty_station') ?? '',
  cambio_utilizzato: params.get('cambio_utilizzato') ?? '',
  progressivo_da: params.get('progressivo_da') ?? '',
  progressivo_a: params.get('progressivo_a') ?? '',
  progressivo_esatto: params.get('progressivo_esatto') ?? '',
})

export default function MovimentiList() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)

  const filtersToUse = useMemo(() => {
    return {
      ...filters,
      no_filter_user: true,
    }
  }, [filters])

  const { data, isLoading, isRefetching } = useMovimenti(filtersToUse)
  const filtersForDownload = useMemo(() => {
    let nextFilters = { ...filters, no_filter_user: true } as any
    if (!filters.progetti.length) {
      delete nextFilters.progetti
    }
    if (!filters.paesi_intervento.length) {
      delete nextFilters.paesi_intervento
    }
    return nextFilters
  }, [filters])

  const downloadWithParams = useMovimentiDownload(filtersForDownload)

  const { t, i18n } = useTranslation()

  const optionsCausali = useMemo(() => {
    const options = OPTIONS_CAUSALI.map((v) => ({
      value: v.value,
      label: t(v.label),
    }))
    return orderBy(options, ['label'], ['asc'])
  }, [t])

  const hasAllegatiOptions = useMemo(() => {
    return [
      {
        value: 'true',
        label: t('yes'),
      },
      {
        value: 'false',
        label: t('no'),
      },
    ]
  }, [t])

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('movements'),
      },
    ]
  }, [t])

  const [showFilters, setShowFilters] = useState(true)

  const areFiltersActive = useMemo(() => {
    if (
      filters.causale ||
      filters.data_competenza_da ||
      filters.data_competenza_a ||
      filters.paesi_intervento.length ||
      filters.progetti.length ||
      filters.collaboratore ||
      filters.fornitore ||
      filters.has_allegato ||
      filters.budget ||
      filters.area_intervento ||
      filters.duty_station ||
      filters.cambio_utilizzato ||
      filters.progressivo_da ||
      filters.progressivo_a 
    ) {
      return true
    } else {
      return false
    }
  }, [filters])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'} position={'relative'}>
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
              {areFiltersActive && (
                <Box
                  width={3}
                  height={3}
                  borderRadius={'50%'}
                  position={'absolute'}
                  top={-1}
                  right={1}
                  bg={'brand'}
                ></Box>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            zIndex={99}
            mt={1}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('causale')}</FormLabel>
                    <Select
                      options={optionsCausali ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          causale: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        optionsCausali?.find(
                          (v) => v.value === filters.causale
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('intervention_area')}
                    </FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.area_intervento}
                      url={'/api/aree-intervento/options/'}
                      keyQuery={'aree-intervento-options'}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            area_intervento: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            area_intervento: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {t('countries')}
                    </FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.paesi_intervento}
                      url={'/api/paesi-intervento/options/'}
                      keyQuery={'paesi-intervento-options'}
                      onChange={(values: any) => {
                        if (!values || values.length === 0) {
                          setFilters({
                            ...filters,
                            paesi_intervento: [],
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            paesi_intervento: values.map((v: any) => v.value),
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('project')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.progetti}
                      url={'/api/progetti/options/'}
                      keyQuery={'progetti-options'}
                      onChange={(values: any) => {
                        if (!values || values.length === 0) {
                          setFilters({
                            ...filters,
                            progetti: [],
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            progetti: values.map((v: any) => v.value),
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('collaborator')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.collaboratore}
                      url={'/api/collaboratori/options/'}
                      keyQuery={'collaboratori-options'}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            collaboratore: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            collaboratore: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Grid templateColumns={'repeat(6, 1fr)'} gap={6} mt={2}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('supplier')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.fornitore}
                      url={'/api/fornitori/options/'}
                      keyQuery={'fornitori-options'}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            fornitore: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            fornitore: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('duty_station')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.duty_station}
                      url={'/api/duty-stations/options/'}
                      keyQuery={'duty-stations-options'}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            duty_station: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            duty_station: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('budget')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.budget}
                      url={'/api/budgets/options/'}
                      keyQuery={'budgets-options'}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            budget: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            budget: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_competence_from')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_competenza_da:
                              dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_competenza_da: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_competenza_da
                          ? dayjs(filters.data_competenza_da).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_competence_to')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_competenza_a: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_competenza_a: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_competenza_a
                          ? dayjs(filters.data_competenza_a).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('has_attachment')}</FormLabel>
                    <Select
                      options={hasAllegatiOptions ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        if (!values) {
                          setFilters({
                            ...filters,
                            has_allegato: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            has_allegato: String(values?.value) ?? '',
                            page: 1,
                          })
                        }
                      }}
                      value={
                        hasAllegatiOptions?.find(
                          (v) => String(v.value) === filters.has_allegato
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Grid templateColumns={'repeat(6, 1fr)'} gap={6} mt={2}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('progressive_from')}</FormLabel>
                    <Input
                      type="number"
                      value={filters.progressivo_da}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          progressivo_da: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('progressive_to')}</FormLabel>
                    <Input
                      type="number"
                      value={filters.progressivo_a}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          progressivo_a: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('exact_progressive')}</FormLabel>
                    <Input
                      type="number"
                      value={filters.progressivo_esatto}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          progressivo_esatto: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'data_documento', label: t('date_document') },
              { value: 'data_competenza', label: t('date_competence') },
              { value: 'progressivo', label: t('progressive') },
              { value: 'causale', label: t('transaction_type') },
              { value: 'descrizione', label: t('description') },
              { value: 'importo', label: t('amount') },
              { value: 'valuta__codice', label: t('currency') },
              {
                value: 'importo_rendicontazione',
                label: t('amount_rendicontation'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <MovimentiTable
                isRefetching={isRefetching}
                filtersOpen={showFilters}
                data={data}
              />
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
