import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useParams,
} from 'react-router-dom'
import NotFound from './components/NotFound'
import DataProvider from './DataProvider'
import ErrorBoundary from './ErrorBoundary'
import Auth from './lib/auth'
import './i18n'
import { RequireAuth, RequireGuest } from './lib/routing'
import { ChakraProvider } from '@chakra-ui/react'
import Login from './pages/Login'
import Home from './pages/Home'
import Layout from './components/Layout'
import { theme } from './theme'
import Users from './pages/Users'
import Valute from './pages/Valute'
import Progetti from './pages/Progetti'
import { I18nextProvider } from 'react-i18next'
import { createI18n, LANGS } from './i18n'
import TipiRetribuzione from './pages/TipiRetribuzione'
import ModalitaPagamento from './pages/ModalitaPagamento'
import TipiCambio from './pages/TipiCambio'
import AreeIntervento from './pages/AreeIntervento'
import SettoriIntervento from './pages/SettoriIntervento'
import PaesiIntervento from './pages/PaesiIntervento'
import VociSpesa from './pages/VociSpesa'
import Enti from './pages/Enti'
import Collaboratori from './pages/Collaboratori'
import ReferentiGenerici from './pages/ReferentiGenerici'
import Donatori from './pages/Donatori'
import Fornitori from './pages/Fornitori'
import ValoriCambio from './pages/ValoriCambio'
import Beni from './pages/Beni'
import Conti from './pages/Conti'
import Movimenti from './pages/Movimenti'
import Profilo from './pages/Profilo'
import AttivazioneAccount from './pages/AttivazioneAccount'
import RecuperaPassword from './pages/RecuperaPassword'
import ResetPassword from './pages/ResetPassword'
import { CausaliContextProvider } from './context/CausaleContext'
import { fr, it, es, pt } from 'yup-locales'
import { setLocale } from 'yup'
import { useEffect } from 'react'
import SaldiCertificati from './pages/SaldiCertificati/SaldiCertificati'
import DutyStations from './pages/DutyStations'

function AvailablesLang() {
  const { lang } = useParams()
  if (LANGS.includes(lang!)) {
    return <Outlet />
  }
  return <NotFound />
}

function App() {
  const i18n = createI18n(window.location.pathname)
  if (i18n.language === 'fr') {
    setLocale(fr)
  } else if (i18n.language === 'it') {
    setLocale(it)
  } else if (i18n.language === 'es') {
    setLocale(es)
  } else if (i18n.language === 'pt') {
    setLocale(pt)
  }

  useEffect(() => {
    document.documentElement.lang = i18n.language
  }, [i18n.language])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <RequireGuest>
              <Outlet />
            </RequireGuest>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="activate/:token" element={<AttivazioneAccount />} />
          <Route path="recover-password" element={<RecuperaPassword />} />
          <Route path="reset/:token" element={<ResetPassword />} />
        </Route>
        {/* AUTH */}
        <Route
          path={'/'}
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Home />} />
          <Route path={':lang/*'} element={<AvailablesLang />}>
            <Route index element={<Home />} />
            <Route path="profile" element={<Profilo />} />
            <Route path="users/*" element={<Users />} />
            <Route path="currencies/*" element={<Valute />} />
            <Route path="projects/*" element={<Progetti />} />
            <Route path="movements/*" element={<Movimenti />} />
            <Route path="renumerations/*" element={<TipiRetribuzione />} />
            <Route
              path="methods-of-payment/*"
              element={<ModalitaPagamento />}
            />
            <Route path="types-exchange/*" element={<TipiCambio />} />
            <Route path="intervention-areas/*" element={<AreeIntervento />} />
            <Route path="duty-stations/*" element={<DutyStations />} />
            <Route
              path="sectors-of-intervention/*"
              element={<SettoriIntervento />}
            />
            <Route
              path="countries-of-intervention/*"
              element={<PaesiIntervento />}
            />
            <Route path="expense-items/*" element={<VociSpesa />} />
            <Route path="entities/*" element={<Enti />} />
            <Route path="employees/*" element={<Collaboratori />} />
            <Route path="contacts/*" element={<ReferentiGenerici />} />
            <Route path="donors/*" element={<Donatori />} />
            <Route path="suppliers/*" element={<Fornitori />} />
            <Route path="exchange-rate-values/*" element={<ValoriCambio />} />
            <Route path="goods/*" element={<Beni />} />
            <Route path="accounts/*" element={<Conti />} />
            <Route path="certified-balances/*" element={<SaldiCertificati />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </>
    )
  )
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <DataProvider>
            <Auth>
              <CausaliContextProvider>
                <RouterProvider router={router} />
              </CausaliContextProvider>
            </Auth>
          </DataProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </I18nextProvider>
  )
}

export default App
