import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import { PaginatedDJResponse, Valuta } from '../types'
import { useDownloadFile } from './download'

export function useValute(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Valuta>>(
    ['valute', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/valute/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      suspense: params.suspense ?? true,
    }
  )
}

export function useDeleteValuta() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/valute/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['valute'])
        client.invalidateQueries(['valuta'])
      },
    }
  )
}

export function useCreateValuta() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (valuta: Valuta) =>
        axios
          .post(`/api/valute/`, valuta, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Valuta)
    ),
    {
      onSuccess(savedValuta) {
        client.invalidateQueries(['valute'])
        client.setQueryData(['valuta', savedValuta.id], savedValuta)
      },
    }
  )
}

export function useUpdateValuta() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (valuta: Valuta) =>
        axios
          .put(`/api/valute/${valuta.id}/`, valuta, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Valuta)
    ),
    {
      onSuccess(savedValuta) {
        client.invalidateQueries(['valute'])
        client.setQueryData(['valuta', savedValuta.id], savedValuta)
      },
    }
  )
}

export function useValuta(id: number) {
  return useQuery<Valuta>(
    ['valute', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/valute/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useValuteDownload(filters:any){
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/valute/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
