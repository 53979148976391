import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import ProtectedRoute from '../../components/ProtectedRoute'
import { useCanGestioneAnagrafiche } from '../../permissions'
import DonatoreDetail from './DonatoreDetail'
import DonatoriCreate from './DonatoriCreate'
import DonatoriEdit from './DonatoriEdit'
import DonatoriList from './DonatoriList'

export default function Donatori() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<DonatoriList />} />
      <Route
        path={'/create'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <DonatoriCreate />
          </ProtectedRoute>
        }
      />
      <Route path={'/:id'} element={<DonatoreDetail />} />
      <Route
        path={'/:id/edit'}
        element={
          <ProtectedRoute permessionCheck={userCanGestioneAnagrafiche}>
            <DonatoriEdit />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
