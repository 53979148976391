import { Box, Button, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { PaginatedDJResponse } from '../types'
import DownloadButton from './DownloadButton'
import OrderingBlock from './OrderingBlock'
import SearchInput from './SearchInput'

interface Option {
  value: string
  label: string
}

interface SubHeaderBlockPageProps {
  setFilters: (filters: any) => void
  setFiltersDebounced: (filters: any) => void
  options: Array<Option>
  data: PaginatedDJResponse<any> | undefined
  uiFilters: any
  filters: any
  downloadWithParams?: () => Promise<void>
  importData?: () => void
}

export default function SubHeaderBlockPage({
  setFilters,
  options,
  data,
  uiFilters,
  setFiltersDebounced,
  filters,
  downloadWithParams,
  importData,
}: SubHeaderBlockPageProps) {
  const { t } = useTranslation()
  return (
    <Flex
      mt={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box>
          {importData && (
            <Button
              bg={'brandBlue'}
              color={'white'}
              _hover={{
                opacity: 0.8,
              }}
              mt={1}
              me={1}
              size={'sm'}
              onClick={importData}
            >
              {t('importa')}
            </Button>
          )}
        </Box>
        <Box>
          {downloadWithParams && (
            <DownloadButton
              isDisabled={!downloadWithParams}
              onClick={() => {
                if (downloadWithParams) {
                  downloadWithParams()
                }
              }}
            />
          )}
        </Box>
        {filters.ordering && (
          <OrderingBlock
            noMargin={!downloadWithParams}
            onChange={(ordering) => setFilters({ ...filters, ordering })}
            value={filters.ordering}
            options={options}
          />
        )}
      </Box>
      <Box alignItems={'center'} display={'flex'}>
        <Box
          whiteSpace={'nowrap'}
          me={6}
          fontSize={14}
          color={'secondary'}
          fontWeight={700}
        >
          {data?.count !== data?.full_count && (
            <>
              {data?.count} {t('of')}
            </>
          )}{' '}{data?.full_count}{' '}
          {data?.full_count === 1 ? t('result') : t('results')}
        </Box>
        <SearchInput
          setFiltersDebounced={setFiltersDebounced}
          value={uiFilters.search}
        />
      </Box>
    </Flex>
  )
}
