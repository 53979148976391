import { BaseCollaboratore, Collaboratore } from '../../types'
import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  DateField,
  InputField,
  InputFileField,
  SelectChakraField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

const initialValues: BaseCollaboratore = {
  nome: '',
  sesso: '',
  email: '',
  telefono: '',
  note: '',
  residenza: '',
  data_nascita: '',
  luogo_nascita: '',
  area_intervento: null,
  paese_intervento: null,
  ente: null,
  allegato: null,
  allegato_2: null,
  allegato_3: null,
  allegato_4: null,
  allegato_5: null,
  duty_station_relazione: undefined,
  codice: '',
}

interface CollaboratoreFormProps {
  collaboratore?: Collaboratore
  save: (collaboratore: Collaboratore) => Promise<void>
}

export function CollaboratoreGridForm({
  showAllegati,
  setShowAllegati,
  isModal = false,
}: {
  showAllegati: number
  setShowAllegati: (value: number) => void
  isModal?: boolean
}) {
  const { t } = useTranslation()
  const { values } = useFormikContext<BaseCollaboratore>()
  return (
    <Grid minHeight="100%" templateColumns={isModal ? "repeat(3, 1fr)" : "repeat(2, 1fr)"} gap={4} overflow={'auto'}>
      <GridItem>
        <InputField isRequired name="nome" label={t('name') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField isRequired name="codice" label={t('employee_code') ?? ''} />
      </GridItem>
      <GridItem>
        <SelectChakraField name="sesso" label={t('sex') ?? ''}>
          <option value={''}></option>
          <option value={'M'}>{t('male')}</option>
          <option value={'F'}>{t('female')}</option>
        </SelectChakraField>
      </GridItem>
      <GridItem>
        <AutoCompleteField
          name="ente"
          label={t('main_entity') ?? ''}
          url="/api/enti/options/"
          keyQuery="enti-options"
          value={values.ente}
        />
      </GridItem>
      <GridItem>
        <AutoCompleteField
          value={values.area_intervento}
          url={'/api/aree-intervento/options/'}
          keyQuery={'aree-intervento-options'}
          name={'area_intervento'}
          label={t('intervention_area') ?? ''}
        />
      </GridItem>
      <GridItem>
        <AutoCompleteField
          name="paese_intervento"
          label={t('country') ?? ''}
          url={'/api/paesi-intervento/options/'}
          value={values.paese_intervento}
          keyQuery={'paesi-intervento-options'}
        />
      </GridItem>
      <GridItem>
        <AutoCompleteField
          label={t('duty_station') ?? ''}
          name="duty_station_relazione"
          url={'/api/duty-stations/options/'}
          value={values?.duty_station_relazione}
          keyQuery={'options-duty-stations'}
          isRequired
        />
      </GridItem>
      <GridItem>
        <DateField name="data_nascita" label={t('date_of_birth') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField name="luogo_nascita" label={t('place_of_birth') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField name="residenza" label={t('residence') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField name="telefono" label={t('phone') ?? ''} />
      </GridItem>
      <GridItem>
        <InputField name="email" label={t('email') ?? ''} />
      </GridItem>
      <GridItem colSpan={2}>
        <TextareaField name="note" label={t('notes') ?? ''} />
      </GridItem>
      <GridItem>
        <Button
          onClick={() => {
            setShowAllegati(showAllegati + 1)
          }}
          isDisabled={showAllegati === 5}
        >
          {t('add_file_attachment')}
        </Button>
        {showAllegati > 0 &&
          Array.from(Array(showAllegati)).map((item, index) => (
            <GridItem mt={2} colSpan={4} key={index}>
              <InputFileField
                name={index === 0 ? 'allegato' : `allegato_${index + 1}`}
                label={t('attachment') + ' ' + (index + 1) ?? ''}
              />
            </GridItem>
          ))}
      </GridItem>
    </Grid>
  )
}

export default function CollaboratoreForm({
  collaboratore,
  save,
}: CollaboratoreFormProps) {
  const { t } = useTranslation()
  const [showAllegati, setShowAllegati] = useState(0)

  const CollaboratoreSchema = Yup.object().shape({
    nome: Yup.string().required().label(t('name')),
    codice: Yup.string().required().label(t('employee_code')),
    duty_station_relazione: Yup.number().required().label(t('duty_station')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('employees'),
        link: '/employees',
      },
      {
        label: t('new_employee'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('employees'),
        link: '/employees',
      },
      {
        label: collaboratore?.nome ?? '',
        link: `/employees/${collaboratore?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, collaboratore])

  useEffect(() => {
    if (collaboratore) {
      setShowAllegati(collaboratore.numero_allegati ?? 0)
    }
  }, [collaboratore])

  return (
    <Formik
      validationSchema={CollaboratoreSchema}
      onSubmit={(collaboratore, { setErrors, setSubmitting }) =>
        save({
          ...collaboratore,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(collaboratore ?? initialValues) as Collaboratore}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        handleReset,
        dirty,
      }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    collaboratore ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2} overflow={'auto'} className={'card-scroll-height'}>
              <CardBody>
                <CollaboratoreGridForm
                  showAllegati={showAllegati}
                  setShowAllegati={setShowAllegati}
                />
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
