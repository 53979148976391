import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  ModalitaPagamento,
  PaginatedDJResponse,
} from '../types'
import { useDownloadFile } from './download'

export function useModalitaPagamento(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<ModalitaPagamento>>(
    ['modalita-pagamenti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/modalita-pagamento/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      suspense: params.suspense ?? true
    }
  )
}

export function useDeleteModalitaPagamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/modalita-pagamento/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['modalita-pagamenti'])
      },
    }
  )
}

export function useCreateModalitaPagamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (modalita: ModalitaPagamento) =>
        axios
          .post(`/api/modalita-pagamento/`, modalita, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ModalitaPagamento)
    ),
    {
      onSuccess(savedModalita) {
        client.invalidateQueries(['modalita-pagamenti'])
        client.setQueryData(
          ['modalita-pagamento', savedModalita.id],
          savedModalita
        )
      },
    }
  )
}

export function useUpdateModalitaPagamento() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (modalita: ModalitaPagamento) =>
        axios
          .put(`/api/modalita-pagamento/${modalita.id}/`, modalita, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ModalitaPagamento)
    ),
    {
      onSuccess(savedModalita) {
        client.invalidateQueries(['modalita-pagamenti'])
        client.setQueryData(
          ['modalita-pagamento', savedModalita.id],
          savedModalita
        )
      },
    }
  )
}

export function useSingleModalitaPagamento(id: number) {
  return useQuery<ModalitaPagamento>(
    ['modalita-pagamento', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/modalita-pagamento/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useModalitaPagamentoDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/modalita-pagamento/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}
