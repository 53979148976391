import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateContoBancario } from '../../hooks/conti-bancari'
import createStringLink from '../../utils'
import ContoForm from './ContoForm'

export default function ContoCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createContoBancario = useCreateContoBancario()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <ContoForm
        save={async (conto) => {
          const savedConto = await createContoBancario.mutateAsync(
            conto
          )
          navigate(
            createStringLink({
              path: `/accounts/${savedConto.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
