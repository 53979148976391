import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import CollaboratoriCreate from './CollaboratoriCreate'
import CollaboratoreDetail from './CollaboratoreDetail'
import CollaboratoriList from './CollaboratoriList'
import CollaboratoriEdit from './CollaboratoriEdit'
import { useCanGestioneAnagrafiche } from '../../permissions'

export default function Collaboratori() {
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  return (
    <Routes>
      <Route index element={<CollaboratoriList />} />
      <Route path="/:id" element={<CollaboratoreDetail />} />
      {userCanGestioneAnagrafiche && (
        <Route path="/create" element={<CollaboratoriCreate />} />
      )}
      {userCanGestioneAnagrafiche && (
        <Route path="/:id/edit" element={<CollaboratoriEdit />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
