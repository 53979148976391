import { AccountUser } from "./types"

export const PAGE_SIZE = 20

export const SERVER_URL = 'http://localhost:8000'

export const OPTIONS_CAUSALI = [
  { value: 'acquisto-cespite', label: '08 Acquisto Cespite' },
  { value: 'vendita-cespite', label: '17 Vendita Cespite' },
  { value: 'trasferimento-a-progetto', label: '13 Prestito ad altro Progetto' },
  { value: 'spese-personale', label: '06 Spese del personale' },
  { value: 'prelievo', label: '04 Prelievo/Versamento' },
  { value: 'apporto-loco', label: '15 Apporto Loco' },
  { value: 'apporto-italia', label: '16 Apporto Italia' },
  { value: 'accredito-loco', label: '02 Accredito in loco' },
  { value: 'donazione', label: '14 Donazione' },
  { value: 'interessi-attivi', label: '10 Interessi attivi' },
  { value: 'invio-da-italia', label: '01 Invio da Italia' },
  { value: 'spesa-italia', label: '07 Spese Italia' },
  { value: 'cambio-valuta', label: '03 Cambio valuta' },
  { value: 'anticipo-cassa', label: '09 Anticipo di cassa' },
  { value: 'reso-anticipo-cassa', label: '11 Reso anticipo di cassa' },
  { value: 'spesa-loco', label: '05 Spese loco' },
  { value: 'giroconto-chiusura', label: '12 Giroconto chiusura' },
  { value: 'giroconto-apertura', label: '19 Giroconto apertura' },
  {
    value: 'trasferimento-da-progetto',
    label: '18 Trasferimento da altro Progetto',
  },
]

export const OPTIONS_CAUSALI_INSERT = [
  { value: 'acquisto-cespite', label: '08 Acquisto Cespite' },
  { value: 'vendita-cespite', label: '17 Vendita Cespite' },
  { value: 'trasferimento-a-progetto', label: '13 Prestito ad altro Progetto' },
  { value: 'spese-personale', label: '06 Spese del personale' },
  { value: 'prelievo', label: '04 Prelievo/Versamento' },
  { value: 'apporto-loco', label: '15 Apporto Loco' },
  { value: 'apporto-italia', label: '16 Apporto Italia' },
  { value: 'accredito-loco', label: '02 Accredito in loco' },
  { value: 'donazione', label: '14 Donazione' },
  { value: 'interessi-attivi', label: '10 Interessi attivi' },
  { value: 'invio-da-italia', label: '01 Invio da Italia' },
  { value: 'spesa-italia', label: '07 Spese Italia' },
  { value: 'cambio-valuta', label: '03 Cambio valuta' },
  { value: 'anticipo-cassa', label: '09 Anticipo di cassa' },
  { value: 'reso-anticipo-cassa', label: '11 Reso anticipo di cassa' },
  { value: 'spesa-loco', label: '05 Spese loco' },
  { value: 'giroconto-chiusura', label: '12 Giroconto chiusura' },
]

export const OPTIONS_SPESA_LOCO = [
  { value: 'spesa-loco', label: '05 Spese loco' },
]

export const OPTIONS_SPESA_PERSONALE = [
  { value: 'spese-personale', label: '06 Spese del personale' },
]

export function getCausaliOptions(user: any, insert=false) {
  let options = [] as any[]
  if(user.permessi?.causale_spese_loco) {
    options = options.concat(OPTIONS_SPESA_LOCO)
  }
  if(user.permessi?.causale_spese_personale) {
    options = options.concat(OPTIONS_SPESA_PERSONALE)
  }
  if(options.length === 0) {
    options = insert ? OPTIONS_CAUSALI : OPTIONS_CAUSALI_INSERT
  }
  return options
}

export const CAUSALI_APPORTO_LOCO = [
  'spese-personale',
  'spesa-loco',
  'spesa-italia',
  'acquisto-cespite',
]

export const TIPO_PROCEDURA_ACQUISTO_OPTIONS = [
  { value: 'tender', label: 'Tender' },
  { value: 'negoziata', label: 'Negoziata' },
  { value: 'acquisto diretto', label: 'Acquisto diretto' },
  { value: 'altro', label: 'Altro' },
]

export const TIPO_SPESA_INELEGGIBILE = 0
export const TIPO_SPESA_ELEGGIBILE = 1
