import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseProgettoPreferito, ProgettoPreferito } from '../types'

export function useProgettiPreferiti(params: Record<string, any> = {}) {
  return useQuery<ProgettoPreferito[]>(
    ['progetti-preferiti', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/progetti-preferiti/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteProgettoPreferito() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/progetti-preferiti/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetti-preferiti'])
        client.invalidateQueries(['progetto-preferito'])
      },
    }
  )
}

export function useCreateProgettoPreferito() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (preferito: BaseProgettoPreferito) =>
        axios
          .post(`/api/progetti-preferiti/`, preferito, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ProgettoPreferito)
    ),
    {
      onSuccess(savedPreferito) {
        client.invalidateQueries(['progetti-preferiti'])
        client.setQueryData(
          ['progetti-preferiti', savedPreferito.id],
          savedPreferito
        )
      },
    }
  )
}
