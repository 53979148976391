import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Movimento, Progetto } from '../../../types'
import AutoCompleteField from '../../../components/form/fields'

function GridCopiaApportoLoco({
  progetto,
  movimento,
}: {
  progetto: Progetto | undefined
  movimento: Movimento | undefined
}) {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <GridItem colSpan={1}>
        <AutoCompleteField
          name={'progetto'}
          label={t('project') ?? ''}
          url={'/api/progetti/options/'}
        //   params={{
        //     exclude_ids: movimento?.progetto
        //   }}
          keyQuery="progetti-options"
          isRequired
          value={values.progetto}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <AutoCompleteField
          name="budget"
          value={values.budget}
          params={{
            progetto: values.progetto,
            data_budget: movimento?.data_competenza,
          }}
          url="/api/budgets/options/"
          keyQuery="budgets-options"
          isDisabled={
            !values.progetto
          }
          label={t('budget') ?? ''}
          isRequired
        />
      </GridItem>
    </Grid>
  )
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  progetto: Progetto | undefined
  movimento: Movimento | undefined
  onConfirm: (values: any) => void
}

export default function ModalCopiaApportoLoco({
  isOpen,
  onClose,
  progetto,
  movimento,
  onConfirm,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    progetto: undefined,
    budget: undefined,
  }

  const ApportoLocoScheam = Yup.object().shape({
    progetto: Yup.string().required().label(t('project')),
    budget: Yup.string().required().label(t('budget')),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={ApportoLocoScheam}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, resetForm }) => (
        <Modal
          isCentered
          size={'4xl'}
          isOpen={isOpen}
          onClose={() => {
            resetForm()
            onClose()
          }}
        >
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {`${t('copia_apporto_loco')} (${movimento?.progressivo}) - ${
                  progetto?.codice
                }`}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <GridCopiaApportoLoco
                  movimento={movimento}
                  progetto={progetto}
                />
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('copy')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
