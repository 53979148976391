import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useCallback } from 'react'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseFornitore,
  Fornitore,
  PaginatedDJResponse,
  ProgettoPerLista,
} from '../types'
import { useDownloadFile } from './download'
import { makeFormDataEncoder } from './utils'

const makeFornitoreFormData = makeFormDataEncoder({
  fileFields: [
    'allegato',
    'allegato_2',
    'allegato_3',
    'allegato_4',
    'allegato_5',
  ],
  excludeFields: [],
})

export function useFornitori(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Fornitore>>(
    ['fornitori', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/fornitori/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
      enabled: params.enabled ?? true,
    }
  )
}

export function useProgettiFornitore(id: number) {
  return useQuery<PaginatedDJResponse<any>>(
    ['progetti-fornitore', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/fornitori/${id}/progetti/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useUnificaFornitori() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({
          fornitori_to_remove,
          fornitore,
        }: {
          fornitori_to_remove: number[]
          fornitore: number
        }) =>
          axios
            .put(
              `/api/fornitori/unifica/`,
              {
                fornitori_to_remove,
                fornitore,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Fornitore)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['fornitori'])
      },
    }
  )
}

export function useAssociaProgettoFornitore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) =>
        ({ fornitore, progetto }: { fornitore: number; progetto: number }) =>
          axios
            .post(
              `/api/progetti/associa-progetto-fornitore/`,
              {
                fornitore,
                progetto,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as ProgettoPerLista)
    ),
    {
      onSuccess() {
        client.invalidateQueries(['progetti-fornitore'])
      },
    }
  )
}

export function useDeleteFornitore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/fornitori/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['fornitori'])
        client.invalidateQueries(['fornitore'])
      },
    }
  )
}

export function useCreateFornitore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (fornitore: BaseFornitore) =>
        axios
          .post(`/api/fornitori/`, makeFornitoreFormData(fornitore), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Fornitore)
    ),
    {
      onSuccess(savedFornitore) {
        client.invalidateQueries(['fornitori'])
        client.setQueryData(['fornitore', savedFornitore.id], savedFornitore)
      },
    }
  )
}

export function useUpdateFornitore() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (fornitore: Fornitore) =>
        axios
          .put(
            `/api/fornitori/${fornitore.id}/`,
            makeFornitoreFormData(fornitore),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as Fornitore)
    ),
    {
      onSuccess(savedFornitore) {
        client.invalidateQueries(['fornitori'])
        client.setQueryData(['fornitore', savedFornitore.id], savedFornitore)
      },
    }
  )
}

export function useFornitore(id: number) {
  return useQuery<Fornitore>(
    ['fornitore', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/fornitori/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Fornitore)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useFornitoriDownload(filters: any) {
  const downloader = useDownloadFile()
  const downloadWithParams = useCallback(() => {
    return downloader('/api/fornitori/download/', filters)
  }, [downloader, filters])
  return downloadWithParams
}

const makeImportFornitori = makeFormDataEncoder({
  fileFields: ['import_file'],
  excludeFields: [],
})

export function useImportFornitori() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => ({ importFile }) => {
      const formData = makeImportFornitori({ import_file: importFile })
      return axios
        .post(`/api/fornitori/import-fornitori/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['fornitori'])
      },
    }
  )
}
