import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../components/BreadCrumb'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import ModalDelete from '../../components/ModalDelete'
import Paginator from '../../components/Paginator'
import {
  useDeletePaeseIntervento,
  usePaeseBeni,
  usePaeseFornitori,
  usePaeseIntervento,
} from '../../hooks/paesi-intervento'
import { useCanGestioneAnagrafiche } from '../../permissions'
import createStringLink from '../../utils'
import BeniTable from '../Beni/BeniTable'
import FornitoriTable from '../Fornitori/FornitoriTable'
import { enGB, it, pt, es, fr } from 'date-fns/locale'

registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('fr', fr)
registerLocale('es', es)
registerLocale('pt', pt)

export default function PaeseInterventoDetail() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const { data } = usePaeseIntervento(Number(id!))
  const deletePaeaseIntervento = useDeletePaeseIntervento()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const userCanGestioneAnagrafiche = useCanGestioneAnagrafiche()
  const [filtersForFornitori, setFiltersForFornitori] = useState({
    page: 1,
    search: '',
    da_data: '',
    a_data: '',
  })
  const [filtersForBeni, setFiltersForBeni] = useState({
    page: 1,
    search: '',
  })
  const { data: fornitori } = usePaeseFornitori(
    Number(id!),
    filtersForFornitori
  )
  const { data: beni } = usePaeseBeni(Number(id!), filtersForBeni)

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('countries_of_intervention'),
        link: '/countries-of-intervention',
      },
      {
        label: data?.nome ?? '',
      },
    ]
  }, [t, data])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              <Button
                size={'sm'}
                me={4}
                onClick={() => {
                  navigate(
                    createStringLink({
                      path: `/movements?paesi_intervento=${data?.id}`,
                      lang: i18n.language,
                    })
                  )
                }}
                border={'1px solid'}
                borderColor={'brandBlue'}
                color={'brandBlue'}
                bg={'card'}
                _hover={{
                  bg: 'brandBlue',
                  color: 'white',
                }}
              >
                {t('list_movements')}
              </Button>
              {userCanGestioneAnagrafiche && (
                <>
                  <Button
                    size={'sm'}
                    me={4}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/countries-of-intervention/${id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <Card>
          <CardBody px={6}>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('name')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('vat')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {data?.vat}
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')}{' '}
              {dayjs(data?.created).format('DD/MM/YYYY HH:mm')},{' '}
              {t('last_update')}{' '}
              {dayjs(data?.modified).format('DD/MM/YYYY HH:mm')}
            </Box>
          </CardFooter>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            bg={'card'}
            display={'flex'}
            justifyContent={'space-between'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            <Box fontWeight={600}>
              {t('suppliers_on_country_of_intervention')}
            </Box>
            <Box>
              <Grid templateColumns={'repeat(3, 1fr)'}>
                <GridItem>
                  <FormControl>
                    <Input
                      placeholder={t('search') ?? ''}
                      size={'sm'}
                      bg={'white'}
                      borderColor={'brandLight'}
                      borderRadius={6}
                      _focus={{
                        borderColor: 'brand',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      // borderColor={'dark'}
                      value={filtersForFornitori.search}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFiltersForFornitori({
                          ...filtersForFornitori,
                          search: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem ms={2}>
                  <FormControl>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      placeholderText={t('date_from') ?? ''}
                      onChange={(date) => {
                        if (date) {
                          setFiltersForFornitori({
                            ...filtersForFornitori,
                            da_data: dayjs(date).format('YYYY-MM-DD'),
                          })
                        } else {
                          setFiltersForFornitori({
                            ...filtersForFornitori,
                            da_data: '',
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filtersForFornitori.da_data
                          ? dayjs(filtersForFornitori.da_data).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem ms={2}>
                  <FormControl>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      placeholderText={t('date_to') ?? ''}
                      onChange={(date) => {
                        if (date) {
                          setFiltersForFornitori({
                            ...filtersForFornitori,
                            a_data: dayjs(date).format('YYYY-MM-DD'),
                          })
                        } else {
                          setFiltersForFornitori({
                            ...filtersForFornitori,
                            a_data: '',
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filtersForFornitori.a_data
                          ? dayjs(filtersForFornitori.a_data).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </Box>
          </CardHeader>
          <CardBody>
            <FornitoriTable
              paese_intervento={id ?? ''}
              notSticky
              showNumMovimenti
              data={fornitori}
            />
            <Box
              width={'100%'}
              mt={3}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <Paginator
                count={fornitori!.count}
                currentPage={
                  filtersForFornitori.page ? filtersForFornitori.page : 1
                }
                goToPage={(page) =>
                  setFiltersForFornitori({
                    ...filtersForFornitori,
                    page,
                  })
                }
              />
            </Box>
          </CardBody>
        </Card>
        <Card mt={6}>
          <CardHeader
            color={''}
            fontWeight={600}
            bg={'card'}
            py={2}
            borderBottom={1}
            borderBottomColor={'brand'}
          >
            {t('goods_on_country_of_intervention')}
          </CardHeader>
          <CardBody>
            <BeniTable data={beni} />
            <Box
              width={'100%'}
              mt={3}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <Paginator
                count={beni!.count}
                currentPage={filtersForBeni.page ? filtersForBeni.page : 1}
                goToPage={(page) => {
                  setFiltersForBeni({
                    ...filtersForBeni,
                    page,
                  })
                }}
              />
            </Box>
          </CardBody>
        </Card>
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deletePaeaseIntervento.mutateAsync(+id!)
          navigate(
            createStringLink({
              path: `/countries-of-intervention`,
              lang: i18n.language,
            })
          )
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={data?.nome}
        title={t('delete_country_of_intervention')}
      />
    </Box>
  )
}
