import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import AutoCompleteField, {
  DateField,
  InputField,
} from '../../components/form/fields'
import * as Yup from 'yup'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto?: number | undefined | null
  excludeProjects?: number[]
}

export default function ModalAssociaProgettoBene({
  isOpen,
  onClose,
  onConfirm,
  progetto,
  excludeProjects = [],
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    progetto: undefined,
    data_associazione: '',
    importo_residuo: 0,
  }

  const SchemaYup = Yup.object().shape({
    // progetto: Yup.string().required().label(t('project')),
    data_associazione: Yup.string().required().label(t('association_date')),
    importo_residuo: Yup.number().required().label(t('residual_amount')),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        onConfirm(values)
        setSubmitting(false)
      }}
      validationSchema={SchemaYup}
      initialValues={initialValues}
    >
      {({ handleSubmit, values }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {progetto
                  ? t('dissociate_or_change_project')
                  : t('associate_project')}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <AutoCompleteField
                    name={'progetto'}
                    label={t('project') ?? ''}
                    keyQuery={'progetti-options'}
                    url={'/api/progetti/options/'}
                    // isRequired
                    params={{
                      exclude_ids: excludeProjects,
                    }}
                    value={values.progetto}
                  />
                </Box>
                <Box mt={2}>
                  <DateField
                    name={'data_associazione'}
                    label={t('association_date') ?? ''}
                    isRequired
                  />
                </Box>
                <Box mt={2}>
                  <InputField
                    name={'importo_residuo'}
                    label={t('residual_amount') ?? ''}
                    isRequired
                  />
                </Box>
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  //   isDisabled={!values.progetto}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {t('associate')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
