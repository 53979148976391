import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCreateFornitore } from '../../hooks/fornitori'
import createStringLink from '../../utils'
import FornitoreForm from './FornitoreForm'

export default function FornitoriCreate() {
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const createFornitore = useCreateFornitore()

  return (
    <Box width={'100%'}  pt={67} background={'#F8F8F8'}>
      <FornitoreForm
        save={async (fornitore) => {
          const savedFornitore = await createFornitore.mutateAsync(
            fornitore
          )
          navigate(
            createStringLink({
              path: `/suppliers/${savedFornitore.id}`,
              lang: i18n.language,
            })
          )
        }}
      />
    </Box>
  )
}
